import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CryptoService } from 'src/app/Services/util/crypto.service';
import { isPlatformBrowser } from '@angular/common';
import { SEO } from '../../../core/constants';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.scss']
})
export class CitaComponent implements OnInit {

  constructor(private _cryptoService: CryptoService, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let userID: string = '';
      let user_existingCustomer: boolean = false;
      const credentials = localStorage.getItem('usuario');
      const bookings = localStorage.getItem('bookings');

      if (credentials) {
        const credentialsJson = JSON.parse(credentials);
        userID = this._cryptoService.encrypt(credentialsJson.email);

        if (bookings)
          user_existingCustomer = JSON.parse(bookings).length > 0;
      }

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "user_info",
        userID: userID,
        user_existingCustomer: user_existingCustomer
      });

      (window as any).dataLayer.push({
        event: "virtualPageView",
        virtualPagePath: "/agenda-tu-cita",
        virtualPageTitle: "NMV: Agenda tu cita"
      });

      // Renombrando valores para SEO - Inicio
      document.getElementsByTagName("title")[0].innerHTML = SEO.scheduleYourAppointment.title;

      let description = document.getElementsByName('description')[0];
      description.setAttribute("content", SEO.scheduleYourAppointment.description);
      // Renombrando valores para SEO - Fin
    }
  }

}
