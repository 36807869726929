<form class="bg-white" [formGroup]="form" [class.bg-white]="isBgWhite">
  <div class="newBoxTab"  [ngStyle]="{'padding-right': isBgWhite ? '23px' : '0px'}">
    <div class="sectionTab">
      <div class="columnBox gap20">
        <div class="titleBox" *ngIf="isBgWhite">Vuelos</div>
        <div class="form-check">
          <input class="form-check-input" (change)="changeSelect(0)" value="0" type="radio" formControlName="rdoVuelo" name="rdoVuelo"
            id="rdoVuelo1">
          <label class="form-check-label" for="rdoVuelo1">
            Ida y vuelta
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" (change)="changeSelect(1)" value="1" type="radio" name="rdoVuelo"  formControlName="rdoVuelo"
            id="rdoVuelo2">
          <label class="form-check-label" for="rdoVuelo2">
            Solo ida
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" (change)="changeSelect(2)" value="2" type="radio" name="rdoVuelo"  formControlName="rdoVuelo"
            id="rdoVuelo3">
          <label class="form-check-label" for="rdoVuelo3">
            Multidestino
          </label>
        </div>
      </div>
    </div>

    <!-- Ida y Vuelta & Solo Ida-->
    <div class="sectionTab" [ngClass]="{'active': tipoVuelo == 2}">
      <div class="columnBox" *ngIf="tipoVuelo !== EnumFlightType.multy_city; else multicity">
        <div class="boxDestino">
          <app-input-autocomplete #inputOrigen [(valueInput)]="valueInputOrigen" [validRequired]="isSubmit"
            (valueInputChange)="cleanInput()" [minTermLength]="minLengthAutocomplete" placeholder="Origen"
            [items]="vuelos$ | async" [loading]="vuelosLoading" [typeahead]="vuelosInput$" formControlName="origen"
            (keypress)="onkeypressSource($event)">
          </app-input-autocomplete>
        </div>
        <div class="boxDestino">
          <app-input-autocomplete #inputDestino [(valueInput)]="valueInputDestino" [validRequired]="isSubmit"
            (valueInputChange)="cleanInput()" [minTermLength]="minLengthAutocomplete" placeholder="Destino"
            [items]="vuelos2$ | async" [loading]="vuelosLoading2" [typeahead]="vuelosInput2$" formControlName="destino"
            (keypress)="onkeypressDestiny($event)">
          </app-input-autocomplete>
        </div>
      </div>
      <div class="columnBox" *ngIf="tipoVuelo === EnumFlightType.ida_vuelta">
        <div class="boxDate">
          <div class="iconCalendar">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <input #inputDepartureDate type="text" class="form-control" formControlName="departureDate"
            (focus)="onFocus()" [readonly]="disabledInput" placeholder="Salida" mask="d0/M0/0*" maxlength="10"
            style="background-color: #fff;" />
        </div>
        <div class="boxDate" *ngIf="tipoVuelo === EnumFlightType.ida_vuelta">
          <div class="iconCalendar">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <input type="text" class="form-control textIndent" formControlName="arrivalDate" (focus)="onFocus()"
            [readonly]="disabledInput" placeholder="Retorno" mask="d0/M0/0*" maxlength="10"
            style="background-color: #fff;" />
        </div>
      </div>


      <app-calendar-price *ngIf="displayCalendar && tipoVuelo === EnumFlightType.ida_vuelta" [flightData]="flightData"
        (inputDates)="insertDates($event)" [isPageResults]="isPageResults"></app-calendar-price>

      <div class="columnBox" *ngIf="tipoVuelo === EnumFlightType.ida">
        <app-calendar [requiredFromDate]="isSubmit" [requiredToDate]="isSubmit" [typeValue]="tipoVuelo"
          [toDate]="vuelosTab?.toDate || null" [fromDate]="vuelosTab?.fromDate || null" class="ignore-template"
          (changeDate)="changeDate($event)">
        </app-calendar>
      </div>
      <div class="columnBox maxW">
        <app-pop-up-pasajero-vuelos (emitValidation)="emitValidation($event)" #popUp [onlyPasajeros]="true"
          (emitDistributionObject)="distributionObject = $event"></app-pop-up-pasajero-vuelos>
      </div>

      <div class="columnBox bntFullW" *ngIf="tipoVuelo !== EnumFlightType.multy_city">
        <button class="fullWidht" type="button" (click)="searchVueloHotel()">Buscar</button>
      </div>
    </div>

    <!-- Multicity  -->
    <ng-template #multicity>
      <app-tab-vuelos-multicity [controls]="getControls()" [requiredFromDate]="isSubmit">
      </app-tab-vuelos-multicity>
      <div class="colTabFilter w100">
        <button class="fullWidht" type="button" (click)="searchVueloHotelMulti()">Buscar</button>
      </div>
    </ng-template>
  </div>
</form>

<!-- <div class="boxTab" style="display:none ;">

    <form class="bg-white" [formGroup]="form">
        <div class="rowBoxTab">
            <div class="padRight " style="position: relative;">
                <mat-form-field>
                    <mat-select formControlName="viajes" disableOptionCentering (valueChange)="changeSelect()">
                        <mat-option [value]="EnumFlightType.ida_vuelta">Ida y Vuelta</mat-option>
                        <mat-option [value]="EnumFlightType.ida">Solo Ida</mat-option>
                        <mat-option [value]="EnumFlightType.multy_city">Multi City</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="padRight">
                <mat-form-field>
                    <mat-select formControlName="clase" disableOptionCentering>
                        <mat-option [value]="EnumCabins.economy">Economy</mat-option>
                        <mat-option [value]="EnumCabins.business">Business</mat-option>
                        <mat-option [value]="EnumCabins.first_class">First Class</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="padRight">
                <app-pop-up-pasajero-vuelos (emitValidation)="emitValidation($event)" #popUp [onlyPasajeros]="true" (emitDistributionObject)="distributionObject = $event"></app-pop-up-pasajero-vuelos>
            </div>
        </div>


        <div class="rowBoxTab" *ngIf="form.controls.viajes.value !== EnumFlightType.multy_city; else multicity">

            <div class="colBoxtab w-30 me-md-1">
                <app-input-autocomplete #inputOrigen [(valueInput)]="valueInputOrigen" [validRequired]="isSubmit" (valueInputChange)="cleanInput()" [minTermLength]="minLengthAutocomplete" placeholder="Origen" [items]="vuelos$ | async" [loading]="vuelosLoading" [typeahead]="vuelosInput$"
                    formControlName="origen" (keypress)="onkeypressSource($event)">
                </app-input-autocomplete>
            </div>

            <div class="colBoxtab w-30 me-md-1">

                <app-input-autocomplete #inputDestino [(valueInput)]="valueInputDestino" [validRequired]="isSubmit" (valueInputChange)="cleanInput()" [minTermLength]="minLengthAutocomplete" placeholder="Destino" [items]="vuelos2$ | async" [loading]="vuelosLoading2" [typeahead]="vuelosInput2$"
                    formControlName="destino" (keypress)="onkeypressDestiny($event)">
                </app-input-autocomplete>

            </div>

            <div class="colBoxtab w-30" *ngIf="form.controls.viajes.value === EnumFlightType.ida_vuelta">
                <div class="iconCalendar">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                        stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <input #inputDepartureDate type="text" class="form-control textIndent" formControlName="departureDate" (focus)="onFocus()" [readonly]="disabledInput" placeholder="Salida" mask="d0/M0/0*" maxlength="10" style="background-color: #fff;" />
            </div>
            <div class="colBoxtab w-30" *ngIf="form.controls.viajes.value === EnumFlightType.ida_vuelta">
                <div class="iconCalendar">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                        stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <input type="text" class="form-control textIndent" formControlName="arrivalDate" (focus)="onFocus()" [readonly]="disabledInput" placeholder="Retorno" mask="d0/M0/0*" maxlength="10" style="background-color: #fff;" />
            </div>

            <app-calendar-price *ngIf="displayCalendar && form.controls.viajes.value === EnumFlightType.ida_vuelta" [flightData]="flightData" (inputDates)="insertDates($event)"></app-calendar-price>

            <app-calendar *ngIf="form.controls.viajes.value !== EnumFlightType.ida_vuelta" [requiredFromDate]="isSubmit" [requiredToDate]="isSubmit" [typeValue]="viajesForm" [toDate]="vuelosTab?.toDate || null" [fromDate]="vuelosTab?.fromDate || null" class="ignore-template"
                (changeDate)="changeDate($event)">
            </app-calendar>


            <div class="colBoxtab w-10 ms-md-2">
                <button class="fullWidht" type="button" (click)="searchVueloHotel()">Buscar</button>
            </div>
        </div>

        <ng-template #multicity>
            <app-tab-vuelos-multicity [controls]="getControls()" [requiredFromDate]="isSubmit">
            </app-tab-vuelos-multicity>
            <div class="colTabFilter w100">
                <button class="fullWidht" type="button" (click)="searchVueloHotelMulti()">Buscar</button>
            </div>
        </ng-template>

        <span class="valid" *ngIf="false">Debe seleccionar la cantidad de pasajeros</span>
    </form>
</div> -->

<!-- <iframe style="height: auto;" width="100%" src="http://52.177.246.241/#/nmviajes"></iframe> -->