<div class="card border-0 shadow h-100">
	<div class="image-container">
		<img [src]="urlImage" loading="lazy" alt="Card Image" class="card-img-top img-fluid" onerror="this.src='./assets/home/bgdefault.png'" />
	</div>
	<div class="card-body card-content">
		<div class="d-flex justify-content-between align-items-center flex-wrap">
			<p class="card-title mb-0">BOLETO AÉREO</p>
			<p class="card-subtitle mb-0">IDA Y VUELTA</p>
		</div>
		<div class="mb-auto">
			<p class="card-destination fs-4 text-break mb-0">{{ title }}</p>
			<p class="mb-0 card-description">{{ description | uppercase }}</p>
		</div>
		<div class="text-end">
			<span class="card_text_price_since">PRECIO DESDE:</span>
			<p class="card-price fw-bolder display-6">{{ price | currency: 'USD' : 'US$' : '1.0-2' }}</p>
		</div>
		<div>
			<app-button [text]="'¡Lo quiero!'" [color]="'primary'" [w100]="true" (click)="viewRates(destinationCode)">
			</app-button>
		</div>
	</div>
</div>
