<div class="banner h659">
	<div class="overflow-hidden w-100 mw-100 position-absolute top-0 h659">
		<img src="./assets/retail/banner.webp"
		     alt="NM 28 de Julio"
		     class="w-100 mw-100 h-100 position-absolute"
		     loading="eager">
		<div class="position-absolute banner-title">
			<h6 class="fs-5 fw-bold lh-1">NM 28 de Julio</h6>
			<h1 class="text-white">Ven y disfruta de la<br><strong>mejor experiencia de viajes</strong></h1>
		</div>
	</div>
</div>
<section class="container mb-md-4">
	<div class="row">
		<div class="col-md-6">
			<h3 class="titleHome pt-md-5 pb-md-3 mb-md-4 mb-3 fw-bolder">NM 28 de Julio</h3>
			<p class="text-justify mb-3 mb-md-4">
				Organiza tu próximo viaje en un ambiente agradable. Nuestra tienda ubicada en Av. 28 de julio 1136,
				Miraflores te ofrece amplias instalaciones donde recibirás una atención personalizada por nuestros
				expertos asesores.
				Conversa con un asesor y planea el itinerario a tu medida en el destino de tu preferencia en nuestros
				counters tipo lounge, disfrutando una bebida en la zona de cafetería y por qué no, al aire libre en la
				terraza.
			</p>
			<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.7341024818293!2d-77.02351018578644!3d-12.130336746762024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b7fd50f9f5db%3A0x129c4b7ccf8c2dc2!2sAv.%2028%20de%20Julio%201136%2C%20Miraflores%2015047!5e0!3m2!1ses!2spe!4v1642009579784!5m2!1ses!2spe"
					width="100%" height="255" style="border:0; margin-bottom: 1rem;" allowfullscreen="" loading="lazy">
			</iframe>
		</div>
		<div class="col-md-6 py-3">
			<img src="assets/retail/28-de-julio.webp"
			     alt="NM 28 de Julio"
			     class="img-fluid pt-4 pt-md-5 mt-md-1"
			     loading="lazy">
		</div>
	</div>
</section>
<section class="events position-relative">
	<div class="w-100 position-absolute top-0 start-0"></div>
	<div class="container position-relative">
		<div class="row pt-5 pb-md-3 mb-md-4 mb-2">
			<div class="col">
				<h3 class="titleHome text-white"><strong>Nuestros eventos</strong></h3>
			</div>
		</div>
		<div class="row pb-4 mb-md-2 pb-md-5 align-items-end">
			<div class="col-6 col-lg-3">
				<p class="text-white">Cierra puertas</p>
				<app-carousel carouselId="carousel1" [images]="slider1Images"></app-carousel>
			</div>
			<div class="col-6 col-lg-3">
				<p class="text-white">Europa & Destinos ex&oacute;ticos</p>
				<app-carousel carouselId="carousel2" [images]="slider2Images"></app-carousel>
			</div>
			<div class="col-6 col-lg-3">
				<p class="text-white">Travel Outlet</p>
				<app-carousel carouselId="carousel3" [images]="slider3Images"></app-carousel>
			</div>
			<div class="col-6 col-lg-3">
				<p class="text-white">Viajes, bodas y m&aacute;s</p>
				<app-carousel carouselId="carousel4" [images]="slider4Images"></app-carousel>
			</div>
		</div>
		<div class="row pt-4 pb-md-3 mb-md-3 mb-1">
			<div class="col">
				<h3 class="titleHome"><strong>Pr&oacute;ximos eventos</strong></h3>
			</div>
		</div>
		<div class="row pb-5 mb-md-3">
			<div class="col col-md-2 col-lg-4 text-end">
				<img src="assets/retail/retail-event.webp"
				     alt="Evento"
				     class="img-fluid pointer"
				     loading="lazy"
				     (click)="onRegistrationClick()">
				<br>
				<button class="btn btn-light mt-3 mt-md-4 ms-auto fw-bold" (click)="onRegistrationClick()">
					Reg&iacute;strate
				</button>
			</div>
		</div>
	</div>
	<div class="w-100 position-absolute bottom-0 start-0"></div>
</section>
