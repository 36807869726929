<form name="form" [formGroup]="form">
	<div class="row gx-3 gy-md-3 gy-sm-3 gy-xs-2 gy-2">
		<div class="col-md-12">
			<div class="container__tags">
				<app-input-class [includeFirtClass]="false" [isLeft]="true" #childClass></app-input-class>
				<app-pop-up-pasajero
					[nroAdultos]="2"
					#popUp
					(emitDistributionObject)="distributionObject = $event"
					(emitDistribution)="distribution = $event"
				></app-pop-up-pasajero>
			</div>
		</div>
		<div class="col-md-12">
			<div class="container__date">
				<app-new-calendar #childDate></app-new-calendar>
				<app-button [text]="'Buscar'" [isMobile]="true" [fullWidth]="true" [left]="true" [icon]="'search.svg'" (onClick)="searchPaquete()"></app-button>
			</div>
		</div>
	</div>
</form>
