export const SEO = {
	home: {
		url: 'https://www.nmviajes.com/',
		title: 'Ofertas de Viajes, Vuelos, Paquetes Turísticos en NM Viajes',
		description: 'Encuentra el mejor precio en pasajes aéreos, paquetes turísticos, vuela a Miami, Cusco, Arequipa y más destinos con NMViajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	flights: {
		url: 'https://www.nmviajes.com/vuelos',
		title: 'Vuelos Nacionales e Internacionales. Ofertas en pasajes aéreos',
		description: 'Vuelos Nacionales Perú e Internacionales. Aprovecha los Pasajes Aéreos Baratos. Vuelos Internacionales Lima a Miami, New York, Buenos Aires. Vuelos Nacionales a Cusco, Tumbes, Piura.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	touristPackages: {
		url: 'https://www.nmviajes.com/paquetes',
		title: 'Paquetes Turísticos Internacionales',
		description: 'Paquetes turísticos internacionales. Encuentra en NM Viajes las mejores ofertas en destinos internacionales: Cancún, Punta Cana, Miami, la Riviera Maya...',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	planTrip: {
		url: 'https://www.nmviajes.com/armapaquete',
		title: 'Arma Tu Viaje - Planifica y Personaliza tu Aventura Ideal',
		description: 'Crea tu viaje soñado armando tu viaje personalizado. Selecciona vuelos, hoteles, actividades y más para diseñar la aventura perfecta. Con NM Viajes, organiza cada detalle de tu viaje y disfruta de una experiencia a tu medida.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	flightAndHotel: {
		url: 'https://www.nmviajes.com/vuelohotel',
		title: 'Vuelo + Hotel a Precios Imperdibles',
		description: '¡Elige tu Vuelo + Hotel así de fácil y rápido! Aprovecha estas ofertas imperdibles y crea el mejor Vuelo + Hotel para ti con NM Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	hotels: {
		url: 'https://www.nmviajes.com/hoteles',
		title: 'Hoteles baratos – Reserva alojamientos al mejor precio en NM Viajes',
		description: 'Descubre y reserva los mejores hoteles con ofertas exclusivas. Encuentra alojamientos en destinos increíbles con los mejores precios en NM Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	cars: {
		url: 'https://www.nmviajes.com/autos',
		title: 'Alquiler de autos – Las mejores ofertas y variedad de vehículos - NM Viajes',
		description: 'Encuentra las mejores ofertas en alquiler de autos para tus viajes. Amplia variedad de vehículos, precios competitivos y servicio de calidad en NM Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	activities: {
		url: 'https://www.nmviajes.com/actividades',
		title: 'Actividades Turísticas - Descubre y Reserva los mejores tours',
		description: ' Explora y reserva actividades turísticas emocionantes en los mejores destinos. Variedad de tours, encuentra experiencias únicas al mejor precio. Planifica tus actividades con NM Viajes y vive momentos inolvidables.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	travelInsurance: {
		url: 'https://www.nmviajes.com/seguro-viaje',
		title: 'Seguro de Viaje - Asistencia de viaje, cobertura completa  | NM Viajes',
		description: 'Asegura tus viajes con nuestras pólizas de seguro de viaje. Ofrecemos cobertura completa para emergencias médicas, cancelaciones y más, en cualquier destino. Viaja tranquilo con NM viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	insurance: {
		url: 'https://www.nmviajes.com/seguros/',
		title: 'Seguro de viaje. Viaja seguro con Assist Card y NM Viajes',
		description: 'Viaja seguro con Assist Card y NM Viajes. Visítanos y conoce todos nuestros planes y coberturas de seguros de viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	scheduleYourAppointment: {
		title: 'Agenda un cita virtual con una asesor de NM Viajes',
		description: 'Recibe la asesoría de una especialista en viajes y haz que el viaje de tus sueños sea una realidad.'
	},
	careChannels: {
		title: 'Conoce todos los canales de atención de NM Viajes',
		description: 'Elige el canal de atención de tu preferencia y planifica tu viaje a los mejores precios.'
	}
} as const;