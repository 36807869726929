import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../../shared/material.module';
import { NuestrasAgenciasComponent } from './nuestras-agencias.component';

@NgModule({
	declarations: [NuestrasAgenciasComponent],
	imports: [CommonModule, MaterialModule, MatIconModule, RouterModule],
	exports: [NuestrasAgenciasComponent]
})
export class NuestrasAgenciasModule {}
