import { Component, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() title1!: string;
  @Input() list1!: any;
  @Input() title2!: string;
  @Input() list2!: any;
  @Input() title3!: string;
  @Input() span!: string;
  @Input() addrees!: string;
  @Input() libro!: string;
  @Input() linkLibro!: string;

  constructor(
    public route: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  showMenuProd: Boolean = true;

  showProducto() {
    this.showMenuProd = !this.showMenuProd;
  }

  showMenuConocenos: Boolean = true;

  showConocenos() {
    this.showMenuConocenos = !this.showMenuConocenos;
  }

  redirect(e: any) {
    if (isPlatformBrowser(this.platformId)) window.open(e, '_blank');
  }

  toOficinas() {
    this.route.navigate(['/nuestras-agencias']);
  }
}
