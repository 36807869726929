<div class="list-group list-group-flush pb-4">
	<a class="list-group-item list-group-item-action d-flex flex-nowrap justify-content-between align-items-center"
	   role="button"
	   (click)="openLink(notification, $event)"
	   *ngFor="let notification of data.notificationList; let i = index">
		<img [src]="notification.iconSrcPath" alt="" class="img-fluid" style="max-width: 25px">
		<div class="d-flex flex-column flex-nowrap">
			<p class="small fw-normal mb-1">{{ notification.title }}</p>
			<h5 class="fw-semibold mb-1">{{ notification.description }}</h5>
			<p class="fw-semibold small mb-0">{{ notification.hint }}</p>
		</div>
		<img src="assets/svg/arrowR-red.svg" alt="" class="w-auto" style="height: .875rem">
	</a>
	<a class="list-group-item list-group-item-action fw-normal"
	   role="button"
	   (click)="bottomSheetRef.dismiss()"
	   *ngIf="!data.notificationList || data.notificationList.length <= 0">
		Sin notificaciones
	</a>
</div>