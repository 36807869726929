<div class="d-flex flex-wrap gx-4 gx-lg-5">
	<ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="carouselItems">
		<ngu-tile NguCarouselItem *nguCarouselDef="let item" class="logo-tile">
			<div class="px-2" [ngClass]="{ pointer: !!item?.redirectUrl }">
				<img
					class="img-fluid object-fit-cover border-right carousel-image-g"
					loading="eager"
					[ngStyle]="{ height: heightImages, borderRadius }"
					appImageResponsiveLoad
					[mobileImage]="item.mobileLogo"
					[desktopImage]="item.desktopLogo"
					(click)="item?.redirectUrl ? redirectToUrl(item?.redirectUrl) : null"
					[alt]="item.alt" />
			</div>
		</ngu-tile>
		<button NguCarouselPrev class="carousel-control-prev" *ngIf="visibleButtons">
			<span aria-hidden="true" class="carousel-control-prev-icon dark-icon"></span>
			<span class="visually-hidden">Previous</span>
		</button>
		<button NguCarouselNext class="carousel-control-next" *ngIf="visibleButtons">
			<span aria-hidden="true" class="carousel-control-next-icon dark-icon"></span>
			<span class="visually-hidden">Next</span>
		</button>

		<div *ngIf="visiblePoints" NguCarouselPoint class="d-flex justify-content-center">
			<ul class="point-carousel">
				<li
					*ngFor="let j of myCarousel.pointNumbers; let i = index"
					[class.active]="j === myCarousel.activePoint"
					(click)="myCarousel.moveTo(j)"
					[style.background]="'url(' + carouselItems[i] + ')'"></li>
			</ul>
		</div>
	</ngu-carousel>
</div>
