<div class="modal__cancel" [class.isHome]="isRedirectHome">
	<div class="modal__cancel__header">
		<img src="./assets/svg/close-modal.svg" alt="Cerrar" (click)="activeModal.close()" />
	</div>
	<div class="modal__cancel__body" *ngIf="!isRedirectHome">
		<h5>Parece que tienes cambios pendientes. ¿Deseas permanecer en este paso?</h5>
		<section>
			<br />
		</section>
		<div class="modal__cancel__body__actions">
			<app-button
				[text]="'No, descartar'"
				[isTabIndex]="false"
				[w100]="true"
				[color]="'outline-primary'"
				(onClick)="closeSave(false)"
				[isMobile]="true"
			></app-button>
			<app-button
				[text]="'Si, permanecer'"
				[isMobile]="true"
				[w100]="true"
				[isTabIndex]="false"
				(onClick)="closeSave(true)"
			></app-button>
		</div>
	</div>

	<div class="modal__cancel__body" *ngIf="isRedirectHome">
		<h5>¿Estás seguro que quieres volver al inicio?</h5>
		<section>
			<span>Recuerda que al hacerlo, perderás todos los datos que has introducido en tu proceso de compra.</span><br />
		</section>
		<div class="modal__cancel__body__actions">
			<app-button
				[text]="'Volver al inicio'"
				[isTabIndex]="false"
				[w100]="true"
				[color]="'outline-primary'"
				(onClick)="redirectHome()"
				[isMobile]="true"
			></app-button>
			<app-button
				[text]="'Continuar compra'"
				[w100]="true"
				[isTabIndex]="false"
				(onClick)="activeModal.close()"
				[isMobile]="true"
			></app-button>
		</div>
	</div>
</div>
