<div class="modal-header overflow-hidden position-relative pt-0 px-0">
	<img src="assets/webp/contact.webp"
	     alt="Contact center"
	     class="img-fluid w-100 h-100 object-fit-cover my-0"
	     loading="lazy">
	<div class="overlay-dark position-absolute top-0 end-0 bottom-0 start-0 z-1"></div>
	<h5 class="modal-title text-center text-white fw-bold fs-5 position-absolute top-0 end-0 bottom-0 start-0 my-auto z-2">
		Te contactamos con un asesor
	</h5>
	<button type="button" class="btn-close z-2" aria-label="Close" (click)="activeModal.dismiss('closed')"></button>
</div>
<div class="modal-body text-center p-3 p-lg-4">
	<p class="text-secondary mb-md-4">Completa el formulario con tus datos y un asesor se comunicará contigo en breve</p>
	<form class="row px-lg-3 g-3" novalidate>
		<div class="col-md-6">
			<app-input
				[label]=""
				[placeholder]="'Nombres'"
				[name]="'name'"
				[isRequired]="true"
				[isOnlyText]="true"
				[maxLenght]="50"
				[class]=""
				[labelError]=""></app-input>
		</div>
		<div class="col-md-6">
			<app-input
				[label]=""
				[placeholder]="'Apellidos'"
				[name]="'lastname'"
				[isRequired]="true"
				[isOnlyText]="true"
				[maxLenght]="50"
				[class]=""
				[labelError]=""></app-input>
		</div>
		<div class="col-md-6">
			<app-input
				class="label_input"
				[label]=""
				[placeholder]="'Correo electrónico'"
				[name]="'email'"
				[isRequired]="true"
				[maxLenght]="50"
				[class]=""
				[labelError]=""></app-input>
		</div>
		<div class="col-md-6">
			<app-input
				class="label_input"
				[label]=""
				[placeholder]="'Teléfono'"
				[name]="'phone'"
				[isRequired]="true"
				[maxLenght]="14"
				[class]=""
				[labelError]=""></app-input>
		</div>
		<div class="col-12">
			<app-button [isMobile]="true" [text]="'Enviar'" [color]="'primary'" [fullWidth]="true" [w100]="true"></app-button>
		</div>
	</form>
</div>