import { Component, OnInit } from '@angular/core';
import { DynamicPackagesService } from '../../../services';
import { IDynamicPackage } from '../../../models';

const LIMIT = 6;

@Component({
	selector: 'app-section-packages',
	templateUrl: './section-packages.component.html',
	styleUrls: [ './section-packages.component.scss' ]
})
export class SectionPackagesComponent implements OnInit {
	private _displayEnd = 3;

	packageList: IDynamicPackage[] = [];
	displayPackageList: IDynamicPackage[] = [];

	constructor(private _dynamicPackagesService: DynamicPackagesService) {
	}

	ngOnInit(): void {
		this.getDynamicPackages();
	}

	private getDynamicPackages() {
		this._dynamicPackagesService.getPackages(0, LIMIT).subscribe({
			next: (response) => {
				if (response && response.Result) {
					this.packageList = response.Result.package;
					this.displayPackageList = [ ...this.packageList ].slice(0, this._displayEnd);
				}
			},
			error: (err) => console.error('Error retrieving packages:', err)
		});
	}

	showMoreLessPackages() {
		if (this._displayEnd < LIMIT && this.displayPackageList.length < this.packageList.length) {
			const newDisplayEnd = LIMIT;
			this.displayPackageList = [ ...this.packageList ].slice(0, newDisplayEnd);
			this._displayEnd = newDisplayEnd;
			return;
		}
		this._displayEnd = 3;
		this.displayPackageList = [ ...this.packageList ].slice(0, this._displayEnd);
	}
}
