import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface LogPayload {
	service: string,
	message: string
}

@Injectable({
	providedIn: 'root'
})
export class LogsService {

	constructor(private _httpClient: HttpClient) {
	}

	sendLog(data: LogPayload): Observable<string> {
		const url = `${environment.urlApiForms}/Log`;
		return this._httpClient.post<string>(url, data);
	}
}
