<div class="container-fluid bg-white">
    <div class="row shadow-sm py-4 mb-4 doNotPrint">
        <div class="col-6 titleMobile">
            <h1>Documentación de Viaje</h1>
        </div>
        <div class="col-6 text-end">
            <!-- <button class="me-2">Descargar</button> -->
            <button class="btnModal" (click)="onPrint()">Imprimir</button>
        </div>
    </div>
    <div class="container-fluid" id="exports" #exports>
        <div class="contentModal">
            <div class="imgCentral">
                <img src="./assets/modal/img-modal-01.png" alt="">
                <img src="./assets/modal/img-modal-02.png" alt="">
                <img src="./assets/modal/img-modal-03.png" alt="">
            </div>
            <p>Recuerde revisar su documentación antes del viaje, su
                <strong>Pasaporte /o su DNI</strong> debe tener una
                <strong>vigencia mínima de 6 meses desde la fecha de inicio de viaje.</strong>
                <br> Ud. debe consultar sí necesita
                <strong>VISA para viajar al país de destino</strong>, como así también verificar la vigencia requerida.
                <br> Si Ud. es peruano, recuerde que cuando viaja a Estados Unidos y/o Canadá requiere visa.
                <br> Así mismo, si su viaje realiza escala en estos países.</p>

            <p>Si cuenta con otra nacionalidad deberá verificar si requiere tramitar los permisos de viaje requeridos, en caso de Estados Unidos (ESTA) y en Canadá (ETA), los mismos que tienen que realizarse de preferencia entre 5 y hasta 15 días de anticipación.
                <br>
                <a href="#">https://esta.cbp.dhs.gov</a> (TRAMITAR ESTA)
                <br>
                <a href="#">http://www.cic.gc.ca/english/visit/eta-start-es.asp</a> (TRAMITAR ETA)
                <br> De acuerdo a su destino de viaje puede ser que sea necesario el certificado de VACUNA DE FIEBRE AMARILLA.
                <br>
                <strong>Es obligatorio para Peruanos</strong> (Aruba, Australia, Bolivia, Costa Rica, honduras, Guatemala, India, Jamaica, Salvador, Venezuela) Otros destinos verificar.
                <br> Infórmese sobre los requisitos sanitarios requeridos para su viaje. Le recordamos, si Ud. realiza el pre-chequeo electrónico con la aerolínea antes del vuelo, verifique y lea cuidadosamente las instrucciones y recomendaciones de la
                línea aérea en materia de equipajes permitidos, materiales peligrosos, hora de presentación al aeropuerto, sala de embarque y tome sus precauciones. </p>

            <p>El pasajero es responsable por cualquier problema que pudiera suscitarse en el momento del embarque por cuestiones de documentación personal necesaria para viajar y ajenas a NUEVO MUNDO VIAJES</p>
        </div>

        <div class="contentModBox">
            <div class="textTitle">Pasajeros Peruanos Viajando al Exterior</div>
            <ul class="listModal">
                <li>Países limítrofes: Cédula del MERCOSUR sin vencer, DNI en buen estado/no deteriorado o Pasaporte Peruano deberá contar con vigencia mínima 6 meses a partir de la fecha de viaje.</li>
                <li>Países no limítrofes: Pasaporte Peruano vigente mínimo 6 meses a partir de la fecha de viaje. Mayor de 18 años y si viaja a países miembros de la Comunidad Andina, puede viajar solo con su DNI vigente.
                </li>
                <li>Si no cuenta con residencia en el país de destino de viaje, recuerde que siempre debe viajar con un boleto aéreo de ida y retorno.</li>
            </ul>
            <div class="padLateral">
                <div class="titleCondicion">
                    Países que solicitan visa de turista para el ingreso a Peruano:
                </div>
                <ul class="listModal lessPad">
                    <li>Australia</li>
                    <li>Jamaica</li>
                    <li>Canadá</li>
                    <li>Japón</li>
                    <li>El Salvador</li>
                    <li>Nueva Zelanda</li>
                    <li>Estados Unidos</li>
                    <li>Reino Unido</li>
                    <li>Fiji</li>
                    <li>Otros países favor de verificar.</li>
                </ul>
            </div>
            <div class="titleCondicion">
                Pasajeros Menores de 18 años requieren:
            </div>
            <ul class="listModal">
                <li>Si viajan solos deben tener permiso de viaje notarial de los padres.</li>
                <li>Viajando con ambos padres: Partida de nacimiento o libreta de matrimonio donde figure el menor. Para ambos casos deben llevar documento del menor DNI, PASAPORTE deberá contar con vigencia mínimo 6 meses a partir de la fecha de viaje.
                </li>
                <li>Viajando con uno u otro padre deben tener permiso notarial de viaje autorizado por el otro padre. Documento del menor. DNI, PASAPORTE deberá contar con vigencia mínimo 6 meses a partir de la fecha de viaje.</li>
            </ul>
        </div>

        <div class="contentModBox">
            <div class="textTitle">Pasajeros extranjeros</div>
            <ul class="listModal">
                <li>Residentes no nacionalizados: DNI de extranjero, Residencia permanente o temporaria dentro de la vigencia.
                </li>
                <li>Para países no limítrofes/ limítrofes: Pasaporte de extranjero más DNI de extranjero.</li>
                <li>Residentes nacionalizados: Cédula del MERCOSUR, Pasaporte o DNI.</li>
            </ul>
            <div class="titleCondicion">
                Requisitos para casos especiales:
            </div>
            <ul class="listModal">
                <li>Extranjero tramitando residencia: Documento válido de su país de origen más certificado de tramitación de residencia con permanencia vigente.</li>
                <li>Pasajeros que cuentan con las siguientes nacionalidades* y Deseen ingresar a Estados Unidos deberán obtener una autorización a través: El Programa se llama ESTA (Electronic System For Travel Authorization) Pág. web:
                    <a href="https://esta.cbp.dhs.gov">https://esta.cbp.dhs.gov</a>
                </li>
            </ul>
            <div class="tablaModal">
                <div class="rowModal">
                    <div class="colModal">Andorra</div>
                    <div class="colModal">Dinamarca</div>
                    <div class="colModal">Hungría</div>
                    <div class="colModal">Liechtenstein</div>
                    <div class="colModal">Noruega</div>
                    <div class="colModal">Eslovenia</div>
                </div>
                <div class="rowModal">
                    <div class="colModal">Australia</div>
                    <div class="colModal">Estonia</div>
                    <div class="colModal">Islandia</div>
                    <div class="colModal">Lituania</div>
                    <div class="colModal">Portugal</div>
                    <div class="colModal">Corea del Sur</div>
                </div>
                <div class="rowModal">
                    <div class="colModal">Austria</div>
                    <div class="colModal">Finlandia</div>
                    <div class="colModal">Irlanda</div>
                    <div class="colModal">Luxemburgo</div>
                    <div class="colModal">República de Malta</div>
                    <div class="colModal">España</div>
                </div>
                <div class="rowModal">
                    <div class="colModal">Bélgica</div>
                    <div class="colModal">Francia</div>
                    <div class="colModal">Italia</div>
                    <div class="colModal">Mónaco</div>
                    <div class="colModal">San Marino</div>
                    <div class="colModal">Suecia</div>
                </div>
                <div class="rowModal">
                    <div class="colModal">Brunei</div>
                    <div class="colModal">Alemania</div>
                    <div class="colModal">Japón</div>
                    <div class="colModal">Países Bajos</div>
                    <div class="colModal">Singapur</div>
                    <div class="colModal">Suiza</div>
                </div>
                <div class="rowModal">
                    <div class="colModal">República Checa</div>
                    <div class="colModal">Grecia</div>
                    <div class="colModal">Letonia</div>
                    <div class="colModal">Nueva Zelanda</div>
                    <div class="colModal">Eslovaquia</div>
                    <div class="colModal">Reino Unido</div>
                </div>
            </div>
        </div>


        <div class="contentModBox">
            <div class="textTitle">Recomendaciones Importantes</div>
            <ul class="listModal">
                <li>Si Ud. viaja a Estados Unidos de América o tendrá una estadía superior a las 8Hs en México, por favor recuerde que debe tener pasaporte y visas vigentes, aunque sean vuelos en tránsito</li>
                <li>Si posee documentación de la Comunidad Europea, consulte con la representación consular del país de nacionalidad que posee y verifique si requiere alguna documentación adicional.</li>
                <li>Recuerde que para el ingreso a Europa debe consultar por la documentación que es requerida para el viaje.
                </li>
                <li>Los documentos para viaje deben encontrarse en buen estado, no deben estar deteriorados ni rotos.</li>
                <li>Se sugiere la compra de su tarjeta de asistencia.</li>
                <li>Cualquier duda se puede contactar al Departamento de Migraciones (51) 1-417-6900 o visite su página web:
                    <a href="http://www.digemin.gob.pe/.">http://www.digemin.gob.pe/.</a>
                </li>
            </ul>
        </div>

        <div class="contentModBox">
            <div class="textTitle">Reglamentación permiso de viaje de menores</div>
            <ul class="listModal">
                <li>Entregar la autorización de viaje expedida por el juez de familia o permiso notarial (si es otorgado en el país) o autorización consular legalizada por el Ministerio de Relaciones Exteriores (si es otorgada en el extranjero).</li>
                <li>La vigencia de las autorizaciones será no mayor a 60 días. En todos los casos válidos para un solo viaje.
                </li>
            </ul>
        </div>

        <div class="contentModBox">
            <div class="textTitle">Notas</div>
            <ul class="listModal">
                <li>En el caso de menor de edad residente en el extranjero que haya permanecido menos de tres (03) meses en el Perú presentará sólo su cédula o autorización de residencia en el extranjero.</li>
                <li>En aplicación del Convenio de Tránsito de Personas Perú-Ecuador u otros convenios Bilaterales y la Decisión № 503 de la Comunidad Andina, se podrá presentar partida de nacimiento en sustitución del pasaporte.</li>
            </ul>
        </div>

        <div class="contentRed mb-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8C11 8.26522 11.1054 8.51957 11.2929 8.70711C11.4804 8.89464 11.7348 9 12 9C12.2652 9 12.5196 8.89464 12.7071 8.70711C12.8946 8.51957 13 8.26522 13 8ZM12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17Z"
                fill="#DF0C30" />
        </svg> NUEVO MUNDO VIAJES no se hará responsable de los problemas que se pudieran suscitar en el embarque debido a documentación
        </div>
    </div>
</div>