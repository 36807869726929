<mat-drawer-container class="example-container">
	<app-search-tabs [indexSelectedTab]="indexSelectedTab" (clickedTab)="getSelectedTab($event)"></app-search-tabs>
	<div class="search__container p-3 p-md-4 px-lg-5" *ngIf="indexSelectedTab <= 4">
		<div class="search__container__metasearch__form container">
			<app-tab-vuelos-v2 *ngIf="indexSelectedTab === 0" [@fadeInOut]></app-tab-vuelos-v2>
			<app-tab-hotel *ngIf="indexSelectedTab === 1" [@fadeInOut]></app-tab-hotel>
			<app-tab-vuelo-hotel *ngIf="indexSelectedTab === 2" [@fadeInOut]></app-tab-vuelo-hotel>
			<app-tab-paquetes *ngIf="indexSelectedTab === 3" [@fadeInOut]></app-tab-paquetes>
			<app-filtersafe *ngIf="indexSelectedTab === 4" [@fadeInOut]></app-filtersafe>
		</div>
	</div>
	<router-outlet></router-outlet>
</mat-drawer-container>
