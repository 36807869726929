import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { GlobalComponent } from '../../global';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-input-class',
  templateUrl: './input-class.component.html',
  styleUrls: ['./input-class.component.scss']
})
export class InputClassComponent implements OnInit {
  @Input() isLeft=false;
  @Input() includeFirtClass=true;
  @Input() isSearchFlight=false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  showOptions=false;
  nameClass='Económica'
  indexClass=0;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.location.href.includes('resultados')) {
        this.indexClass = GlobalComponent.searchData.flightClass;
        this.nameClass = this.indexClass == 0 ? 'Económica' : this.indexClass == 1 ? 'Business' : 'Primera clase';
      }
    }
  }

  clickOption(nameClass:string, index:number){
    this.nameClass=nameClass;
    this.indexClass=index;
    this.showOptions=false;
  }

  clickInside(){
    this.showOptions=!this.showOptions;
  }

  getValues(){
    return {
      flightClass: this.indexClass,
    }
  }

}
