<div class="container-fluid bg-white py-3">
  <!-- TITULO -->
  <div class="row">
    <ng-container *ngIf="typePay === 'SAFETYPAY'; else tc">
      <div class="col-12 titleSuperior">
        ¡Felicidades, estás a un paso de tu viaje!
      </div>
    </ng-container>
    <ng-template #tc>
      <div class="col-12 titleSuperior">
        ¡Felicidades, prepara todo para tu viaje!
      </div>
    </ng-template>
  </div>
  <!-- END TITULO -->

  <!-- BANNER -->
  <div class="row py-5">
    <div class="col-8 offset-2">
      <div class="d-flex justify-content-center align-items-center">
        <img class="imgViewMovile" src="./../../../../assets/conformidad/felicidad.png" alt="">
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <!-- END BANNER -->

  <div class="row" *ngIf="typePay === 'TARJETA'">
    <div class="col-12 subTitleConf">
      <p>Excelente elección. </p>
      <p>Tu solicitud de compra N° <strong>{{ reservaStr?.Reserva }}</strong> ha sido recibida y está siendo procesada.
      </p>
    </div>
  </div>
  <div class="row" *ngIf="typePay === 'SAFETYPAY'">
    <div class="col-12 subTitleConf">
      <p>Excelente elección.</p>
      <p> Se ha completado la reserva N° <strong>{{ reservaStr?.Reserva }}</strong> y estamos a la espera del pago
        para completar tu compra!</p>

    </div>
  </div>

  <!-- ESPACIO -->
  <div class="padBancaInt" *ngIf="typePay === 'SAFETYPAY'">
  </div>
  <!-- END ESPACIO -->

</div>

<div class="bancaInt" *ngIf="typePay === 'SAFETYPAY'">
  <div class="bodyBanca">
    <div class="cardBlue">
      <div class="descrip">
        Información para pago a través de
      </div>
      <div class="descrip Bold">
        Banca por Internet
      </div>
      <div class="number" *ngIf="listBank">
        {{ listBank['TransactionIdentifier']}}
      </div>

      <div class="number" *ngIf="reservationCode">
        {{ reservationCode }}
      </div>

      <div class="circle leftPos"></div>
      <div class="circle rightPos"></div>
      <div class="sombraBlue int1"></div>
      <div class="sombraBlue int2"></div>
    </div>

    <!-- RESERVA SEGUROS -->
    <div class="cardRed" *ngIf="ShowComponentTime">
      <div class="descrip">
        Te quedan
      </div>
      <div class="hora">
        <div class="iconCard">
          <img src="./assets/conformidad/reloj.png" alt="">
        </div>
        <countdown [config]="{leftTime: timeShow}"></countdown>
      </div>
      <div class="fecha">
        <!-- Solo tienes hasta las {{ listBank.ExpirationDateTime.substr(11,5) }} p.m. del viernes 10 septiembre -->
        <!-- Solo tienes hasta las {{ listBank.ExpirationDateTime.substr(11,5) }} p.m. del {{
        listBank.ExpirationDateTime.substr(0,10)
        }} -->
        Solo tienes hasta las {{ timeLimit }} del {{ deadLine }}
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row py-5">
    <div class="offset-2 col-8 lessViewMob">
      <div class="card">
        <div class="card-body rowBody">
          <div class="leftCard">IMPORTE A PAGAR</div>

          <div class="rightCard">USD
            <div class="boldFont">{{ (resultJson.destinyString.es_nacional === 1) ? (shopString.PriceTotal *
              1.18) : shopString.PriceTotal | number:'1.2-2'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="safetyPay" *ngIf="typePay === 'SAFETYPAY'">
    <div class="rowSafety">
      <div class="columnSaf titleSafety">¿Cómo pagar con SafetyPay?</div>
    </div>
    <div class="rowSafety">
      <div class="columnSaf descripSafety">Ingresa a la Banca por Internet de tu preferencia y sigue las
        instrucciones
      </div>
    </div>

    <div class="rowSafety">
      <div class="columnSaf">
        <div class="iconCard">
          <img src="./assets/conformidad/safetyPay.png" alt="">
        </div>
      </div>
    </div>

    <div class="rowSafety padBottom50" *ngIf="listBank">
      <div class="columnSaf" *ngFor="let bn of bankSteps">
        <div class="boxBank selected">
          <img src="{{bn.Imagen}}" alt="{{bn.Name}}">
        </div>
      </div>

      <!-- <div class="columnSaf">
                <div class="boxBank selected"><img src="./assets/conformidad/bcp.png" alt=""></div>
            </div>
            <div class="columnSaf">
                <div class="boxBank"><img src="./assets/conformidad/bbva.png" alt=""></div>
            </div>
            <div class="columnSaf">
                <div class="boxBank"><img src="./assets/conformidad/interbank.png" alt=""></div>
            </div>
            <div class="columnSaf">
                <div class="boxBank lessMargin"><img src="./assets/conformidad/scotiabank.png" alt=""></div>
            </div> -->
    </div>

    <div class="rowSafety padBottom10" *ngIf="listBank">
      <div class="columnSaf colCenter colMobile" *ngFor="let step of bankSteps[0].PaymentSteps">
        <div class="headPaso">
          <div class="number">{{ step.Step }}</div>
        </div>
        <div class="textPaso">{{ step.Value }}</div>
      </div>
      <!-- <div class="columnSaf colCenter colMobile">
                <div class="headPaso">
                    <div class="number">2</div>
                </div>
                <div class="textPaso">Selecciona la opción <strong>“Pagos y Transferencias” - “Pago de servicios”</strong></div>
            </div>
            <div class="columnSaf colCenter colMobile">
                <div class="headPaso">
                    <div class="number">3</div>
                </div>
                <div class="textPaso">Selecciona <strong>“Empresas Diversas” - “SafetyPay”</strong> y el tipo de moneda.</div>
            </div>
            <div class="columnSaf colCenter colMobile">
                <div class="headPaso">
                    <div class="number">4</div>
                </div>
                <div class="textPaso">Ingresa tu código de pago <strong>186990</strong>, verifica el monto, confirma el pago con tu <strong>clave token</strong> listo!
                </div>
            </div> -->
    </div>
  </div>

  <!-- PASAJEROS -->
  <div class="row text-center">
    <div class="titleConf">Datos de asegurado(s)</div>
  </div>

  <div class="row py-4">
    <div class="offset-3 col-6 viewMobile">
      <div class="card" *ngFor="let customer of shopString.customers">
        <div class="card-body">
          <ng-container>
            <div class="titleAsegurado">
              {{ customer.nameCustomer | uppercase }} {{ customer.lastNameCustomer | uppercase }}
            </div>
            <div class="fechaAsegurado">
              {{ customer.dayCustomer }} / {{ customer.monthCustomer }} / {{ customer.yearCustomer }}
            </div>

            <div class="descripAsegurado">
              Tu solicitud de compra es {{ paymentData.Result.QuoteId }}.
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- END PASAJEROS -->

  <!-- COBERTURA-->
  <div class="row text-center">
    <div class="titleConf">
      Detalle de tu cobertura
    </div>
  </div>
  <div class="row py-2">
    <div class="offset-4 col-4 viewMobile">
      <div *ngIf="safe0Json.detalleCobertura" class="order-0">
        <app-coverage [txtPlan]="safe0Json?.nombreProducto" [txtDias]="resultJson?.days" [txtOrigen]=""
          [txtDestino]="resultJson?.destinyString.descripcion_destino" [txtSalida]="resultJson?.fromDate"
          [txtRegreso]="resultJson?.toDate" [txtPasajeros]="resultJson?.passengers.length" [plan]="safe0Json">
        </app-coverage>
      </div>
    </div>
  </div>
  <!-- END COBERTURA-->

  <!-- DATOS DE CONTACTO -->
  <div class="row text-center">
    <div class="titleConf">
      Datos de Contacto
    </div>
  </div>
  <div class="row py-4">
    <div class="offset-3 col-6 viewMobile">
      <div class="card padCard">
        <div class="rowCard">
          <div class="columnCard">

          </div>
        </div>
        <div class="rowCard">
          <div class="columnCard boldCol">
            <div class="iconCard">
              <img src="./assets/conformidad/user.png" alt="">
            </div>
            Nombres y apellidos:
          </div>
          <div class="columnCard">
            {{ shopString.contactForm.nameContacto | uppercase }} {{ shopString.contactForm.lastnameContacto
            | uppercase }}
          </div>
        </div>
        <div class="rowCard">
          <div class="columnCard boldCol">
            <div class="iconCard">
              <img src="./assets/conformidad/mail.png" alt="">
            </div>
            Email:
          </div>
          <div class="columnCard">
            {{ shopString.contactForm.mailContacto | uppercase}}
          </div>
        </div>
        <div class="rowCard">
          <div class="columnCard boldCol">
            <div class="iconCard">
              <img src="./assets/conformidad/phone.png" alt="">
            </div>
            Teléfono(s):
          </div>
          <div class="columnCard">
            CELULAR {{ shopString.contactForm.code0 | uppercase }} {{ shopString.contactForm.numberPhone0 }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END DATOS DE CONTACTO-->

  <!-- MODAL COBERTURA -->
  <div class="modal fade" id="ModalCoverageI" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">{{safe0Json?.producto}} -
            {{safe0Json?.precioEmisionLocal | currency : 'USD'}}
          </h3>
          <h5 class="modal-subtitle" id="exampleModalLabel">Asistencia médica: {{ asistMedic }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" *ngIf="!coverage">
          <div class="row">
            <p class="text">!Cargando Cobertura¡</p>
            <mat-spinner color="warn"></mat-spinner>
          </div>
        </div>
        <div class="modal-body" *ngIf="coverage">
          <div class="row">
            <div class="col-12">
              <div class="d-flex py-3">
                <img src="./assets/seguros/covid.png" alt="">
                <div class="px-2">
                  <span class="covid">COBERTURA</span>
                  <span class="covid">COVID</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row scrollBody">
            <div class="col-10 offset-1">
              <table class="table-bordered w-100">
                <tbody *ngIf="coverage">
                  <tr class="row" *ngFor="let text of coverage">
                    <td class="col d-flex align-items-center">
                      <strong>{{ text?.Valor }}</strong>
                    </td>
                    <td class="col">{{ text?.NombreVisible}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>