import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentacionViajeComponent } from './documentacion-viaje.component';

@NgModule({
  declarations: [
    DocumentacionViajeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DocumentacionViajeComponent
  ],
})
export class DocumentacionViajeModule { }
