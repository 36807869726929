<div class="package" [ngClass]="{'card__internacional': fly}">
    <img loading="lazy" [src]="image" [alt]="city">
    <div class="shadow"></div>
    <div class="d-flex align-items-start flex-column pb-md-3">
        <div class="mb-auto d-flex w-100 align-items-start justify-content-between">
            <h6>{{label}}</h6>
            <p class="py-2 px-2">{{ span }}
                <svg *ngIf="label" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.5858 13.8564H0.414201C0.185479 13.8564 0 14.0419 0 14.2706C0 14.4994 0.185479 14.6848 0.414201 14.6848H20.5858C20.8145 14.6848 21 14.4994 21 14.2706C21 14.0419 20.8145 13.8564 20.5858 13.8564Z"
                        fill="white" />
                    <path d="M0.199796 7.43127L3.25258 9.51781C3.94007 9.98772 4.74826 10.2275 5.55989 10.2274C6.14744 10.2274 6.7368 10.1018 7.28313 9.84677L10.2909 8.44105C10.4981 8.34417 10.5875 8.09764 10.4907 7.89046C10.3939 7.68323 10.1474 7.59381 9.94007 7.69056L6.9326 9.09624C5.89556 9.58011 4.66464 9.47962 3.71997 8.83393L1.19504 7.10815L2.33144 6.41114L5.25599 7.3812C5.35685 7.4147 5.46674 7.40808 5.56291 7.36276L9.04759 5.72128C9.17744 5.66011 9.26633 5.53614 9.28261 5.39353C9.29888 5.25096 9.24019 5.11009 9.12745 5.02124L5.28233 1.99123L6.77777 1.22819L13.6943 3.41642C13.7936 3.44786 13.9011 3.44065 13.9954 3.39641L18.6229 1.22198C19.1452 1.00336 19.7774 1.24223 20.0349 1.75675C20.1666 2.01986 20.1858 2.31775 20.0891 2.5956C19.9924 2.87344 19.7923 3.09496 19.5256 3.21947L13.3091 6.12356C13.1018 6.22035 13.0123 6.46689 13.1091 6.67411C13.2059 6.88134 13.4524 6.97097 13.6597 6.87409L19.8761 3.97008C20.347 3.75026 20.7005 3.35889 20.8714 2.86802C21.0424 2.37715 21.0084 1.85082 20.7757 1.38604C20.3248 0.485156 19.2104 0.0709545 18.2917 0.462706C18.2871 0.464653 18.2825 0.466724 18.278 0.468837L13.7898 2.57783L6.86574 0.387156C6.76207 0.354351 6.64949 0.363629 6.55252 0.413126L4.32971 1.54733C4.20396 1.61149 4.11955 1.73534 4.10575 1.87584C4.09196 2.01633 4.1507 2.15422 4.26162 2.24162L8.08863 5.25734L5.35971 6.54281L2.40948 5.56422C2.29354 5.52582 2.16663 5.54044 2.0625 5.60427L0.216944 6.73624C0.09724 6.80964 0.0228909 6.93875 0.0194116 7.07908C0.0159738 7.21949 0.0839028 7.35204 0.199796 7.43127Z"
                        fill="white" />
                    <path d="M11.622 6.90127C11.415 6.99798 11.3256 7.24418 11.4223 7.4512C11.519 7.65822 11.7652 7.74764 11.9722 7.65093C12.1792 7.55421 12.2687 7.30801 12.172 7.10099C12.0752 6.89402 11.829 6.80459 11.622 6.90127Z"
                        fill="white" />
                </svg>
            </p>
        </div>
        <div class="show d-md-flex w-100 align-items-end justify-content-between">
            <div [ngClass]="{'w-100 text-center': fly}">
                <span *ngIf="destiny" class="hidden">¡Vamos!
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.22168 6.4825H14.5749M14.5749 6.4825L9.58248 0.741211M14.5749 6.4825L9.58248 12.2238" stroke="white" />
                    </svg>
                </span>
                <h3 class="fs-3 fw-bold">{{ city }}</h3>
                <button (click)="toDestiny(rout)" class="hidden mb-2" *ngIf="btn">{{ btn }}</button>
                <span *ngIf="destiny" class="hidden">{{ destiny }} - </span>
                <span class="d-inline"> {{ pack }}</span>
                <span class="d-inline d-md-none d-inline"> desde</span>
            </div>
            <div>
                <div *ngIf="price" class="d-md-block d-none text-center">
                    <span class="desde">desde</span>
                    <mark>${{ price }}
                    </mark>
                </div>
                <div *ngIf="price" class="d-md-none d-block">
                    <mark>${{ price }}</mark>
                </div>
            </div>
        </div>
    </div>
</div>