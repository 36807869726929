<!-- VUELOS -->
<section class="container-fluid  px-5">
  <div class="row mb-4 mt-5 ms-4">
    <div class="col-12">
      <!-- <ng-container *ngFor="let section of dataPagePresenterService.data.sections">
                    <app-title *ngIf="section.section === 'Vuelos'" [title]="section.title"></app-title>
                </ng-container> -->
      <h3 class="titleHome">
        <strong>Pasajes Aéreos</strong>
      </h3>
      <h4 class="titleHome">Oferta de Vuelos Nacionales e Internacionales.</h4>
    </div>
    <div class="clearfix"></div>
  </div>

  <!-- <app-card [itemList]="packagesService.packages"></app-card> -->
  <app-flight-deals></app-flight-deals>

  <!-- <div class="row text-end py-5 d-none d-md-block">
        <div class="col-12">
            <ng-container>
                <button class="plus dropdown-toggle px-4">Más ofertas</button>
            </ng-container>
        </div>
        <div class="clearfix"></div>
    </div> -->
</section>
<!-- END VUELOS -->

<section class="container-fluid fluid- px-5">
  <div class="row">
    <div class="col-12">
      <h3 class="titleHome">
        <strong>Vuelos Internacionales</strong>
      </h3>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="row">
    <div *ngFor="let vuelo of $vuelosInternacionales | async" class="col-12 col-lg-3 col-md-4 col-sm-6">
      <app-package [fly]="'true'" btn="Ver Tarifas" [codigo_ciudad]="vuelo.DestinationCode" [image]="vuelo.Image"
        [city]="vuelo.Destination" [span]="vuelo | generateprice"></app-package>
    </div>
    <div class="clearfix"></div>
  </div>

  <div class="row">
    <div class="col-12">
      <h3 class="titleHome padTitle">
        <strong>Vuelos Nacionales</strong>
      </h3>
    </div>
  </div>
  <div class="row boxVueloNac verMovil">
    <div class="col-5 boxVueloPackage">
      <div class="boxVuelos">
        <div (click)="toDestiny(packb)" *ngFor="let packb of $vuelosNacionales | async" class="rowVuelo">
          <div class="lugar">{{packb.Destination}}</div>
          <div class="desde">desde</div>
          <div class="precio">${{packb.Rate}}</div>
          <div class="btnCircle">
            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M-6.56879e-08 11.4972L4.83081 6.60526L-4.93358e-07 1.71329L1.48721 0.210525L7.81579 6.60526L1.48722 13L-6.56879e-08 11.4972Z"
                fill="white" />
            </svg>
          </div>
        </div>

      </div>
    </div>
    <div class="col-7 box-img-nacionales">
      <div class="boxImgVuelo">
        <div class="gridVuelo">
          <div class="gridItem1">
            <img src="./assets/vuelos/img-01.webp" alt="Huacachina" loading="lazy">
          </div>
          <div class="gridItem2">
            <img src="./assets/vuelos/img-02.webp" alt="Amazonas" loading="lazy">
          </div>
          <div class="gridItem3">
            <img src="./assets/vuelos/img-03.webp" alt="Machu Picchu" loading="lazy">
          </div>
          <div class="gridItem4">
            <img src="./assets/vuelos/img-04.webp" alt="Arequipa" loading="lazy">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="viajaBox">
  <div class="container-fluid">
    <div class="row mnuViaja">
      <div class="col-md-5 padLeft70">
        <img src="./assets/vuelos/viaja.png" alt="Viaja al Per&uacute; y al mundo" loading="lazy">
      </div>
      <div class="col-md-7 vuelos" *ngIf="$vuelos">
        <div (click)="redirigirVuelo(item)" class="subMenu vuelos__vuelo" *ngFor="let item of $vuelos | async">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29:295)">
              <path
                d="M3.8147 18.1926C3.53921 18.3219 3.41767 18.6473 3.54044 18.9254L4.79876 21.6266C4.86058 21.7611 4.97336 21.8658 5.11207 21.9175C5.17284 21.9395 5.23728 21.9509 5.30208 21.9509C5.38317 21.9516 5.4632 21.9343 5.53693 21.9008L15.0441 17.4602L14.183 26.4639C14.1663 26.6411 14.2346 26.8154 14.3672 26.9338C14.784 27.2788 15.3035 27.4753 15.8439 27.493C15.9929 27.4928 16.1411 27.474 16.2856 27.4373C17.0574 27.2359 17.6558 26.5481 18.064 25.3735L21.8054 14.2951L29.2152 10.8387C31.3962 9.82101 32.5202 7.75744 31.7654 6.14676C31.337 5.3049 30.5166 4.73222 29.5787 4.61997C28.5367 4.4627 27.4717 4.62662 26.5253 5.09002L17.9744 9.09964L8.78071 5.50368C7.62292 5.0676 6.70593 5.07881 6.06285 5.54308C5.52327 5.98581 5.21627 6.65131 5.2294 7.34921C5.23501 7.52627 5.32415 7.69001 5.46986 7.79124L11.6606 12.0469L8.11521 13.7247L2.17022 12.2145C1.92661 12.1546 1.67302 12.2644 1.54955 12.483L0.0677707 15.1504C-0.00911178 15.2917 -0.0211958 15.4595 0.034671 15.6105C0.0901874 15.7616 0.208226 15.8812 0.358663 15.939L4.75988 17.7567L3.8147 18.1926ZM6.37564 7.0697C6.40856 6.83327 6.52817 6.61804 6.71136 6.46567C7.03027 6.23625 7.61188 6.26988 8.38912 6.56059L16.5765 9.75393L12.8406 11.4988L6.37564 7.0697ZM1.34272 15.145L2.31575 13.417L8.03675 14.8373C8.16004 14.8685 8.29017 14.8567 8.4061 14.804L27.0008 6.11891C27.74 5.75429 28.5726 5.62154 29.3887 5.73853C29.961 5.79124 30.4708 6.12154 30.7533 6.62206C31.2227 7.62889 30.3001 9.09964 28.7397 9.82661L22.2973 12.8467L22.6889 11.6888C22.7879 11.3909 22.6262 11.069 22.3283 10.9702C22.0301 10.8714 21.7083 11.0331 21.6096 11.331L17.007 25.0266C16.7329 25.8042 16.3917 26.2684 16.0115 26.3688C15.776 26.4141 15.5322 26.3679 15.3292 26.2404L16.4478 14.4961C16.477 14.1874 16.2506 13.9133 15.9415 13.884C15.6327 13.8544 15.3586 14.0812 15.3292 14.39L15.156 16.1851L5.55917 20.659L4.77109 18.9812L6.34779 18.2432C6.55077 18.1537 6.68159 17.953 6.68159 17.7315C6.68159 17.5098 6.55077 17.3091 6.34779 17.2198L1.34272 15.145Z" />
            </g>
            <defs>
              <clipPath id="clip0_29:295">
                <rect width="32" height="32" />
              </clipPath>
            </defs>
          </svg> {{item.Description}}
        </div>
      </div>
    </div>
  </div>
</section>

<section class="slider-container mx-auto">
  <app-aereolineas [aereolineas]="$aereolineas | async" (redirigir)="toLine($event)"></app-aereolineas>
</section>