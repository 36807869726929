<!--assets/svg/radio-check.svg-->
<form [formGroup]="form" class="search__flightHotel">
	<div class="section__flightHotel__tags">
		<app-input-class [includeFirtClass]="false" [isLeft]="true" #childClass></app-input-class>
		<app-pop-up-pasajero
			[nroAdultos]="2"
			#popUp
			(emitDistributionObject)="distributionObject = $event"
			(emitDistribution)="distribution = $event"
		></app-pop-up-pasajero>
	</div>
	<div class="section__flightHotel__inputs">
		<section>
			<div class="section__flightHotel__inputs__group">
				<input
					matInput
					aria-label="State"
					[matAutocomplete]="auto1"
					formControlName="origen"
					(keyup)="autoComplete($event, 1)"
					[ngClass]="{ error: validateForm('origen') }"
					placeholder="Origen"
					(keypress)="inputValidator.removeNonValid($event, inputValidator.VALID_NAME)"
				/>
				<img src="./assets/svg/location-red.svg" alt="o" />
			</div>
			<mat-autocomplete #auto1="matAutocomplete" class="single-autocomplete">
				<mat-option *ngFor="let state of citysOrigenSelect" [value]="state.label">
					<img class="example-option-img" aria-hidden src="./assets/svg/airplane-line.svg" />
					<span>{{ state.label }}</span>
				</mat-option>
			</mat-autocomplete>
			<div class="section__flightHotel__inputs__group">
				<input
					matInput
					aria-label="State2"
					[matAutocomplete]="auto2"
					formControlName="destino"
					(keyup)="autoComplete($event, 2)"
					placeholder="Destino"
					[ngClass]="{ error: validateForm('destino') }"
					(keypress)="inputValidator.removeNonValid($event, inputValidator.VALID_NAME)"
				/>
				<img src="./assets/svg/location-red.svg" alt="o" />
			</div>
			<mat-autocomplete #auto2="matAutocomplete">
				<mat-option *ngFor="let state of citysDestinosSelect" [value]="state.label">
					<img class="example-option-img" aria-hidden src="./assets/svg/airplane-line.svg" />
					<span>{{ state.label }}</span>
				</mat-option>
			</mat-autocomplete>
		</section>
		<app-input-range #childDates [typeFlight]="-1"></app-input-range>
		<app-button [text]="'Buscar'"  [fullWidth]="true" [isMobile]="true" [left]="true" [icon]="'search.svg'" (onClick)="searchVueloHotel()"></app-button>
	</div>
</form>
