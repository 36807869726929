<div class="tab-autocomplete">
    <div class="iconDestino">
        <img src="./assets/origen.png" alt="">
    </div>
    <input #inputSearch [(ngModel)]="valueInput" (keypress)="inputValidator.removeNonValid($event, inputValidator.VALID_NAME); onkeypress($event)" #input [ngClass]="{'isvalid':(input.value.length<minTermLength && validRequired)}"
        [placeholder]="placeholder" [disabled]="isDisabled" (keyup)="keyUp($event)" (keydown)="keyDown($event)" (focus)="showAutocomplete()" class="form-control animated fadeIn" type="text">

    <svg (click)="clean()" *ngIf="input.value.length>0 && !loading" class="input-icon pointer" width="461" height="461" viewBox="0 0 461 461" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_101_50)">
      <path
        d="M285.08 230.397L456.218 59.2699C462.294 53.1929 462.294 43.3589 456.218 37.2839L423.511 4.56489C420.598 1.65389 416.645 0.0148926 412.519 0.0148926C408.392 0.0148926 404.439 1.65389 401.526 4.56489L230.388 175.705L59.25 4.56489C56.337 1.65389 52.384 0.0148926 48.257 0.0148926C44.131 0.0148926 40.177 1.65389 37.265 4.56489L4.55799 37.2839C-1.51901 43.3589 -1.51901 53.1929 4.55799 59.2699L175.696 230.398L4.57499 401.505C-1.49901 407.582 -1.49901 417.416 4.57499 423.491L37.284 456.21C40.195 459.121 44.149 460.76 48.276 460.76C52.403 460.76 56.356 459.121 59.27 456.21L230.387 285.09L401.505 456.21C404.418 459.121 408.371 460.76 412.498 460.76C416.626 460.76 420.579 459.121 423.49 456.21L456.199 423.491C462.273 417.416 462.273 407.582 456.199 401.505L285.08 230.397Z"
        fill="#999" />
    </g>
    <defs>
      <clipPath id="clip0_101_50">
        <rect width="460.775" height="460.775" fill="white" />
      </clipPath>
    </defs>
  </svg>

    <svg *ngIf="loading" version="1.1" id="L9" class="input-icon-loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
    <path fill="#999"
      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
        to="360 50 50" repeatCount="indefinite" />
    </path>
  </svg>

    <div *ngIf="input.value.length<minTermLength && input.value.length !== 0" class="resultVuelo" [class.active]="boxOrigenTerm">
        <div class="rowVuelo">
            <div class="primaryTitle">
                <span>{{typeToSearchText}}</span>
            </div>
        </div>
    </div>

    <div class="div" #cardAutocompleteComponent>
        <app-card-autocomplete (itmSelected)="selectItm($event)" [cardAutocomplete]="items || []" [boxOrigen]="boxOrigen" [inputText]="inputTxt"></app-card-autocomplete>
    </div>
</div>