import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { HomeService } from '../../../features/home/services';
import { ToolbarService } from '../../../shared/services';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-home-advertisement',
	templateUrl: './home-advertisement.component.html',
	styleUrls: [ './home-advertisement.component.scss' ]
})
export class HomeAdvertisementComponent implements OnInit, OnDestroy {
	protected isBrowser = false;

	advertisementContent: string = '';
	unsubscribe$ = new Subject<void>();
	intervalSubscription: Subscription;

	constructor(
			@Inject(PLATFORM_ID) private platformId: Object,
			private homeService: HomeService,
			private toolBarService: ToolbarService
	) {
		if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
	}

	ngOnInit(): void {
		this.getAdvertisement();
	}

	private getAdvertisement() {
		this.homeService
				.getAdvertisement()
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (data) => {
						if (data?.result?.advertisementContent && data?.result?.startDate && data?.result?.endDate) {
							const startDate = new Date(data.result.startDate);
							const endDate = new Date(data.result.endDate);
							const currentDate = new Date();

							if (currentDate >= startDate && currentDate <= endDate) {
								this.advertisementContent = this.truncateText(data.result.advertisementContent);
								this.emitContent();
								if (this.isBrowser) {
									this.intervalSubscription = interval(1000)
											.pipe(
													takeWhile(() => {
														const now = new Date();
														if (now > endDate) {
															this.advertisementContent = '';
															this.emitContent();
															return false;
														}
														return true;
													})
											).subscribe();
								}
							} else {
								this.advertisementContent = '';
							}
						} else {
							this.advertisementContent = '';
						}
						this.toolBarService.statusShowAdvertisement$.next(!!this.advertisementContent);
					},
					error: (_) => {
						this.toolBarService.statusShowAdvertisement$.next(!!this.advertisementContent);
					}
				});
	}

	private truncateText(text: string): string {
		return text.length > 500 ? text.slice(0, 500) : text;
	}

	private emitContent() {
		this.toolBarService.advertisementContent$.next(this.advertisementContent);
		this.toolBarService.statusShowAdvertisement$.next(!!this.advertisementContent);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
	}
}
