import { StringUtils } from './string-utils';

export class DateUtils {
	/**
	 * The function **fromISO8601toDateTimeFormat()** converts a date string in ISO 8601 format (YYYY-MM-DD)
	 * to a date-time format.
	 * @param {string} dateStr - The date string in ISO 8601 format.
	 * @returns {string | null} Returns the date-time string in ISO 8601 format, or null if the input is invalid.
	 * @example
	 * const dateTime = DateUtils.fromISO8601toDateTimeFormat('2024-03-22');
	 * console.log(dateTime); // Expected output: '2024-03-22T00:00:00.000Z'
	 */
	static fromISO8601toDateTimeFormat(dateStr: string): string | null {
		const regex = /^\d{4}-\d{2}-\d{2}$/;
		if (!regex.test(dateStr))
			return null;

		const date = new Date(dateStr);

		if (isNaN(date.getTime())) return null;

		return date.toISOString();
	}

	static fromDateStringToDateTimeFormat(dateStr: string): string | null {
		const regex = /^\d{2}\/\d{2}\/\d{4}$/;
		if (!regex.test(dateStr))
			return null;

		const dateStrArr = dateStr.split('/');
		const date = Number.parseInt(dateStrArr[0]);
		const month = Number.parseInt(dateStrArr[1]) - 1;
		const year = Number.parseInt(dateStrArr[2]);

		const assembledDate = new Date(year, month, date);

		if (isNaN(assembledDate.getTime())) return null;

		return assembledDate.toISOString();
	}

	static formatToHumanDate(dateStr: string): string | null {
		const parts = dateStr.split('-');
		if (parts.length !== 3) return null;

		const day = parseInt(parts[0], 10);
		const month = parseInt(parts[1], 10) - 1;
		const year = parseInt(parts[2], 10);

		const date = new Date(year, month, day);
		if (isNaN(date.getTime())) return null;

		const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: '2-digit', month: 'long' };
		return date.toLocaleDateString('es-ES', options);
	}

	static formatToDateStr(dateStr: string): string | null {
		const parts = dateStr.split('-');
		if (parts.length !== 3) return null;

		const day = parseInt(parts[2], 10);
		const month = parseInt(parts[1], 10) - 1;
		const year = parseInt(parts[0], 10);

		const date = new Date(year, month, day);
		if (isNaN(date.getTime())) return null;

		return `${day < 10 ? '0' + day : day}/${month < 10  ? '0' + month : month}/${year}`;
	}

	static formatToHumanShortenDate(dateStr: string): string | null {
		const dateParts = dateStr.split('-');
		if (dateParts.length !== 3) return null;

		const day = parseInt(dateParts[0], 10);
		const month = parseInt(dateParts[1], 10) - 1;
		const year = parseInt(dateParts[2], 10);

		const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];

		const date = new Date(year, month, day);
		if (isNaN(date.getTime())) return null;

		const monthName = months[month];
		return `${day} de ${StringUtils.capitalizeWords(monthName)} ${year}`;
	}
}
