<div class="containerError">

    <div class="msgError">
        <div class="titulo">404
            <!-- <div class="imgBoxError">
        <img src="./assets/img/404.webp" alt="">
      </div> -->
        </div>
        <div class="mensaje">Página no encontrada <br>
            <span>
        Upss! Lo sentimos, se produjo un error inesperado. Por favor, vuelva a realizar la búsqueda.
      </span>

        </div>
        <a class="boton" routerLink="/" target="_self">
            <img class="white" src="./assets/img/home.webp" alt="">
            <img class="gris" src="./assets/img/home-gris.svg" alt=""> Volver a la página de inicio
        </a>
    </div>
</div>
