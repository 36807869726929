import { IHotSale } from '../models';

export const HOT_SALE_DATA = [
	{
		id: 1,
		title: 'Cyber Days con NM Viajes',
		items: [
			{
				id: 1,
				title: 'Año Nuevo Tarapoto + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FIi1QUqwoUwKfSQOTurY15JQ8.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Tarapoto (TPP)',
				tag: '',
				airline: 'SKY',
				price: '574',
				departureDate: '30/12/2024',
				returnDate: '02/01/2024',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FCLKrxtYiV67O-FNXPrBLlCwjpeg.jpeg',
				description: 'Tarapoto, Perú',
				itinerary: [
					{
						day: '30',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Cadete Guillermo Del Castillo Paredes (TPP).'
					},
					{
						day: '30',
						month: 'dic',
						description: '<b>Tarapoto,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Cumbaza Hotel & Convenciones.<br>' +
								'<b>Traslado:</b> de Cadete Guillermo Del Castillo Paredes (TPP) a Cumbaza Hotel & Convenciones.<br>' +
								'<b>Traslado:</b> de Cumbaza Hotel & Convenciones a Cadete Guillermo Del Castillo Paredes (TPP).<br>' +
								'<b>Transporte:</b> Desde Cadete Guillermo Del Castillo Paredes a Jorge Chavez Intl.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					transfers: 2,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '30/12/2024', description: 'lunes 30 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Standard - Doble', price: 574 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2F45dWnudj94XS-wNE6TN1Bc4cpjbn.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2Fj9Ua39fOFObW-yszZE8FogQpBIBX.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FpyJjE7Lr2fpI-7nOVGDRlOghUltG.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2Fkd4xOKZ8UPwv-q8wg9AlPcSgJxLR.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=10134443&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 2,
				title: 'Punta Cana + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2F5hMhSfK7XrC2-cZhtelKBhzjpeg.jpeg',
				days: 5,
				nights: 4,
				origin: 'Lima (LIM)',
				destination: 'Punta Cana Intl (PUJ)',
				tag: '',
				airline: 'LATAM',
				price: '2,166',
				departureDate: '09/09/2024',
				returnDate: '13/09/2024',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fn8UsK65gWchxLGiEBu-BrzldrpZDOCquCT.png',
				description: 'Punta Cana, República Dominicana',
				itinerary: [
					{
						day: '09',
						month: 'sep',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM)  a Punta Cana Intl (PUJ)'
					},
					{
						day: '09',
						month: 'sep',
						description: '<b>Punta Cana,</b> República Dominicana.<br>' +
								'<b>Alojamiento:</b> Impressive Punta Cana - All inclusive<br>' +
								'<b>Traslado:</b> de Punta Cana Intl (PUJ) a Impressive Punta Cana - All inclusive<br>' +
								'<b>Traslado:</b> de Impressive Punta Cana - All inclusive a Punta Cana Intl (PUJ)<br>' +
								'<b>Transporte:</b> Desde Punta Cana Intl a Jorge Chavez Intl.<br>' +
								'<b>4</b> Noches.'
					},
					{
						day: '13',
						month: 'sep',
						description: '<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					transfers: 2,
					allInclusive: 4,
					baggage: 1
				},
				important: [
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto\n' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben\n' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '09/09/2024', description: 'lunes 09 de septiembre 2024' } ],
				roomType: [ { value: 1, description: 'Tropical View', price: 2166 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F28000000%2F27580000%2F27573300%2F27573282%2F404fc3bf_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F28000000%2F27580000%2F27573300%2F27573282%2Fde2c9d51_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F28000000%2F27580000%2F27573300%2F27573282%2Fd30e8845_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F28000000%2F27580000%2F27573300%2F27573282%2F4a3b70af_z.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=9781083&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 4,
				title: 'Cartagena + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fp9via82P4oRS-94tM3FSCCzqzBu7.jpeg',
				days: 5,
				nights: 4,
				origin: 'Lima (LIM)',
				tag: 'Cartagena (CTG)',
				airline: 'LATAM',
				price: '1,450',
				departureDate: '18/09/2024',
				returnDate: '22/09/2024',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FCIqhE6okCcYv-tOuhh07zxaKcPOp.jpeg',
				description: 'Cartagena, Colombia',
				itinerary: [
					{
						day: '18',
						month: 'sep',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Rafael Nunez (CTG)'
					},
					{
						day: '18',
						month: 'sep',
						description: '<b>Cartagena de Indias,</b> Colombia.<br>' +
								'<b>Alojamiento:</b> Atlantic Lux Hotel.<br>' +
								'<b>Traslado:</b> de Rafael Nunez (CTG) a Atlantic Lux Hotel.<br>' +
								'<b>Traslado:</b> de Atlantic Lux Hotel a Rafael Nunez (CTG).<br>' +
								'<b>Transporte:</b>  Desde Rafael Nunez a Jorge Chavez Intl.<br>' +
								'<b>4</b> Noches.'
					},
					{
						day: '22',
						month: 'sep',
						description: '<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					transfers: 2,
					insurances: 1,
					breakfast: 4,
					baggage: 1
				},
				important: [
					{ description: 'Internacional - Seguro (Assist Card: Tarjeta de Asistencia (Cobertura de $30,000)) - Región (Solo continente) - Días (5)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '18/09/2024', description: 'miércoles 18 de septiembre 2024' } ],
				roomType: [ { value: 1, description: 'Standard Doble', price: 1450 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Fhotels%2F6000000%2F5230000%2F5227800%2F5227749%2Fb3efb42d_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Fhotels%2F6000000%2F5230000%2F5227800%2F5227749%2F23c3a8cb_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Fhotels%2F6000000%2F5230000%2F5227800%2F5227749%2F1728c18e_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fi.travelapi.com%2Fhotels%2F6000000%2F5230000%2F5227800%2F5227749%2F9b171b85_z.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=9850996&lang=es&currency=USD&agencyId=162512'
			}
		] as IHotSale[]
	}
] as const;