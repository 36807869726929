<div class="position-relative w-100" style="height: 792px">
	<div class="flotante start-0 bottom-0 ms-md-2 mb-2">
		<div class="buscador col">
			<div class="mat-div d-flex w-75">
				<mat-icon>search</mat-icon>
				<input type="text" placeholder="Ingresa tu calle o distrito" />
			</div>
			<hr />
			<div class="boxbtn">
				<button (click)="agenciaView(1)" class="btn-agencias" [ngClass]="btnActivo === 1 ? 'activa' : ''">
					NM 28 de julio
				</button>
				<button (click)="agenciaView(6)" class="btn-agencias" [ngClass]="btnActivo === 6 ? 'activa' : ''">
					NM CC. Mega Plaza
				</button>
				<button (click)="agenciaView(7)" class="btn-agencias" [ngClass]="btnActivo === 7 ? 'activa' : ''">
					NM San Isidro
				</button>
				<button (click)="agenciaView(2)" class="btn-agencias" [ngClass]="btnActivo === 2 ? 'activa' : ''">
					NM Pardo
				</button>
				<button (click)="agenciaView(3)" class="btn-agencias" [ngClass]="btnActivo === 3 ? 'activa' : ''">
					NM Primavera
				</button>
				<button (click)="agenciaView(5)" class="btn-agencias" [ngClass]="btnActivo === 5 ? 'activa' : ''">
					NM La Molina
				</button>
				<button (click)="agenciaView(4)" class="btn-agencias" [ngClass]="btnActivo === 4 ? 'activa' : ''">
					NM CC. Caminos del Inca
				</button>
				<button (click)="agenciaView(8)" class="btn-agencias" [ngClass]="btnActivo === 8 ? 'activa' : ''">
					NM Plaza San Miguel
				</button>
				<button (click)="agenciaView(9)" class="btn-agencias" [ngClass]="btnActivo === 9 ? 'activa' : ''">
					NM Jesús María
				</button>
				<button (click)="agenciaView(10)" class="btn-agencias" [ngClass]="btnActivo === 10 ? 'activa' : ''">
					NM Arequipa
				</button>
				<button (click)="agenciaView(11)" class="btn-agencias" [ngClass]="btnActivo === 11 ? 'activa' : ''">
					NM Chiclayo
				</button>
			</div>
		</div>
		<div class="direccion">
			<div class="container">
				<div class="row">
					<div class="col-12 d-flex">
						<mat-icon style="min-width: 22px">pin_drop</mat-icon>
						<div>
							<span
								>{{ direccion }}
								<strong>{{ distrito }}</strong>
							</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 d-flex">
						<mat-icon style="min-width: 22px">schedule</mat-icon>
						<div>
							<span>{{ horario }}</span>
							<span *ngIf="horario2">{{ horario2 }}</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 d-flex">
						<mat-icon style="min-width: 22px">mail_outline</mat-icon>
						<div>
							<span>
								<a href="mailto:{{ correo }}">{{ correo }}</a>
							</span>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="telefono">
					<div class="col-12 d-flex">
						<mat-icon style="min-width: 22px">phone</mat-icon>
						<div>
							<span>{{ telefono }}</span>
							<span>{{ telefono2 }}</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 d-flex position-relative">
						<img class="img-fluid agencias-img" loading="lazy" [src]="imagen" [alt]="direccion + ' ' + distrito" />
						<button
							class="btn-agencias activa position-absolute text-uppercase"
							(click)="onVerMasClick()"
							*ngIf="btnActivo === 1">
							Ver m&aacute;s
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- PARDO -->
	<iframe
		*ngIf="map === 1"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.7341024818293!2d-77.02351018578644!3d-12.130336746762024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b7fd50f9f5db%3A0x129c4b7ccf8c2dc2!2sAv.%2028%20de%20Julio%201136%2C%20Miraflores%2015047!5e0!3m2!1ses!2spe!4v1642009579784!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>
	<!-- END PARDO -->

	<!-- 28 DE JULIO -->
	<iframe
		*ngIf="map === 2"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.8965805966145!2d-77.03940318578668!3d-12.119228146550975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c83ccc55db55%3A0xee1a21d484b9cd6f!2sAv.%20Jos%C3%A9%20Pardo%20801%2C%20Lima%2015074!5e0!3m2!1ses!2spe!4v1642007934192!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>
	<!-- END 28 DE JULIO -->

	<!-- PRIMAVERA -->
	<iframe
		*ngIf="map === 3"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.030625753682!2d-76.9767242491876!3d-12.110055946337173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c799ed7225b7%3A0xdae3aa58637fb50c!2sAv.%20Primavera%201352%2C%20Santiago%20de%20Surco%2015023!5e0!3m2!1ses!2spe!4v1642002369582!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>
	<!-- END PRIMAVERA -->

	<!-- NM CC. Caminos del Inca -->
	<iframe
		*ngIf="map === 4"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d820.0786018912304!2d-76.99195635771399!3d-12.113983956959538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c7ec37a6d22d%3A0x12293e9ad3f61963!2sAv.%20Caminos%20del%20Inca%20257%2C%20Lima%2015038!5e0!3m2!1ses-419!2spe!4v1657559525637!5m2!1ses-419!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>

	<!-- NM CC. La Fontana -->
	<iframe
		*ngIf="map === 5"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.5172698460233!2d-76.96848947655046!3d-12.076699064091688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c655fc0115e3%3A0x5a3c98acd3428e16!2sAv.%20Javier%20Prado%20Este%205268%2C%20Cercado%20de%20Lima%2015023!5e0!3m2!1ses!2spe!4v1657558547148!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>

	<!-- NM CC. Mega Plaza -->
	<iframe
		*ngIf="map === 6"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d975.6333152105344!2d-77.06189117082158!3d-12.006763999468358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105cefc90e60c19%3A0x708ef4f6cd5e8d07!2sAv.%20Alfredo%20Mendiola%203698%2C%20Cercado%20de%20Lima%2015304!5e0!3m2!1ses!2spe!4v1661794291960!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>

	<!-- NM San Isidro -->
	<iframe
		*ngIf="map === 7"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.26192879064!2d-77.02864994909662!3d-12.094212646037027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c95327fb3be7%3A0xf3b0d469702f0133!2sNMViajes%20San%20Isidro!5e0!3m2!1ses-419!2spe!4v1662510042435!5m2!1ses-419!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>

	<!-- NM San Miguel -->
	<iframe
		*ngIf="map === 8"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.506232941802!2d-77.08584614916185!3d-12.077456591404935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c972729a55f3%3A0xad3605a965daafaf!2sPlaza%20San%20Miguel%2C%20Av.%20la%20Marina%202000%2C%20San%20Miguel%2015088!5e0!3m2!1ses-419!2spe!4v1676357353481!5m2!1ses-419!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>

	<!-- NM Jesus Maria -->
	<iframe
		*ngIf="map === 9"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d975.3228931630202!2d-77.0553516243427!3d-12.092180685999914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c854828409d7%3A0x2effa5f9008c7027!2sAv.%20Faustino%20S%C3%A1nchez%20Carri%C3%B3n%201029%2C%20Jes%C3%BAs%20Mar%C3%ADa%2015076!5e0!3m2!1ses!2spe!4v1718829244971!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>

	<!-- Arequipa -->
	<iframe
		*ngIf="map === 10"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3827.4999531174167!2d-71.53844781196263!3d-16.39941596320567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424a5a0293e5ed%3A0x11c6fb1453f246e4!2sPortal%20de%20San%20Agust%C3%ADn%20111%2C%20Arequipa%2004001!5e0!3m2!1ses!2spe!4v1718829054639!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>

	<!-- NM Chiclayo -->
	<iframe
		*ngIf="map === 11"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.054971195375!2d-79.86617111459698!3d-6.7631521486720825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904cef86d621ff73%3A0xf73b86b4c59e2a9f!2sMall%20Aventura%20Chiclayo!5e0!3m2!1ses!2spe!4v1718829160398!5m2!1ses!2spe"
		width="100%"
		height="792"
		style="border: 0"
		allowfullscreen=""
		loading="lazy"></iframe>
</div>

<div class="container fluid py-4">
	<!-- AYUDA -->
	<div class="row pb-3">
		<div class="col-12">
			<h2 class="title">
				<strong>¿Necesitas ayuda?</strong>
			</h2>
		</div>
	</div>
	<div class="row pb-5">
		<div class="col-12 col-md-7 text-center">
			<img class="vidthMobile" src="./assets/seguros/ayuda.svg" alt="Ayuda" loading="lazy" />
		</div>
		<div class="col-md-4 col-12 text-center mt-5">
			<p class="text-justify mt-sm-3">
				En <strong>Nmviajes</strong> queremos brindarte el mejor de los servicios. Si necesitas ayuda con tus reservas
				ponemos a tu disposición nuestro portal de ayuda. Aquí podrás encontrar información valiosa para tu próximo
				viaje y podrás ponerte en contacto con nosotros si es que tuvieras algún inconveniente.
			</p>

			<button class="my-5" (click)="portalAyuda()">Portal de ayuda</button>
		</div>
	</div>
	<!-- END AYUDA  -->
</div>
