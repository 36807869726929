<!--<button class="customers" (click)="showPasajero()">{{onlyPasajeros | getNombrePipe}}</button>-->
<div class="container__tag" #passengerHotel>
	<div class="container__tag__name" (click)="showPasajero()">
		<img src="./assets/svg/people-red2.svg" alt="" />
		<span *ngIf="!onlyPasajeros">{{totalHabitacion}} Habitaci{{totalHabitacion > 1 ? 'ones':'ón'}} - {{totalPasajeros}} Pasajero{{totalPasajeros > 1 ? 's':''}}</span>
		<span *ngIf="onlyPasajeros">{{totalPasajeros}} Pasajero{{totalPasajeros > 1 ? 's':''}}</span>
	</div>

	<div [id]="idContent" class="boxFlotante" @openClose *ngIf="showOption && idStateOpen === idContent">
		<div
			*ngFor="let habitacion of habitaciones; let first = first; let i = index"
			data-testid="adultos"
			class="boxTwoPasajero d-flex flex-row align-items-center p-3"
		>
			<div class="groupItem">
				<div class="groupItem inLine">
					<div class="boxNumber" *ngIf="!onlyPasajeros">
						<img
							src="./assets/svg/cerrar.svg"
							(click)="eliminarHabitacion(i)"
							*ngIf="!first"
							class="cerrarbtn"
							alt=""
						/>
						<div class="numberItem">{{ i + 1 }}</div>
						<img src="./assets/svg/cama.svg" alt="" />
					</div>
					<div class="groupItem">
						<div class="itemPasajero" data-testid="adultos">
							<div class="iconTwo">
								<img height="13px" src="./assets/filter/adulto.png" alt="" />
							</div>
							<div class="textSection">
								<div class="text1">Adultos</div>
								<div class="text2">18 o más años</div>
							</div>
							<div class="number">
								<div class="btnNWhite" (click)="calculateDistributionTravel(habitacion, 'adultos', 0)">-</div>
								<div class="textBtn">
									<input data-testid="input" class="inputPasajero" type="text" [value]="habitacion.nroAdultos" />
								</div>
								<div class="btnNWhite" (click)="calculateDistributionTravel(habitacion, 'adultos', 1)">+</div>
							</div>
						</div>
						<div class="itemPasajero" data-testid="ninios">
							<div class="iconTwo">
								<img height="13px" src="./assets/filter/nino.png" alt="" />
							</div>
							<div class="textSection">
								<div class="text1">Niños</div>
								<div class="text2">De 0-17 años</div>
							</div>
							<div class="number">
								<div class="btnNWhite" (click)="calculateDistributionTravel(habitacion, 'ninos', 0)">-</div>
								<div class="textBtn">
									<input data-testid="input" class="inputPasajero" type="text" [value]="habitacion.nroNinos" />
								</div>
								<div class="btnNWhite" (click)="calculateDistributionTravel(habitacion, 'ninos', 1)">+</div>
							</div>
						</div>
					</div>
				</div>

				<div class="itemPasajero espacio" *ngIf="habitacion.nroNinos > 0">
					<span>Edad(es):</span>
					<ng-container *ngFor="let item of habitacion.ninos">
						<input (input)="changeInputEdad($event, item)" [(ngModel)]="item.edad" type="number" class="inputEdad" />
					</ng-container>
				</div>
			</div>
		</div>

		<div  class="d-flex flex-row justify-content-between align-items-center p-3 btnPasajero">
			<button *ngIf="!onlyPasajeros" class="green" type="button" (click)="agregarHabitacion()">
				+ Habitación
			</button>
			<button type="button" (click)="savePasajeros()">Listo</button>
		</div>
	</div>
</div>

<!-- <div *ngFor="let habitacion of habitaciones; let first = first; let i = index" data-testid="adultos" class="boxTwoPasajero d-flex flex-row align-items-center p-3">
    <div class="groupItem ">
        <div (click)="eliminarHabitacion(i)" class="closeBtn" *ngIf="!first">
            <img src="./assets/comprar/x.png" alt="">
        </div>
        <div class="itemPasajero">
            <div class="d-flex flex-row iconTwo">
                <img height="13px" src="./assets/filter/adulto.png" alt="">
            </div>
            <div class="d-flex flex-row flex-column w-100">
                <div class="d-flex flex-row text1">Adultos</div>
                <div class="d-flex flex-row text2">18 o más años</div>
            </div>
            <div class="d-flex flex-row number">
                <div class="d-flex flex-row btnNWhite"
                    (click)="calculateDistributionTravel(habitacion,'adultos',0)">-</div>
                <div class="d-flex flex-row textBtn">
                    <input data-testid="input" class="inputPasajero" type="text" [value]="habitacion.nroAdultos">
                </div>
                <div class="d-flex flex-row btnNWhite"
                    (click)="calculateDistributionTravel(habitacion,'adultos',1)">+</div>
            </div>
        </div>
        <div class="itemPasajero">
            <div class="d-flex flex-row iconTwo">
                <img height="13px" src="./assets/filter/nino.png" alt="">
            </div>
            <div class="d-flex flex-row flex-column w-100">
                <div class="d-flex flex-row text1">Niños</div>
                <div class="d-flex flex-row text2">De 0 - 17 años</div>
            </div>
            <div class="d-flex flex-row number">
                <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel(habitacion,'ninos',0)">-
                </div>
                <div class="d-flex flex-row textBtn">
                    <input data-testid="input" class="inputPasajero" type="text" [value]="habitacion.nroNinos">
                </div>
                <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel(habitacion,'ninos',1)">+
                </div>
            </div>
        </div>
        <div class="itemPasajero espacio" *ngIf="habitacion.nroNinos > 0">
            <span>Edad(es):</span>
            <ng-container *ngFor="let item of habitacion.ninos">
                <input (input)="changeInputEdad($event, item)" [(ngModel)]="item.edad" type="number"
                    class="inputEdad">
            </ng-container>
        </div>
    </div>

</div> -->
