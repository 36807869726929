<div class="container">
  <div class="row">
    <div class="col-12">
      <h6 class="mt-5 mb-3">CONDICIONES DE RESERVA</h6>
      <div>
        <a class="d-md-none d-block" href="../../../../assets/pdf/Condiciones_de_Reserva_nuevomundoviajes.pdf">
          descargar condiciones
        </a>
      </div>
      <iframe class="d-none d-md-block" src="../../../../assets/pdf/Condiciones_de_Reserva_nuevomundoviajes.pdf"
        width="100%" height="900" frameborder="0"></iframe>
    </div>
    <div class="clearfix"></div>
  </div>
</div>