import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CryptoService } from 'src/app/Services/util/crypto.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-documentacion-viaje',
  templateUrl: './documentacion-viaje.component.html',
  styleUrls: ['./documentacion-viaje.component.scss']
})
export class DocumentacionViajeComponent implements OnInit {
  isBrowser: Boolean = false;

  constructor(private _cryptoService: CryptoService, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
    if (this.isBrowser) {
      let userID: string = '';
      let user_existingCustomer: boolean = false;
      const credentials = localStorage.getItem('usuario');
      const bookings = localStorage.getItem('bookings');

      if (credentials) {
        const credentialsJson = JSON.parse(credentials);
        userID = this._cryptoService.encrypt(credentialsJson.email);

        if (bookings)
          user_existingCustomer = JSON.parse(bookings).length > 0;
      }

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "user_info",
        userID: userID,
        user_existingCustomer: user_existingCustomer
      });

      (window as any).dataLayer.push({
        event: "virtualPageView",
        virtualPagePath: "/documentacion-viaje",
        virtualPageTitle: "NMV: Documentacion de viaje"
      });
    }
  }

  onPrint() {
    if (this.isBrowser) window.print();
  }
}
