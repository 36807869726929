import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Offer } from 'src/app/Models/offers/offers.model';
import { OffersService } from 'src/app/Services/offers/offers.service';

@Component({
	selector: 'app-section-super-offers',
	templateUrl: './section-super-offers.component.html',
	styleUrls: ['./section-super-offers.component.scss']
})
export class SectionSuperOffersComponent implements OnInit {
	indexTab = 0;

	internationalFlights: Offer[] = [];
	nationalFlights: Offer[] = [];

	internationalFlightsPag: Offer[] = [];
	nationalFlightsPag: Offer[] = [];

	offersSubscription = new Subscription();

	currentPagNac = 0;
	currentPagInc = 0;

	showPackages = true;
	showOffersNac = true;
	showOffersInt = true;
	hiddenSection = false;
	isBrowser: Boolean = false;

	constructor(
		private offersService: OffersService,
		private _router: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.isBrowser = true;
		}
		this.offersNacInt();
	}

	offersNacInt() {
		this.offersService.getOffersNationalInternational().subscribe({
			next: (res) => {
				this.nationalFlights = res.Result.filter((item) => item.Type == 'NAC').slice(0, 8);
				this.nationalFlightsPag = this.nationalFlights.slice(0, 4);
				this.internationalFlights = res.Result.filter((item) => item.Type == 'INT').slice(0, 8);
				this.internationalFlightsPag = this.internationalFlights.slice(0, 4);
				if (this.nationalFlights.length == 0) this.showOffersNac = false;
				if (this.internationalFlights.length == 0) this.showOffersInt = false;
				this.updateDisplayedDataNac();
				this.updateDisplayedDataInc();
			},
			error: (err) => {
				this.showOffersNac = false;
				this.showOffersInt = false;
				console.error(err);
			}
		});
	}

	showMore() {
		if (this.indexTab == 0) this.updateDisplayedDataNac();
		if (this.indexTab == 1) this.updateDisplayedDataInc();
	}

	updateDisplayedDataNac() {
		const currentPagNac = this.currentPagNac + 4;
		if (currentPagNac > this.nationalFlights.length) {
			this.nationalFlightsPag = this.nationalFlights.slice(0, 4);
			this.currentPagNac = 4;
			this.scrollReset();
		} else {
			this.nationalFlightsPag = this.nationalFlights.slice(0, currentPagNac);
			this.currentPagNac = currentPagNac;
		}
	}

	updateDisplayedDataInc() {
		const currentPagInc = this.currentPagInc + 4;
		if (currentPagInc > this.internationalFlights.length) {
			this.internationalFlightsPag = this.internationalFlights.slice(0, 4);
			this.currentPagInc = 4;
			this.scrollReset();
		} else {
			this.internationalFlightsPag = this.internationalFlights.slice(0, currentPagInc);
			this.currentPagInc = currentPagInc;
		}
	}

	viewRates(destinationCode: string): void {
		if (this.isBrowser) {
			window.scroll({ top: 0, behavior: 'smooth' });
			this._router.navigateByUrl(`/vuelos/destino/LIM/${destinationCode}`);
		}
	}

	ngOnDestroy() {
		this.offersSubscription.unsubscribe();
	}

	scrollReset() {
		if (this.isBrowser)
			document.querySelector('#sectionOffers')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}
}
