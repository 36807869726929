<div class="container">
        <div class="row">
            <div class="col-12">
                <h6 class="mt-5 mb-3">POLÍTICA DE RESPONSABILIDAD SOCIAL CORPORATIVA (RSC) Y TRATAMIENTO DE DATOS PERSONALES </h6>
                <div>
                    <a class="d-md-none d-block" href="../../../../assets/pdf/Política__responsabilidad_Social_Corporativa_NMVIAJES.pdf">
                        descargar Responsabilidad social
                    </a>
                </div>
                <iframe src="../../../../assets/pdf/Política__responsabilidad_Social_Corporativa_NMVIAJES.pdf" width="100%" height="900" frameborder="0"></iframe>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>