<section class="bg-white py-3 py-md-4 py-lg-5" id="packagesSection" *ngIf="displayPackageList.length > 0">
	<div class="container">
		<div class="d-flex flex-column row-gap-3 row-gap-lg-4" id="sectionPackage">
			<h3 class="fs-2 fw-bolder text-start mb-0">Paquetes a tu medida</h3>
			<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-md-4 g-lg-5 px-2 px-lg-0">
				<app-package-card [item]="item" *ngFor="let item of displayPackageList"></app-package-card>
			</div>
			<app-button class="mx-auto mt-3 mt-lg-4"
			            [text]="packageList.length > displayPackageList.length ? 'Ver más paquetes' : 'Ver menos'"
			            [color]="'outline-primary'"
			            [isMobile]="true"
			            (onClick)="showMoreLessPackages()"></app-button>
		</div>
	</div>
</section>