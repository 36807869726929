<div class="new__tabs px-4 px-md-5 py-2 py-md-3 container-menu">
	<div class="new__tabs__section justify-content-center gap-5" style="width: fit-content" #slider>
		<ng-container *ngFor="let tab of dataTabs; let i = index">
			<a
				class="new__tabs__section__tab d-flex flex-row"
				[class.active]="indexActive === i"
				[href]="tab.path"
				(click)="clickTab(tab, i, $event)"
			>
				<img [src]="indexActive === i ? tab.active : tab.icon" alt="" loading="lazy" width="33" height="32" />
				<span [class.active]="indexActive === i">{{ tab.name }}</span>
			</a>
		</ng-container>
	</div>
</div>
