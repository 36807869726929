<section class="border-top">
	<div class="container__footer">
		<article>
			<div class="container__footer__col1 flex-wrap">
				<a href="https://www.nmviajes.com/LocalResource/mincetur/Constancia-nmv.pdf" target="_blank">
					<img src="assets/footer/agencia.webp" alt="Agencia de turismo" loading="lazy">
				</a>
				<a href="https://www.mincetur.gob.pe/esnna/" target="_blank">
					<img src="assets/footer/protegeme.webp" alt="Protégeme turismo responsable" loading="lazy">
				</a>
				<a routerLink="/libro-reclamaciones">
					<img src="assets/img/home/libro-reclamaciones.webp"
					     alt="Libro de reclamaciones"
					     loading="lazy"
					     class="h-auto"
					     style="width: 120px">
				</a>
			</div>
			<div class="container__footer__col2">
				<img src="./assets/footer/visa.png" alt="visa">
				<img src="./assets/footer/mastercard.png" alt="mastercard">
				<img src="./assets/footer/american.png" alt="american">
				<img src="./assets/footer/diners.png" alt="dinners">
				<img src="./assets/footer/safety.png" alt="safety">
			</div>
		</article>
	</div>
</section>
