<div style="min-width: 310px" *ngIf="carouselEventsImages.length">
	<app-carousel-home
		[carouselItems]="carouselEventsImages"
		[carouselConfig]="carouselEventsConfig"
		[visibleButtons]="false"
		heightImages="310px"
		borderRadius="20px"
		[visiblePoints]="true">
	</app-carousel-home>
</div>
<app-section-super-offers></app-section-super-offers>
<app-section-packages></app-section-packages>
<app-section-why-choose-us [dataSection]="dataPagePresenterService.data.sections[4].card"></app-section-why-choose-us>
<!--<app-section-blog></app-section-blog>-->
<app-section-boletin></app-section-boletin>