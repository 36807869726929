<div class="modal-content ">
	<div class="recoveryLeft ">
		<div class="titleRecovery ">¡Únete a NMViajes y recibe nuestras ofertas exclusivas!</div>
		<img src="./assets/modal/logo-white.png " alt="NM Viajes">
	</div>
	<div class="recoveryRight">
		<div class="modal-header">
			<button type="button" id="btncloseRecovery" class="btn-close" (click)="activeModal.dismiss()"></button>
		</div>
		<div class="titleRecovery">Recuperar contraseña</div>
		<form name="recoverPasswordForm" [formGroup]="recoverPasswordForm" autocomplete="off">
			<div class="elementRow">
				<div class="divValida" [ngClass]="{error : validateRecoverPasswordForm('email')}">
					<input type="text" #password class="form-control" formControlName="email" placeholder="Email">
					<div class="textError" *ngIf="validateRecoverPasswordForm('email')">
						{{ recoverPasswordEmailErrorMessage }}
					</div>
				</div>
			</div>
		</form>
		<div class="d-flex justify-content-center">
			<button (click)="getPassword(password.value)" class="w-75">Recuperar contraseña</button>
		</div>
		<hr>
		<div class="account">
			<div class="notRegister">¿Ya tienes cuenta?</div>
			<a class="newAccount" (click)="openLoginModal()">Inicia sesión</a>
		</div>
	</div>
</div>
