<div class="container">
	<div class="row">
		<div class="col-12">
			<h6 class="mt-5 mb-3">POLÍTICA DE PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES </h6>
			<div>
				<a class="d-md-none d-block"
				   href="./assets/pdf/Política_Protección_Tratamiento_de_Datos_Personales_NMVIAJES.pdf"
				   target="_blank">
					descargar politicas
				</a>
			</div>
			<iframe class="d-none d-md-block"
			        src="./assets/pdf/Política_Protección_Tratamiento_de_Datos_Personales_NMVIAJES.pdf"
			        width="100%"
			        height="900"
			        frameborder="0"></iframe>
		</div>
		<div class="clearfix"></div>
	</div>
</div>