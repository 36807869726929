<section class="container-fluid fluid ofertas px-0">
    <div class="row mx-0">
        <div class="col-12">
            <h2 class="titleHome padTitle ms-3">
                <strong>Ofertas por Líneas Aéreas</strong>
            </h2>
        </div>
    </div>

    <div class="row mx-0">
        <div class="col-12 px-0">
            <div class="desktop-visible">
                <div class="sliderOfertas">
                    <div class="btnSteps" (click)="afterBtn()">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.29248 1.80184L3.22217 6.00001L7.29248 10.1982L6.03939 11.4878L0.707115 6.00001L6.0394 0.512207L7.29248 1.80184Z"
                                fill="#DF0C30" />
                        </svg>
                    </div>
                    <ng-container *ngFor="let contador of maxCounterArray">
                        <div *ngIf="aereolineas" class="contentSliderImg" [ngClass]="{active: counter===contador}">
                            <a *ngFor="let item of aereolineas | porslide:contador:imagenesPorSilder" (click)="toLine(item)">
                                <img loading="lazy" [src]="item.Logo" appImgDefault [alt]="item.Name">
                            </a>
                        </div>
                    </ng-container>
                    <div class="btnSteps" (click)="nextBtn()">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.70752 10.1982L4.77783 5.99999L0.707521 1.80182L1.96061 0.512183L7.29288 5.99999L1.9606 11.4878L0.70752 10.1982Z"
                                fill="#DF0C30" />
                        </svg>
                    </div>
                </div>

            </div>

            <div class="mobile-visible">
                <div class="sliderOfertasCel">
                    <ng-container *ngFor="let contador of maxCounterArrayMobile">
                        <div *ngIf="aereolineas" class="contentSliderImg" [ngClass]="{active: counter===contador}">
                            <a *ngFor="let item of aereolineas | porslide:contador:imagenesPorSilderMobile" (click)="toLine(item)">
                                <img loading="lazy" [src]="item.Logo" appImgDefault [alt]="item.Name">
                            </a>
                        </div>
                    </ng-container>
                </div>

                <div class="pages-cont">
                    <ng-container *ngFor="let b of maxCounterArrayMobile; let indice = index">
                        <button type="button" (click)="counter=b" [ngClass]="{active: counter===b}"></button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>