import { NgModule } from '@angular/core';
import { ImageResponsiveLoadDirective } from './image-responsive-load.directive';
import { DefaultImageDirective } from './imagen.directive';

@NgModule({
	imports: [],
	exports: [DefaultImageDirective, ImageResponsiveLoadDirective],
	declarations: [DefaultImageDirective, ImageResponsiveLoadDirective],
	providers: []
})
export class DirectivesModule {}
