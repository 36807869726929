<div class="boxCobertura">
    <div class="headerCober">
        <div class="leftHeader">

        </div>
        <div class="rightHeader">
            FECHA FLEXIBLE
        </div>
    </div>
    <div class="BodyCober mt-4">
        <div class="titleBodyCober">
            {{txtPlan}}
        </div>
        <p>Cobertura por: {{ txtDias }} día(s)</p>
        <!-- <p>Asistencia médica: USD 60.000</p> -->
        <div class="destinoCober marginSup">
            <div class="columnDest">
                <div class="iconCol">
                    <img src="./assets/seguros/avion.png" alt="">
                </div>
            </div>
            <div class="columnDest boldBox">
                <div>Origen:</div>
                <div>Destino:</div>
            </div>
            <div class="columnDest">
                <div>(PE) Perú</div>
                <div>{{txtDestino}}</div>
            </div>
        </div>
        <div class="destinoCober">
            <div class="columnDest">
                <div class="iconCol">
                    <img src="./assets/seguros/calendario.png" alt="">
                </div>
            </div>
            <div class="columnDest boldBox">
                <div>Salida:</div>
                <div>Regreso:</div>
            </div>
            <div class="columnDest">
                <div>{{txtSalida}}</div>
                <div>{{txtRegreso}}</div>
            </div>
        </div>
        <div class="destinoCober">
            <div class="columnDest">
                <div class="iconCol">
                    <img src="./assets/seguros/persona.png" alt="">
                </div>
            </div>
            <div class="columnDest boldBox">
                <div>Pasajero(s):</div>
            </div>
            <div class="columnDest">
                <div>{{txtPasajeros}}</div>
            </div>
        </div>
    </div>
    <div class="footerCober">
        <div class="footerLeftCober">
            <img src="./assets/seguros/covid.png" alt="">
        </div>
        <div class="footerCenterCober">
            <div>COBERTURA</div>
            <div>COVID</div>
        </div>
        <div class="footerRightCober">
            <div><a data-bs-toggle="modal" data-bs-target="#ModalCoverageI" (click)="data(plan)">Ver detalle de los beneficios</a> </div>
        </div>
    </div>
</div>