<a class="card border-0 shadow-none text-bg-dark h-100 text-decoration-none" target="_blank" [href]="item.ideaUrl">
	<img loading="lazy" class="card-img img-fluid h-100 flex-grow-1" [src]="item.ImageUrl" [alt]="item.LargeTitle">
	<div class="card-img-overlay d-flex flex-column z-2">
		<h4 class="card-title inter-bold h2 mb-1">{{ item.Title }}</h4>
		<p class="card-subtitle fs-6 fw-bold mb-2">
			{{ item.Counters.HotelNights + 1 }} D&Iacute;AS | {{ item.Counters.HotelNights }} NOCHES
		</p>
		<p class="small card-text text-uppercase fw-medium flex-grow-1">DESDE {{ item.Origin.Location.Name }}</p>
		<p class="small card-text text-end fw-bold mb-1">PRECIO DESDE:</p>
		<p class="card-text card-price text-end fw-bolder display-6 mb-1">{{ item.TotalPrice.Amount | currency:'USD':'US$ ':'1.0-0' }}</p>
		<small class="fw-bold text-end text-uppercase mb-3 mb-lg-4">
			PARA VIAJAR EL {{ item.departureDate | date:'dd/MM/yyyy' }}
		</small>
		<button class="btn btn-lg btn-outline-light fw-bolder">¡Lo quiero!</button>
	</div>
</a>
