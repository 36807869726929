<div class="rowDestino center" *ngIf="tickets?.length && city">
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_117:1334)">
      <path
        d="M41.6988 0H8.30078C5.17769 0 2.63672 2.54097 2.63672 5.66406V41.7965C2.63672 43.412 3.95088 44.7262 5.56641 44.7262H18.3918C18.9312 44.7262 19.3684 44.289 19.3684 43.7496C19.3684 43.2102 18.9312 42.7731 18.3918 42.7731H5.56641C5.02777 42.7731 4.58984 42.3351 4.58984 41.7965V5.66406C4.58984 3.61786 6.25458 1.95312 8.30078 1.95312H37.4252C36.5604 2.948 36.0348 4.24538 36.0348 5.66406V37.1353C36.0348 38.0363 35.302 38.7691 34.4009 38.7691C33.6967 38.7691 33.0738 38.3202 32.8506 37.6522L31.3789 33.2363C30.9635 31.9897 29.8012 31.1523 28.4874 31.1523C26.8066 31.1523 25.4395 32.5195 25.4395 34.1999V34.9606H20.3777C19.8383 34.9606 19.4012 35.3981 19.4012 35.9371C19.4012 36.4765 19.8383 36.9137 20.3777 36.9137H25.4395V41.7721C25.4395 43.214 25.7206 44.6201 26.2753 45.9507L27.7119 49.3988C27.8683 49.7738 28.231 50 28.6137 50C28.7388 50 28.8662 49.976 28.9886 49.9249C29.4868 49.7173 29.7222 49.1455 29.5147 48.6477L28.0781 45.1996C27.6234 44.1082 27.3926 42.955 27.3926 41.7725V34.2003C27.3926 33.5964 27.8835 33.1055 28.4874 33.1055C28.9593 33.1055 29.377 33.4061 29.5261 33.8539L30.9978 38.2698C31.4869 39.7366 32.8545 40.7223 34.4009 40.7223C36.3789 40.7223 37.9879 39.1132 37.9879 37.1353V20.3922L39.2929 21.6946C41.6214 24.0185 42.9035 27.1103 42.9035 30.3997V40.7806C42.9035 43.4696 42.2943 46.175 41.1423 48.6046C40.9111 49.0917 41.119 49.6742 41.6061 49.9054C42.0933 50.1366 42.6758 49.929 42.907 49.4415C44.1822 46.7522 44.8566 43.7572 44.8566 40.7806V30.3997C44.8566 26.5877 43.3708 23.0053 40.6727 20.3121L37.9879 17.6323V16.6992H44.4332C46.0487 16.6992 47.3629 15.3851 47.3629 13.7695V5.66406C47.3629 2.54097 44.8223 0 41.6988 0V0ZM45.4098 13.7695C45.4098 14.3078 44.9718 14.7461 44.4332 14.7461H37.9879V5.66406C37.9879 3.61786 39.6526 1.95312 41.6988 1.95312C43.745 1.95312 45.4098 3.61786 45.4098 5.66406V13.7695Z"
        fill="#4B4B4B" />
      <path
        d="M22.6562 42.7731C22.3991 42.7731 22.1474 42.8776 21.9658 43.0592C21.7842 43.2411 21.6797 43.4929 21.6797 43.7496C21.6797 44.0067 21.7842 44.2585 21.9658 44.4401C22.1474 44.6217 22.3991 44.7262 22.6562 44.7262C22.913 44.7262 23.1647 44.6217 23.3467 44.4401C23.5283 44.2585 23.6328 44.0067 23.6328 43.7496C23.6328 43.4929 23.5283 43.2411 23.3467 43.0592C23.1647 42.8776 22.913 42.7731 22.6562 42.7731Z"
        fill="#4B4B4B" />
      <path
        d="M16.0808 7.22656C16.0808 6.14967 15.2046 5.27344 14.1277 5.27344H9.83081C8.75354 5.27344 7.87769 6.14967 7.87769 7.22656V11.5234C7.87769 12.6003 8.75354 13.4766 9.83081 13.4766H14.1277C15.2046 13.4766 16.0808 12.6003 16.0808 11.5234V7.22656ZM14.1277 11.5234H9.83081V7.22656H14.1277L14.1288 11.5234C14.1288 11.5234 14.1284 11.5234 14.1277 11.5234Z"
        fill="#4B4B4B" />
      <path
        d="M16.0808 18.9453C16.0808 17.8684 15.2046 16.9922 14.1277 16.9922H9.83081C8.75354 16.9922 7.87769 17.8684 7.87769 18.9453V23.2422C7.87769 24.3191 8.75354 25.1953 9.83081 25.1953H14.1277C15.2046 25.1953 16.0808 24.3191 16.0808 23.2422V18.9453ZM14.1277 23.2422H9.83081V18.9453H14.1277L14.1288 23.2422C14.1288 23.2422 14.1284 23.2422 14.1277 23.2422Z"
        fill="#4B4B4B" />
      <path
        d="M14.1277 28.7109H9.83081C8.75354 28.7109 7.87769 29.5868 7.87769 30.6641V34.9606C7.87769 36.0378 8.75354 36.9137 9.83081 36.9137H14.1277C15.2046 36.9137 16.0808 36.0378 16.0808 34.9606V30.6641C16.0808 29.5868 15.2046 28.7109 14.1277 28.7109ZM14.1277 34.9606H9.83081V30.6641H14.1277L14.1288 34.9606C14.1288 34.9606 14.1284 34.9606 14.1277 34.9606Z"
        fill="#4B4B4B" />
      <path
        d="M31.5754 13.4766C32.1148 13.4766 32.552 13.0394 32.552 12.5C32.552 11.9606 32.1148 11.5234 31.5754 11.5234H24.8433C24.3039 11.5234 23.8667 11.9606 23.8667 12.5C23.8667 13.0394 24.3039 13.4766 24.8433 13.4766H31.5754Z"
        fill="#4B4B4B" />
      <path
        d="M20.3777 13.4766C20.6348 13.4766 20.8866 13.372 21.0681 13.1905C21.2497 13.0089 21.3542 12.7567 21.3542 12.5C21.3542 12.2433 21.2497 11.9911 21.0681 11.8095C20.8866 11.628 20.6348 11.5234 20.3777 11.5234C20.1198 11.5234 19.8688 11.628 19.6872 11.8095C19.5049 11.9911 19.4011 12.2433 19.4011 12.5C19.4011 12.7567 19.5049 13.0089 19.6872 13.1905C19.8688 13.372 20.1198 13.4766 20.3777 13.4766Z"
        fill="#4B4B4B" />
      <path
        d="M20.3773 25.1953H31.5754C32.1148 25.1953 32.5519 24.7581 32.5519 24.2188C32.5519 23.6794 32.1148 23.2422 31.5754 23.2422H20.3773C19.8383 23.2422 19.4008 23.6794 19.4008 24.2188C19.4008 24.7581 19.8383 25.1953 20.3773 25.1953Z"
        fill="#4B4B4B" />
    </g>
    <defs>
      <clipPath id="clip0_117:1334">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>

<div class="rowDestino titulo" *ngIf="tickets?.length && city">Actividades</div>
<div class="rowDestino subtitulo mt-4" *ngIf="tickets?.length && city">Reserva experiencias inolvidables en todo el
  mundo.
</div>

<div class="boxPackage" *ngIf="tickets?.length && city">
  <div class="package">
    <div class="icono">
      <img src="./assets/vuelos/calendar.png" alt="">
      <div class="eclipse">
        <img src="./assets/vuelos/eclipse.png" alt="">
      </div>
    </div>
    <div class="texto">Cancelación gratuita</div>
  </div>
  <div class="package">
    <div class="icono">
      <img src="./assets/vuelos/cleaning.png" alt="">
      <div class="eclipse">
        <img src="./assets/vuelos/eclipse.png" alt="">
      </div>
    </div>
    <div class="texto">Manténgase a salvo durante COVID-19</div>
  </div>
  <div class="package">
    <div class="icono">
      <img src="./assets/vuelos/money.png" alt="">
      <div class="eclipse">
        <img src="./assets/vuelos/eclipse.png" alt="">
      </div>
    </div>
    <div class="texto">Mejor precio garantizado</div>
  </div>
  <!-- Solo para versión móvil -->
	<div class="packageMov d-lg-none">
		<div class="contentBox">
			<div class="icono">
				<img src="./assets/vuelos/calendar.png" alt="">
				<div class="eclipse">
					<img src="./assets/vuelos/eclipse.png" alt="">
				</div>
			</div>
			<div class="texto">Cancelación gratuita</div>
		</div>
		<div class="contentBox">
			<div class="icono">
				<img src="./assets/vuelos/cleaning.png" alt="">
				<div class="eclipse">
					<img src="./assets/vuelos/eclipse.png" alt="">
				</div>
			</div>
			<div class="texto">Manténgase a salvo durante COVID-19</div>
		</div>
		<div class="contentBox">
			<div class="icono">
				<img src="./assets/vuelos/money.png" alt="">
				<div class="eclipse">
					<img src="./assets/vuelos/eclipse.png" alt="">
				</div>
			</div>
			<div class="texto">Mejor precio garantizado</div>
		</div>
	</div>
</div>

<div class="cardDestino" [ngClass]="{show : tickets?.length}">

  <div class="loadingBoxCard" [ngClass]="{show : !tickets}">
    <div class="iconLoading">
      <img src="./assets/loader.gif" alt="">
    </div>
    <div class="texto">
      Cargando actividades...
    </div>
  </div>

	<ngu-carousel #carousel [inputs]="tileConfig" [dataSource]="tickets" *ngIf="tickets">
		<ngu-tile class="px-3 px-lg-4" *nguCarouselDef="let item">
			<ngu-carousel #tile [inputs]="carouselConfig" [dataSource]="item.ImageUrls">
				<ngu-tile NguCarouselItem class="slide" *nguCarouselDef="let imageUrl">
					<img class="img-fluid" loading="lazy" [src]="imageUrl" [alt]="item.Name">
				</ngu-tile>
			</ngu-carousel>
			<p class="fs-6 fw-bold fontOpen px-2">{{ item.Name }}</p>
			<p class="small d-flex align-items-center px-2" *ngIf="item.Duration">
				<mat-icon>restore</mat-icon>
				Duración: {{ item.Duration }}
			</p>
			<p class="small px-2 mb-1">{{ item.ActivityType }}</p>
			<ul class="dots mt-md-auto" NguCarouselPoint>
				<li [class.active]="i === tile.activePoint"
				    (click)="tile.moveTo(i)"
				    *ngFor="let i of tile.pointNumbers; let j = index"></li>
			</ul>
		</ngu-tile>
		<button NguCarouselPrev
		        class="p-carousel-prev position-absolute start-0"
		        [style.opacity]="carousel.isFirst ? .5 : 1"></button>
		<button NguCarouselNext
		        class="p-carousel-next position-absolute end-0"
		        [style.opacity]="carousel.isLast ? .5 : 1"></button>
	</ngu-carousel>
</div>