import { Component } from '@angular/core';

@Component({
	selector: 'app-pre-footer',
	templateUrl: './pre-footer.component.html',
	styleUrls: [ './pre-footer.component.scss' ]
})
export class PreFooterComponent {

	constructor() {
	}

}
