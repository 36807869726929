import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-newcard-offer',
	templateUrl: './newcard-offer.component.html',
	styleUrls: ['./newcard-offer.component.scss']
})
export class NewcardOfferComponent implements OnInit {
	constructor(
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}
	@Input() title = '';
	@Input() price = 0;
	@Input() description = '';
	@Input() urlImage = '';
	@Input() numNight = 0;
	@Input() numStart = 0;
	@Input() destinationCode = '';
	isBrowser: Boolean = false;

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.isBrowser = true;
		}
	}

	viewRates(destinationCode: string): void {
		if (this.isBrowser) {
			window.scroll({ top: 0, behavior: 'smooth' });
			this.router.navigateByUrl(`/vuelos/destino/LIM/${destinationCode}`);
		}
	}
}
