<div class="modal-header">
	<div class="iconCenter">
		<img src="./assets/modal/usuario-verificado.svg" alt="" />
	</div>
	<div class="titleVerifica">¡Perfecto!</div>
	<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
</div>
<div class="modal-body">
	<div class="descripText">Su usuario fue activado con éxito.</div>
	<div class="action">
		<button (click)="acceptConfirmation()" type="button" class="">Aceptar</button>
	</div>
</div>