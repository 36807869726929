<div class="modal-header">
	<div class="text-end">
		<button type="button" class="btn-close" tabindex="-1" (click)="activeModal.close()"></button>
	</div>
	<img src="./assets/modal/logo-modal.webp" alt="">
	<h1 class="modal-title" id="ModalSesionLabel">Iniciar sesión</h1>
	<p>Ingresa con tus datos de siempre</p>
</div>
<div class="modal-body">
	<mat-tab-group mat-align-tabs="center" (selectedTabChange)="showSocialMediaLogin($event)">
		<!-- region PERSONAL -->
		<mat-tab label="Personal">
			<form #formUser="ngForm" (ngSubmit)="formUser.form.valid" action="">
				<div class="elementRow" [ngClass]="{'error': loginPEmail.invalid && submitPerson}">
					<input #loginPEmail="ngModel" type="text" email class="form-control" name="email"
					       [(ngModel)]="login.email" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" placeholder="Email" required>
					<div *ngIf="loginPEmail.invalid" class="msgError">Ingresa tu email</div>
				</div>
				<div class="elementRow" [ngClass]="{'error': loginPPassword.invalid && submitPerson}">
					<input #loginPPassword="ngModel" type="password" #passwordP class="form-control" name="password"
					       [(ngModel)]="login.password" placeholder="Contraseña" required>
					<div *ngIf="loginPPassword.invalid" class="msgError">Ingresa tu contraseña</div>
					<div (click)="togglePassword(passwordP)" class="iconPass">
						<img src="./assets/modal/eye.png" alt="">
					</div>
				</div>
				<div class="row mx-0">
					<div class="col-6">
						<mat-checkbox [(ngModel)]="login.recorder" name="recorder" class="example-margin">
							Recordar datos
						</mat-checkbox>
					</div>
					<div class="col-6 text-end">
						<a href="#" class="subRayado" (click)="openForgotPassword()">¿Olvidaste la contraseña?</a>
					</div>
					<div class="clearfix"></div>
				</div>
			</form>
		</mat-tab>
		<!-- endregion -->
		<!-- region EMPRESA -->
		<mat-tab label="Empresa">
			<form #formBusiness="ngForm" (ngSubmit)="formBusiness.form.valid" action="">
				<div class="elementRow" [ngClass]="{'error': loginBEmail.invalid && submitBusiness}">
					<input #loginBEmail="ngModel" type="text" class="form-control" name="email" [(ngModel)]="loginB.email"
					       pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" placeholder="Email" required>
					<div *ngIf="loginBEmail.invalid" class="msgError">Ingresa tu email</div>
				</div>
				<div class="elementRow" [ngClass]="{'error': loginBPassword.invalid && submitBusiness}">
					<input #loginBPassword="ngModel" type="password" class="form-control" #passwordB name="password"
					       [(ngModel)]="loginB.password" placeholder="Contraseña" required>
					<div *ngIf="loginBPassword.invalid" class="msgError">Ingresa tu contraseña</div>
					<div (click)="togglePassword(passwordB)" class="iconPass">
						<img src="./assets/modal/eye.png" alt="">
					</div>
				</div>
				<div class="row mx-0">
					<div class="col-6">
						<mat-checkbox [(ngModel)]="loginB.recorder" name="recorder" class="example-margin">
							Recordar datos
						</mat-checkbox>
					</div>
					<div class="col-6 text-end">
						<a href="#" (click)="openForgotPassword()">¿Olvidaste la contraseña?</a>
					</div>
					<div class="clearfix"></div>
				</div>
			</form>
		</mat-tab>
		<!-- endregion -->
	</mat-tab-group>
</div>
<div class="modal-footer">
	<button (click)="signIn(formUser, formBusiness)" class="w-100">Iniciar sesión</button>
	<div class="account">
		<div class="notRegister">¿No estás registrado?</div>
		<a class="newAccount" (click)="openNewAccount()">Crea una cuenta</a>
	</div>
	<div class="w-100 d-flex align-items-center justify-content-center" *ngIf="!isFromIOS">
		<div class="line bg-secondary bg-opacity-25"></div>
		<p class="text-center my-0 mx-2">&oacute;</p>
		<div class="line bg-secondary bg-opacity-25"></div>
	</div>
	<div class="googleSignIn"></div>
</div>
