<button
	*ngIf="right && icon"
	[class]="color + ' btn'"
	[disabled]="disabled"
	[class.btn__xs]="size=='xs'"
	[class.isLoader]="isLoader"
	(click)="clickButton()"
	[class.w-100]="w100"
	[class.isMobile]="isMobile"
	[class.isLink]="isLink"
	[class.fullwidth]="fullWidth"
	[class.isMobile2]="isMobile2"
	[attr.tabIndex]="isTabIndex ? 0: -1"
	(keyup)="onKeyUp($event)"
>
	{{ isLoader ? '' : text }}
	<img *ngIf="!isSucess && !isLoader" [src]="'./assets/svg/' + icon" />
	<img *ngIf="isSucess && disabled && !isLoader" [src]="'./assets/svg/'+ icon" />
	<div class="spinner" *ngIf="disabled && isLoader"></div>
</button>

<button

	*ngIf="left && icon && icon!==''"
	[class]="color + ' btn'"
	[disabled]="disabled"
	(click)="clickButton()"
	[class.w-100]="w100"
	[class.btn__xs]="size=='xs'"
	[class.isMobile]="isMobile"
	[attr.tabIndex]="isTabIndex ? 0: -1"
	[class.isLink]="isLink"
	[class.isMobile2]="isMobile2"
	[class.fullwidth]="fullWidth"
>
	<img alt="img_btn" [src]="'./assets/svg/' + icon" />
	{{ text }}
</button>

<button
	*ngIf="!right && !left && !icon"
	[class]="'btn ' + color"
	[disabled]="disabled"
	(click)="clickButton()"
	[class.w-100]="w100"
	[class.btn__xs]="size=='xs'"
	[class.isMobile]="isMobile"
	[class.isLink]="isLink"
	[attr.tabIndex]="isTabIndex ? 0: -1"
	[class.isMobile2]="isMobile2"
	[class.fullwidth]="fullWidth"
>
	{{ text }}
</button>
