import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PLATFORM_STORAGE_KEYS } from '../constants';
import { StorageUtils } from '../../shared/utils/storage-utils';
import { isPlatformBrowser } from '@angular/common';

const MAX_ITEM_AGE = (60 * 60 * 1000);

@Injectable({
	providedIn: 'root'
})
export class PlatformService {
	private _isBrowser = false;

	private _mobile: number | null;
	private _device: number | null;

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
	}

	setMobile(value: number, maxAge = MAX_ITEM_AGE) {
		if (this._isBrowser) {
			StorageUtils.setItemWithExpiration(PLATFORM_STORAGE_KEYS.MOBILE, value, maxAge, true);
			this._mobile = value;
		}
	}

	setDevice(value: number, maxAge = MAX_ITEM_AGE) {
		if (this._isBrowser) {
			StorageUtils.setItemWithExpiration(PLATFORM_STORAGE_KEYS.DEVICE, value, maxAge, true);
			this._device = value;
		}
	}

	getDevice(): number {
		const item = StorageUtils.getItem(PLATFORM_STORAGE_KEYS.DEVICE, true);
		return item ? item.data : this._device;
	}
}
