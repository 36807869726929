<div class="container__range" #newcalendar>
	<div class="container__range__date multi" [tabIndex]="0" (click)="openCalendar()">
		<img src="./assets/svg/calendar.svg" alt="" />
		<span [class.inactive]="dateDeparture == ''">{{ dateDeparture == '' ? placeholder : dateDeparture }}</span>
	</div>
	<section [hidden]="!showCalendar">
		<div class="container__range__arrow"></div>
		<div class="container__range__datepicker">
			<div class="container__range__datepicker__calendar">
				<div class="container__range__datepicker__calendar__actions">
					<div class="ngb-dp-arrow">
						<button type="button" class="btn btn-link ngb-dp-arrow-btn" (click)="changeMonth(-1)" [disabled]="disabledPrevMonth">
							<span class="ngb-dp-navigation-chevron"></span>
						</button>
					</div>
					<span>{{ nameCurrentMonth }} {{ dateOneWay.year }}</span>
					<div class="ngb-dp-arrow right">
						<button type="button" class="btn btn-link ngb-dp-arrow-btn" (click)="changeMonth(1)">
							<span class="ngb-dp-navigation-chevron"></span>
						</button>
					</div>
				</div>
				<!--[startDate]="{year: 2023, month: 12, day:15}"-->
				<ngb-datepicker
					#dp2
					[(ngModel)]="selectedDateOneWay"
					(navigate)="dateOneWay = $event.next"
					[dayTemplate]="customDay"
					[minDate]="minDate"
					[outsideDays]="'collapsed'"
				></ngb-datepicker>
			</div>
			<div class="container__range__datepicker__buttons">
				<app-button [text]="'Cancelar'" [size]="'xs'" [color]="'neutral'" (onClick)="openCalendar(true)"></app-button>
				<app-button [text]="'Aplicar'" [size]="'xs'" [color]="'outline-primary'" (onClick)="applyDate()"></app-button>
			</div>
		</div>
	</section>
</div>

<ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled">
	<span class="custom-day" [class.range]="selected || ( selectedDateOneWay ? selectedDateOneWay.year==date.year && selectedDateOneWay.day==date.day && selectedDateOneWay.month==date.month : false)">
		{{ date.day }}
	</span>
</ng-template>
