import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CoreModule } from '../../core';
import { FooterModule } from '../../shared/components/footer/footer.module';
import { PreFooterModule } from '../../shared/components/pre-footer/pre-footer.module';
import { ContainerComponent } from './container.component';

@NgModule({
	declarations: [ContainerComponent],
	imports: [CommonModule, RouterOutlet, PreFooterModule, FooterModule, CoreModule]
})
export class ContainerModule {}
