export interface ItemExp {
	data: any,
	expireTime?: number
}

export class StorageUtils {

	static setItemWithExpiration(key: string, value: any, maxAge: number | null, isSession: boolean) {
		let result: ItemExp = { data: value };

		if (maxAge) result.expireTime = Date.now() + maxAge;

		if (isSession) sessionStorage.setItem(key, JSON.stringify(result));
		else localStorage.setItem(key, JSON.stringify(result));
	}

	static getItem(key: string, isSession: boolean): ItemExp | null {
		let item: string | null;

		if (isSession) item = sessionStorage.getItem(key);
		else item = localStorage.getItem(key);

		const result: ItemExp = item ? JSON.parse(item) : null;

		if (result) {
			if (!result.expireTime || result.expireTime > Date.now())
				return result;

			localStorage.removeItem(key);
			return null;
		}

		return null;
	}
}
