import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { ButtonModule } from '../button/button.module';
import { NewcardOfferComponent } from './newcard-offer.component';

@NgModule({
	imports: [CommonModule, ButtonModule],
	exports: [NewcardOfferComponent],
	declarations: [NewcardOfferComponent, ReplacePipe],
	providers: []
})
export class NewCardOfferModule {}
