<div class="rowDestino center" *ngIf="hotels?.length && city">
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_135:1996)">
      <path
        d="M39.7181 1.51367H10.282C4.6125 1.51367 0 6.12617 0 11.7957C0 17.4652 4.6125 22.0777 10.282 22.0777H17.7687V30.3847L17.4031 30.078C15.5962 28.5617 12.7687 28.685 11.1006 30.3532C9.35254 32.1013 9.46699 34.8795 11.3679 36.4744L17.7686 41.8453V47.0215C17.7686 47.8305 18.4244 48.4863 19.2334 48.4863H36.8115C37.6205 48.4863 38.2764 47.8305 38.2764 47.0215V30.5609C38.2764 28.3484 36.6207 26.4736 34.4251 26.2001L26.5576 25.2199V22.0775H39.7181C45.3876 22.0775 50 17.465 50 11.7955C50 6.12598 45.3876 1.51367 39.7181 1.51367ZM20.6982 45.5566V42.627H35.3467V45.5566H20.6982ZM34.063 29.1073C34.7948 29.1985 35.3468 29.8234 35.3468 30.5609V39.6973H19.7666L13.251 34.2301C12.6727 33.7448 12.6376 32.9594 13.1722 32.4247C13.7726 31.8244 14.8696 31.7766 15.52 32.3223L18.2919 34.6481C19.2427 35.4458 20.6983 34.7691 20.6983 33.526V14.7949C20.6983 13.9872 21.3555 13.3301 22.1632 13.3301C22.9709 13.3301 23.628 13.9872 23.628 14.7949V26.5137C23.628 27.2526 24.1785 27.8759 24.9118 27.9673L34.063 29.1073ZM39.7181 19.1479H26.5576V14.7949C26.5576 12.3718 24.5862 10.4004 22.1631 10.4004C19.7399 10.4004 17.7686 12.3718 17.7686 14.7949V19.1479H10.2819C6.22793 19.1479 2.92969 15.8497 2.92969 11.7957C2.92969 7.7417 6.22793 4.44336 10.282 4.44336H39.7181C43.7721 4.44336 47.0703 7.7416 47.0703 11.7957C47.0703 15.8498 43.7721 19.1479 39.7181 19.1479Z"
        fill="#4B4B4B" />
      <path
        d="M38.603 7.80217L33.7793 12.6257L31.8854 10.7319C31.3134 10.1598 30.3859 10.1598 29.8138 10.7319C29.2417 11.3039 29.2417 12.2314 29.8138 12.8034L32.7435 15.7331C33.3155 16.3052 34.243 16.3053 34.8151 15.7331L40.6745 9.87375C41.2465 9.30168 41.2465 8.37424 40.6745 7.80217C40.1025 7.23 39.175 7.23 38.603 7.80217Z"
        fill="#4B4B4B" />
    </g>
    <defs>
      <clipPath id="clip0_135:1996">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>

<div class="rowDestino titulo" style="margin-bottom: 50px;" *ngIf="hotels?.length && city">Reserva un hotel</div>

<div class="cardHotel" [ngClass]="{show : hotels?.length}">

  <div class="loadingBoxCard" [ngClass]="{show : !hotels}">
    <div class="iconLoading">
      <img src="./assets/loader.gif" alt="">
    </div>
    <div class="texto">
      Cargando hoteles...
    </div>
  </div>

	<ngu-carousel #carousel [inputs]="tileConfig" [dataSource]="hotels" *ngIf="hotels">
		<ngu-tile NguCarouselItem class="px-1 px-lg-2" *nguCarouselDef="let item">
			<div class="card border-0 shadow-none">
				<ngu-carousel #tile [inputs]="carouselConfig" [dataSource]="item.Images">
					<ngu-tile NguCarouselItem class="slide p-0" *nguCarouselDef="let image">
						<img class="card-img w-100 object-fit-cover" loading="lazy" [src]="image" [alt]="item.Name">
					</ngu-tile>
				</ngu-carousel>
				<div class="card-body">
					<div class="card-title d-flex flex-row flex-nowrap align-items-start">
						<h5 class="fw-bold fontOpen lh-base text-wrap mb-0">{{ item.Name }}</h5>
						<a href="{{dynamicPackageUrl}}hotels/{{item.Id}}"
						   target="_blank"
						   class="text-white text-decoration-none p-0 ms-auto">
							<svg class="arrow" width="11" height="18" viewBox="0 0 11 18" fill="none"
							     xmlns="http://www.w3.org/2000/svg">
								<path
										d="M-9.24496e-08 15.885L6.79892 9L-6.94355e-07 2.115L2.09312 -9.14931e-08L11 9L2.09312 18L-9.24496e-08 15.885Z"
										fill="white" />
							</svg>
						</a>
					</div>
					<div class="card-subtitle cardStar px-0 mt-md-auto" style="clear:both">
						<div class="estrellas">
							<div [ngClass]="validateScore(1, item.Category)"></div>
							<div [ngClass]="validateScore(2, item.Category)"></div>
							<div [ngClass]="validateScore(3, item.Category)"></div>
							<div [ngClass]="validateScore(4, item.Category)"></div>
							<div [ngClass]="validateScore(5, item.Category)"></div>
						</div>
						<div class="iconos" *ngIf="item.Services">
							<div *ngFor="let service of item.Services.slice(0, 4)" class="{{ service.Icon }}"></div>
						</div>
					</div>
					<div class="card-text">
						<div class="cardOpinion px-0">Evaluaci&oacute;n de los hu&eacute;spedes</div>
						<div class="cardEvaluacion px-0 mt-md-auto">
							<div class="groupItems">
								<div class="itemsCardEvaluacion">
									<img src="./assets/providers/tripadvisor.svg" alt="">
								</div>
								<div class="itemsCardEvaluacion aRight">{{ item.Rating.Score }} / 10</div>
							</div>
							<div class="groupItems">
								<div class="itemsCardEvaluacion">
									<img src="./assets/svg/comment.svg" alt="">
								</div>
								<div class="itemsCardEvaluacion aRight">{{ item.Rating.Review }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ul class="dots mt-md-auto" NguCarouselPoint>
				<li [class.active]="i === tile.activePoint"
				    (click)="tile.moveTo(i)"
				    *ngFor="let i of tile.pointNumbers; let j = index"></li>
			</ul>
		</ngu-tile>
		<button NguCarouselPrev
		        class="p-carousel-prev position-absolute start-0"
		        [style.opacity]="carousel.isFirst ? .5 : 1"></button>
		<button NguCarouselNext
		        class="p-carousel-next position-absolute end-0"
		        [style.opacity]="carousel.isLast ? .5 : 1"></button>
	</ngu-carousel>
	<div class="imgLoadHotel" *ngIf="hotels?.length === 0">
		<a href="{{ images[0].RedirectLink }}" target="_blank">
			<img [src]="images[0].PathImage" [title]="images[0].NameImage" [alt]="images[0].NameImage">
		</a>
	</div>
</div>