import { Component, Input, OnInit } from '@angular/core';
import { IDynamicPackage } from '../../../../models';

@Component({
	selector: 'app-package-card',
	templateUrl: './package-card.component.html',
	styleUrls: [ './package-card.component.scss' ]
})
export class PackageCardComponent implements OnInit {
	@Input() item: IDynamicPackage;

	constructor() {
	}

	ngOnInit(): void {
	}

}
