import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink } from '@angular/router';
import { HomeAdvertisementModule } from '../../../Component/home-page/home-advertisement/home-advertisement.module';
import { BurgerMenuModule } from '../../../shared/components/burger-menu/burger-menu.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { MobileComponent } from './mobile';
import {
	ChangePasswordComponent,
	CheckEmailComponent,
	ForgotPasswordComponent,
	LoginComponent,
	NewAccountComponent
} from './modals';
import { NotificationsComponent } from './notifications';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
	declarations: [
		ToolbarComponent,
		ForgotPasswordComponent,
		ChangePasswordComponent,
		NotificationsComponent,
		MobileComponent,
		LoginComponent,
		CheckEmailComponent,
		NewAccountComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BurgerMenuModule,
		RouterLink,
		ButtonModule,
		MatBottomSheetModule,
		MatCheckboxModule,
		MatTabsModule,
		HomeAdvertisementModule
	],
	exports: [ToolbarComponent]
})
export class ToolbarModule {}
