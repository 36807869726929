import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IPackageResponseModel } from '../models';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../../../core/services';
import { Guid } from '../../../shared/utils';
import { IBaseResponseModel } from '../../../shared/models/base-response.model';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DynamicPackagesService {

	constructor(private _httpClient: HttpClient, private _storageService: StorageService) {
	}

	getPackages(first: number, limit: number): Observable<IBaseResponseModel<IPackageResponseModel>> {
		const url: string = `${environment.urlApiDynamicPackages}/v1/api/blocking/nmviajes?Parameter.First=${first}&Parameter.Limit=${limit}&TrackingCode=${Guid()}&MuteExceptions=${environment.production}&Caller.Company=Expertia&Caller.Application=NMViajes&Caller.FromIP=&Caller.FromBrowser=`;
		return this._httpClient.get<IBaseResponseModel<IPackageResponseModel>>(url).pipe(catchError(err => throwError(err)));
	}
}
