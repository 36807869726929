<div class="rowBoxTab" *ngFor="let formGroup of controls; let index = index" [formGroup]="formGroup">
    <div class="colBoxtab w-30 me-md-1">
        <app-input-autocomplete [validRequired]="isSubmit" [minTermLength]="3" placeholder="Origen"
            [items]="vuelos$ | async" [loading]="vuelosLoading" [typeahead]="vuelosInput$" formControlName="origen">
        </app-input-autocomplete>
    </div>
    <div class="colBoxtab w-30 me-md-1">

        <app-input-autocomplete [validRequired]="isSubmit" [minTermLength]="3" placeholder="Destino"
            [items]="vuelos2$ | async" [loading]="vuelosLoading2" [typeahead]="vuelosInput2$" formControlName="destino">
        </app-input-autocomplete>
    </div>
    <div class="colBoxtab w-30 me-md-1">
        <!-- <div class="colBoxtab w-15">
            <div class="groupTab">
                <img class="calendar-left" src="../../../../assets/flight-search/calendar-gray.png" alt="" />
                <div class="dp-hidden position-absolute">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="datepicker" ngbDatepicker #d="ngbDatepicker" [autoClose]="'outside'" outsideDays="hidden" [minDate]="
                index > 0
                  ? dateToNgbDateStruct(
                      controls[index - 1].controls.departureDate.value
                    )
                  : dateToNgbDateStruct(systemCurrentDate)
              " (dateSelect)="onDateSelection(formGroup, $event)" />
                    </div>
                </div>
                <div class="input-group">
                    <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" (click)="d.toggle()" (input)="validateInput(dpFromDate.value)" formControlName="departureDate" />
                </div>
            </div>
        </div> -->

        <!-- <div class="hideDate">
            <input name="datepicker2" style="border: 0.5px;"  mask="d0/M0/0*" maxlength="11" placement="bottom right" [minDate]="index > 0
            ? dateToNgbDateStruct(
                controls[index - 1].controls.departureDate.value
              )
            : dateToNgbDateStruct(systemCurrentDate)
        " ngbDatepicker #datepicker2="ngbDatepicker" [footerTemplate]="footerTemplate2" [autoClose]="'outside'" (dateSelect)="onDateSelection(formGroup, $event)" [displayMonths]="2"
                [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
            <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                    {{ date.day }}</span>
            </ng-template>
            <ng-template #footerTemplate2>
                <div class="btnDatePicker">
                    <hr class="my-0">
                    <button (click)="datepicker2.close()">Listo</button>
                </div>
            </ng-template>
        </div> -->

        <div class="hideDate">
            <!-- OCULTAR CAMPO -->
            <input class="hideInput" name="datepicker3" mask="d0/M0/0*" ngbDatepicker #d="ngbDatepicker"
                placement="bottom right" [minDate]="index > 0
            ? dateToNgbDateStruct(
                controls[index - 1].controls.departureDate.value
              )
            : dateToNgbDateStruct(systemCurrentDate)
        " [displayMonths]="1" (dateSelect)="onDateSelection(formGroup, $event)">
            <ng-template #footerTemplate3>
                <div class="btnDatePicker">
                    <hr class="my-0">
                    <button (click)="d.close()">Listo</button>
                </div>
            </ng-template>
            <!-- END OCULTAR CAMPO -->
        </div>

        <div class="iconCalendar">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                    stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>

        <input [ngClass]="{'isvalid': requiredFromDate && ( formatter.format(fromDate) === '')}" #dpFromDate3
            mask="d0/M0/0*" placement="bottom right" maxlength="11" class="form-control d-left isvalid"
            (click)="d.toggle()" [placeholder]="placeHolder" [value]="formatter.format(fromDate)"
            (input)="validateInput( dpFromDate3.value)" autocomplete="off" formControlName="departureDate">
        <!-- <input #dpFromDate2 mask="d0/M0/0*" 
        [ngClass]="{'isvalid': isSubmit && ( formatter.format(fromDate) === '')}" placement="bottom right" maxlength="11" class="form-control d-left"  (click)="datepicker2.toggle()" (blur)="emitValue()" placeholder="Salida" name="dpFromDate2" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput2(fromDate, dpFromDate2.value)"
            autocomplete="off"> -->
        <!-- <app-calendar [requiredFromDate]="isSubmit" [requiredToDate]="isSubmit" [toDate]="toDate || null" [fromDate]="fromDate || null" class="ignore-template" (changeDate)="changeDate($event)"></app-calendar> -->
    </div>
    <div class="colBoxtab w-10 ms-md-2 gap10">
        <button class="fullWidht" type="button" (click)="addTramo(index)"
            *ngIf="index == controls.length - 1 && index < maxTramo - 1">+</button>
        <button type="button" class="fullWidht" (click)="deleteTramo(formGroup)"
            *ngIf="index <= controls.length - 1 && controls.length > 1"> - </button>
    </div>
</div>