<div class="carousel slide" data-bs-ride="carousel" [id]="carouselId">
	<div class="carousel-indicators">
		<ng-container *ngFor="let b of images; let indice = index; let first = first">
			<button type="button" [attr.data-bs-target]="getIdXmlFormatted()" [attr.data-bs-slide-to]="indice"
			        [ngClass]="{'active': first}" aria-current="true" aria-label="Slide"></button>
		</ng-container>
	</div>
	<div class="carousel-inner">
		<div class="carousel-item" [attr.data-interval]="interval" [class.active]="i === 0"
		     *ngFor="let imgSrc of images let i = index">
			<img [src]="imgSrc" alt="" class="d-block w-100 img-fluid rounded-1" loading="lazy">
		</div>
	</div>
	<button class="carousel-control-prev d-none d-lg-flex" type="button" data-bs-slide="prev"
	        [attr.data-bs-target]="getIdXmlFormatted()">
		<span class="carousel-prev-icon" aria-hidden="true">
		  <img src="./assets/icon-left.svg" alt="Anterior">
		</span>
		<span class="visually-hidden">Previous</span>
	</button>
	<button class="carousel-control-next d-none d-lg-flex" type="button" data-bs-slide="next"
	        [attr.data-bs-target]="getIdXmlFormatted()">
		<span class="carousel-next-icon" aria-hidden="true">
		  <img src="./assets/icon-right.svg" alt="Siguiente">
		</span>
		<span class="visually-hidden">Next</span>
	</button>
</div>