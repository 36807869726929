<section class="boxAgendaCita text-center">
    <div class="rowCita">
        <div class="colCita">
            <h1>¡Viajemos!</h1>
            <h2>Agenda una cita con nuestros
                <br>asesores expertos y reúnete con
                <br>nosotros desde donde estés.
                <br>
                <br>Elige el horario más conveniente
                <br>para ti y te ayudaremos a&nbsp;
                <br>planificar tus vacaciones
            </h2>
            <p class="">
                <a class="" href="https://ayuda.nmviajes.com/">¿Ya realizaste una compra y necesitas ayuda? Ingresa aquí</a>
            </p>
        </div>
        <div class="colCita iframeCita">
            <iframe class="heightIframe" src="https://calendly.com/nuevomundoviajes/agenda-tu-cita?embed_domain=meta.nmviajes.com&amp;embed_type=Inline" frameborder="0" width="100%"></iframe>
        </div>
    </div>
</section>