<div class="container-fluid bg-white">
    <div class="row shadow-sm py-4 mb-4 doNotPrint">
        <div class="col-6 titleMobile">
            <h1>Condiciones de compra</h1>
        </div>
        <div class="col-6 text-end">
            <button class="btnModal" (click)="onPrint()">Imprimir</button>
        </div>
    </div>
    <div class="container-fluid px-4" id="exports">
        <div class="row">
            <div class="col-12">
                <div class="titleModal doNotPrint">Contenido</div>
                <a role="button" class="linkRed" (click)="scrollToSection('declaraciones')">A) Declaraciones
                    generales</a>
                <a role="button" class="linkRed" (click)="scrollToSection('responsabilidad')">B) Responsabilidad</a>
                <a role="button" class="linkRed" (click)="scrollToSection('solicitudes')">C) Solicitudes y pagos</a>
                <a role="button" class="linkRed" (click)="scrollToSection('servicios')">D) Servicios no incluidos</a>
                <a role="button" class="linkRed" (click)="scrollToSection('documentacion')">E) Documentación</a>
                <a role="button" class="linkRed" (click)="scrollToSection('vuelos')">F) Vuelos Airline Mix</a>
                <a role="button" class="linkRed" (click)="scrollToSection('hoteles')">G) Hoteles</a>
                <a role="button" class="linkRed" (click)="scrollToSection('autos')">H) Autos</a>
                <a role="button" class="linkRed" (click)="scrollToSection('traslados')">I) Traslados</a>
                <a role="button" class="linkRed" (click)="scrollToSection('cancelaciones')">J) Cancelaciones</a>
                <a role="button" class="linkRed" (click)="scrollToSection('cambio')">K) Cambio de fecha</a>
                <a role="button" class="linkRed" (click)="scrollToSection('procedimiento')">L) Procedimiento de
                    consultas o reclamos</a>
                <a role="button" class="linkRed" (click)="scrollToSection('presentacion')">M) Presentación en el
                    aeropuerto</a>
                <a role="button" class="linkRed" (click)="scrollToSection('seguros')">N) Seguros</a>
                <a role="button" class="linkRed" (click)="scrollToSection('equipaje')">Ñ) Equipaje</a>
                <a role="button" class="linkRed" (click)="scrollToSection('aceptacion')">O) Aceptación del cliente</a>
                <a role="button" class="linkRed" (click)="scrollToSection('autorizacion')">P) Autorización del cliente
                    para el manejo de datos personales</a>
                <a role="button" class="linkRed" (click)="scrollToSection('carta')">Q) Carta de responsabilidad</a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="subtitleModal" id="declaraciones">
                    A) DECLARACIONES GENERALES
                </div>

                <ul class="listaModal">
                    <li>Declaro ser mayor de edad y tener la capacidad legal para adquirir los servicios ofrecidos a
                        través de este sitio web.
                    </li>
                    <li>Declaro que toda la información registrada es exacta, clara y detallada.</li>
                    <li>Como Cliente autorizo a Promotora de Turismo Nuevo Mundo S.A.C. o al proveedor de los servicios
                        adquiridos a debitar de mi Tarjeta de Crédito los valores en la forma seleccionada por mí en el
                        paso anterior.</li>
                    <li>Al aceptar este contrato, como Cliente me comprometo a enviarlo impreso, con todos los datos
                        completos, junto con una copia de mi documento de identidad cuando sea solicitado por Promotora
                        de Turismo Nuevo Mundo S.A.C.
                    </li>
                    <li>Como Cliente declaro conocer y aceptar las reglas de Cancelación y Reembolso, y los recargos que
                        ellos puedan generar.
                    </li>
                    <li>Declaro haber sido informado que mi solicitud de compra será atendida dentro de las 72 horas de
                        adquirido el servicio. Luego de procesada la compra, el ticket y/o voucher será enviado dentro
                        de las 24 horas siguientes.
                    </li>
                    <li>Con la efectiva cobranza a través del medio de pago seleccionado por mí en el paso anterior,
                        Promotora de Turismo Nuevo Mundo S.A. se compromete a gestionar por mi cuenta y orden los
                        servicios seleccionados en el paso anterior.</li>
                    <li>En caso de mi incumplimiento de los términos pactados en la forma de pago, como Cliente
                        reconozco adeudar los montos presentados en el paso anterior, que corresponden a mi elección.
                    </li>
                    <li>Declaro que he sido debida y previamente informado respecto de los servicios contratados, así
                        como de las condiciones, regulaciones y restricciones que conllevan, las mismas que me han sido
                        detalladas y explicadas debidamente, y que
                        acepto plenamente.</li>
                    <li>Declaro haber sido informado y acepto que es mi responsabilidad como Cliente contar con la
                        documentación en regla de acuerdo con el destino y/o nacionalidad, esto es tener pasaporte
                        vigente (mínimo 6 meses) y visas vigentes de los
                        países por los que voy a viajar, vacunas, permiso de menores en caso sea necesario y la
                        documentación respectiva para realizar el viaje.</li>
                    <li>Declaro hacerme responsable de las consecuencias económicas derivadas de cualquier utilización
                        de esta página web, incluido el mal uso de las contraseñas que en su caso el cliente o un
                        tercero no autorizado pueda llevar a cabo, evitando
                        el acceso de terceros no autorizados a datos personales que solo el cliente debe conocer, como
                        un posible nombre de usuario o los datos de tu tarjeta de crédito.</li>
                    <li>Como Cliente me responsabilizo de la revisión de mi correo electrónico, por lo que declaro tener
                        conocimiento de las comunicaciones que se me efectúen por esta vía.</li>
                    <li>
                      Declaro tener conocimiento que las condiciones climáticas pueden variar significativamente; y que algunos fenómenos pueden afectar la experiencia del tour/viaje y están fuera del control de Promotora de Turismo Nuevo Mundo S.A.C.
                    </li>
                    <li>
                      Asimismo, como Cliente me responsabilizo por llevar la ropa y equipo apto para las diversas condiciones climáticas, incluyendo cambios bruscos de tiempo, en base al destino (tour/viaje) seleccionado.
                    </li>
                    <li>
                      No obstante, informamos que en la sierra del Perú las temporadas de lluvia son del mes noviembre al mes de marzo. Asimismo, en la Selva del Perú, las temporadas de lluvia son en los meses de noviembre a marzo. En la costa del Perú, el verano es de diciembre al mes de abril y la temporada de frío entre mayo y noviembre. Para Europa, las temporadas de frío son los meses de diciembre a marzo y más cálido de abril a noviembre. En el caribe, las temporadas de huracanes y condiciones climáticas adversas son del mes de agosto hasta noviembre. Ten en consideración que las etapas de lluvia pueden estar acompañadas de neblina. En general, pese a que sea una temporada apta para visitas, es posible que el clima pueda variar e incluso no se permita la observación de atractivos turísticos.
                    </li>
                    <li>En caso de no aceptar las presentes Condiciones Generales de Compra, el Cliente no podrá acceder
                        ni utilizar los servicios y/o contenidos ofertados en esta página.</li>
                </ul>


                <div class="subtitleModal" id="responsabilidad">
                    B) RESPONSABILIDAD
                </div>
                <ul class="listaModal">
                    <li>Promotora de Turismo Nuevo Mundo S.A.C. declara expresamente que actúa en la condición exclusiva
                        de intermediaria en la reserva o contratación de los distintos servicios turísticos y su
                        responsabilidad será determinada únicamente en
                        tal condición conforme a las disposiciones legales sobre la materia.</li>
                    <li>Promotora de Turismo Nuevo Mundo S.A.C. no se responsabiliza por los hechos que se produzcan por
                        caso fortuito o fuerza mayor, fenómenos climáticos o hechos de la naturaleza que acontezcan
                        antes o durante el desarrollo de los servicios
                        que impidan, demoren o de cualquier modo obstaculicen la ejecución total o parcial de las
                        prestaciones contratadas. No obstante, lo declarado en el párrafo que antecede, Promotora de
                        Turismo Nuevo Mundo S.A.C. brindará soporte
                        al cliente, en su condición de intermediaria, para la mejor solución del problema y de
                        corresponder, solicitar los reembolsos respectivos, de acuerdo a las políticas de los
                        operadores.
                    </li>
                    <li>Promotora de Turismo Nuevo Mundo S.A.C. no se responsabiliza por las alteraciones de horarios,
                        postergaciones y/o cancelaciones, las comodidades, equipos utilizados o cualquier otra
                        modificación realizada por las empresas transportadoras,
                        ni por cualquier otro hecho de tercero, no imputable a Promotora de Turismo Nuevo Mundo S.A.C.,
                        que afecten la prestación del servicio. No obstante, lo declarado en el párrafo que antecede,
                        Promotora de Turismo Nuevo Mundo S.A.C.
                        brindará soporte al cliente, en su condición de intermediario, para la mejor solución del
                        problema y de corresponder, solicitar los reembolsos respectivos, de acuerdo a las políticas de
                        los operadores.</li>
                    <li>En ningún caso Promotora de Turismo Nuevo Mundo S.A.C. responderá frente al Cliente por daños
                        indirectos o lucro cesante alegado por ellos, referidos a hechos en los cuales los funcionarios,
                        empleados o representantes de Promotora
                        de Turismo Nuevo Mundo S.A.C. no hayan tenido participación en ellos, debiendo en todo caso
                        acreditar que les son imputables.</li>
                    <li>Promotora de Turismo Nuevo Mundo S.A.C. se reserva el derecho de procesar o no cualquier
                        operación de compra, en donde se evidencie o haya sospecha de transacción dudosa o fraude,
                        pudiendo solicitar al cliente documentación adicional
                        y su presencia física en nuestras oficinas para la firma del voucher. Asimismo, Promotora de
                        Turismo Nuevo Mundo S.A.C. podrá requerir al Cliente el cambio de la modalidad de pago por él
                        seleccionada, de ser necesario para concretar
                        su operación de compra.</li>
                </ul>

                <div class="subtitleModal" id="solicitudes">
                    C) SOLICITUDES Y PAGOS
                </div>
                <ul class="listaModal">
                    <li>El precio estimado en el momento de la solicitud de servicios y hasta que no se haya efectuado
                        la emisión de los pasajes u órdenes de servicio y su facturación y pago, queda sujeto a
                        disponibilidad y a modificaciones sin previo aviso.
                        Cuando se produzca una alteración en los mismos y/o modificaciones en sus costos y/o en el tipo
                        de cambio aplicado y/o por la aplicación de los impuestos que correspondan de acuerdo a la
                        normativa vigente, Promotora de Turismo
                        Nuevo Mundo S.A.C. no será responsable de los mayores costos resultantes, los que deberán ser
                        comunicados al cliente a su correo electrónico, debiendo asumirlos. Cada Pasaje Aéreo se
                        encuentra regulado por una serie de condiciones
                        y restricciones determinadas por cada Compañía Aérea para cada tarifa en particular. De acuerdo
                        al monto de la tarifa, se imponen ciertas limitaciones sobre la utilización del Pasaje Aéreo,
                        por ejemplo: no reembolsable, no transferible,
                        restricciones sobre cambios de ruta, imposición de estadías mínimas y máximas, restricciones a
                        la devolución del importe abonado, etc. Las tarifas más económicas son las que mayores
                        restricciones poseen. Tenga presente que Promotora
                        de Turismo Nuevo Mundo S.A.C. no se encuentra autorizada a variar ni modificar en ningún caso
                        las regulaciones de los Pasajes Aéreos.</li>
                    <li>Los boletos aéreos están sujetos al Impuesto General a las Ventas cuando se expidan en el país o
                        de aquellos documentos que aumenten o disminuyan el valor de venta de los pasajes, siempre que
                        el servicio se inicie o termine en el país,
                        así como cuando se adquieran en el extranjero para ser utilizados desde el país.</li>
                    <li>Todos los importes pagados antes de la confirmación de los servicios son recibidos por concepto
                        de reserva.</li>
                    <li>Los precios definitivos quedarán fijados a partir del momento de la confirmación definitiva de
                        los servicios que se produce con la emisión de los pasajes u órdenes de servicios, vouchers, y
                        su facturación.</li>
                    <li>De no efectuarse la cancelación del precio, Promotora de Turismo Nuevo Mundo S.A.C. queda
                        facultada a cancelar los servicios con previo aviso al Cliente, no teniendo el Cliente derecho a
                        reintegro alguno.</li>
                    <li>
                        De generarse un doble cargo en su proceso de compra, tomar en cuenta que de manera inmediata
                        procederemos con las gestiones de extorno, lo que no excederá del plazo de 30 días. De no verse
                        regularizado en el periodo antes mencionado, por favor contactarse con nosotros a través del
                        portal de ayuda para brindarle la asistencia particular a su caso.
                    </li>
                </ul>

                <div class="subtitleModal" id="servicios">
                    D) SERVICIOS NO INCLUIDOS
                </div>
                <p>Sólo debe considerarse incluido en los precios aquello que está expresa y literalmente detallado en
                    la documentación de viaje, de lo que se desprende que no está incluido en los precios: visados,
                    certificados de vacunación, tasas de embarque
                    o de aeropuertos, extras en hoteles (cafés, licores, aguas minerales, lavado y planchado, propinas,
                    comunicaciones, resort fee y cargos adicionales en hoteles, llamadas telefónicas sin costo,
                    periódicos, cajas de seguridad, deportes
                    o excursiones no incluidos, etc.), tasas sobre servicios, impuestos, gastos e intereses de
                    operaciones financieras, excursiones y visitas opcionales, alimentación extra en ruta, comidas y/o
                    gastos adicionales, o perjuicios producidos
                    por cancelaciones, demoras en las salidas o llegadas de los medios de transporte, o por razones
                    imprevistas ajenas a Promotora de Turismo Nuevo Mundo S.A., entre otros.</p>

                <div class="subtitleModal" id="documentacion">
                    E) DOCUMENTACION
                </div>
                <p>Es responsabilidad del Cliente informarse sobre los requisitos que exigen las autoridades
                    migratorias, aduaneras y sanitarias de los destinos, siendo responsabilidad exclusiva del Cliente
                    contar con la documentación personal que exijan
                    las autoridades mencionadas. Promotora de Turismo Nuevo Mundo S.A. no será responsable por
                    inconvenientes que sufriera el Cliente que carezca de documentos en las condiciones necesarias ya
                    sea al salir o al entrar en este u otro país.
                    Debe tenerse en cuenta que la obtención de las visas en los países a visitar por el Cliente corren
                    por exclusiva responsabilidad de éste, como así también la presentación de la documentación
                    necesaria para el ingreso a los países de
                    destino y documentos de sanidad requeridos (vacunas).</p>

                <div class="subtitleModal" id="vuelos">
                    F) VUELOS AIRLINE MIX
                </div>
                <p>En el caso de vuelos que se muestren como Airline Mix , www.nmviajes.com ofrecerá resultados que son
                    una combinación de vuelos por separado, o dos vuelos de ida en vez de ida y vuelta. Nuestra web
                    reserva 2 vuelos por separado y estas
                    son independientes , cada una puede tener distintas regulaciones. (Ejemplo: tasas de equipaje ,
                    cargos por cambios, y diferentes políticas de reembolsos). En caso que una de las 2 aerolineas
                    realice alguna modificación en su itinerario
                    o Ud desee realizar algún cambio, el otro vuelo no se afectatará lo mismo que si se realiza una
                    postergación o cancelación , el otro vuelo no estaría afectado. Recuerde que recibirá 2 códigos de
                    reserva , lo mismo que boletos independientes
                    y en el caso que sea un viaje internacional, es importante que revise la información previa ya que
                    para presentarse en el aeropuerto es importante que informe ambos codigos y boletos aéreos.
                </p>

                <div class="subtitleModal" id="hoteles">
                    G) HOTELES
                </div>
                <ul class="listaModal">
                    <li>Informamos que cada hotel tiene sus propias regulaciones en el servicio y condiciones
                        tarifarias, así como la categorización de tipos de habitación. El horario de ingreso puede
                        variar según la temporada y ocupación del mismo. Todas
                        las reservaciones se generan en habitación estándar a excepción de casos que se haya solicitado
                        otro tipo de habitación.</li>
                    <li>El Cliente deberá tener al menos 18 años para poder realizar el check in, a menos que se
                        encuentre acompañado de sus padres o un representante legal. En el extranjero deberá contar con
                        una tarjeta de crédito como garantía si así se
                        lo requieren al momento del check in.</li>
                    <li>En algunos hoteles los cargos adicionales no están incluidos y deberán pagarse directamente en
                        destino. Estos pueden variar entre USD10.00 hasta USD30.00 aprox. por habitación por día. Las
                        reservas garantizan la ocupación máxima de
                        personas en la habitación; la distribución de camas depende de la propiedad. Se recomienda
                        consultar en caso de las habitaciones TRIPLE Y CUADRUPLE así mismo si tienen alguna solicitud
                        especial al momento de generar su reserva.
                        Las solicitudes especiales se confirman solo en la propiedad no se pueden confirmar antes de la
                        llegada.</li>
                    <li>Reservas hoteleras en territorio peruano: Los residentes y ciudadanos peruanos (y los
                        extranjeros que permanezcan más de 59 días en Perú) deben abonar el impuesto a la renta del 18%.
                        En caso de los extranjeros si no presentan una copia
                        de la tarjeta de inmigración y el pasaporte en el momento del check in deberán pagar el impuesto
                        del 18%.
                    </li>
                    <li>Se recomienda a los clientes llevar impreso su voucher de reserva. En caso Ud. no se presente en
                        el Hotel en la fecha y horario correspondiente, el Hotel puede cancelar su reserva y
                        considerarla como NO SHOW lo cual afectaría cualquier
                        solicitud de reembolso total o parcial.</li>
                </ul>

                <div class="subtitleModal" id="autos">
                    H) AUTOS
                </div>
                <ul class="listaModal">
                    <li>Informamos que cada rentadora de autos tiene sus propias regulaciones de servicio, así como sus
                        condiciones tarifarias y contractuales aplicables al alquiler de vehículos.</li>
                    <li>La reserva de auto se realiza para una categoría específica, se garantiza el tamaño y capacidad,
                        la información de modelo y marca es referencial. La renta del auto se realiza por un periodo o
                        bloque determinado (día, semana, horas).
                        Estas incluyen kilometraje y/o millaje ilimitado y los requisitos y seguros mínimos para retirar
                        el auto y circular en la localidad indicada. La tarifa de renta no incluye el pago de multas,
                        impuestos, infracciones, arrastre de
                        grúas, gasolina, cargos por dejar el auto en otra ciudad, ni cualquier servicio adicional. En
                        caso se extienda el periodo de alquiler o se retire o entregue el auto fuera de las horas
                        estipuladas o en otra localidad pueden existir
                        cargos adicionales. Los impuestos y tasas en algunos casos están incluidos de acuerdo a la
                        tarifa solicitada.
                    </li>
                    <li>El conductor deberá ser mayor de 25 años, en caso tenga entre 21 y 26 años algunas rentadoras
                        pueden exigir documentación adicional y/o solicitar se paguen cargos adicionales.</li>
                    <li>El conductor debe llevar consigo el voucher de reserva emitido por Promotora de Turismo Nuevo
                        Mundo S.A.C., permiso de conducir vigente y valido en el lugar de destino, tarjeta de crédito
                        internacional propia con saldo disponible en
                        caso de garantía y pasaporte vigente.</li>
                    <li>En caso desee contratar tarifas con seguros y servicios adicionales (seguros con pólizas contra
                        terceros, cargos de gasolina, cargo por conductor adicional, puede revisar la información de
                        reserva y/o solicitarlo al asesor de venta.</li>
                </ul>

                <div class="subtitleModal" id="traslados">
                    I) TRASLADOS
                </div>
                <p>Los servicios contratados son en servicio regular (compartido con otros pasajeros) o en privados
                    según lo indicado en el voucher de servicios. Se recomienda llevar impresa la documentación de los
                    servicios ya que esta contiene información
                    de los servicios contratados con el operador local e información importante en caso de emergencia.
                    En caso Ud. no se presente a la fecha y horario correspondiente, el operador puede cancelar su
                    reserva y Ud. no tendrá derecho a solicitar
                    un reembolso total o parcial. En algunos casos se le solicitará que, previo a la fecha para hacer
                    uso de la Actividad o Traslado, Ud. se comunique con el Operador para confirmar algunos detalles de
                    la Actividad y/o coordinar el punto
                    de encuentro o el traslado y/o solicitar los vouchers definitivos sin los cuales Ud. no podrá hacer
                    uso del servicio. Si Ud. no realiza este contacto en tiempo y forma y, en virtud de ello, Ud. ve
                    frustrado el uso y goce del servicio,
                    Promotora de Turismo Nuevo Mundo S.A.C. no se hará responsable y Ud. no tendrá derecho a exigir
                    reembolso alguno por parte de Promotora de Turismo Nuevo Mundo S.A.C y/o el Operador.
                </p>

                <div class="subtitleModal" id="cancelaciones">
                    J) CANCELACIONES
                </div>
                <ul class="listaModal">
                    <li>En caso de desistimiento por parte del Cliente de servicios contratados en firme por Promotora
                        de Turismo Nuevo Mundo S.A.C. a terceros, las cancelaciones estarán sujetas a las condiciones
                        contractuales bajo las cuales estos últimos
                        presten sus servicios, las mismas que han sido informadas al cliente en el paso anterior. En
                        todos los casos de reintegro, la agencia podrá retener del precio, los gastos incurridos más la
                        comisión del diez por ciento de los servicios
                        contratados con terceros.</li>
                    <li>
                      En caso de condiciones climáticas extremas, Promotora de Turismo Nuevo Mundo S.A.C se reserva el derecho de cancelar o reprogramar el tour para garantizar la seguridad de los Clientes.
                    </li>
                    <li>Promotora de Turismo Nuevo Mundo S.A.C. no es responsable por los servicios que no fueran
                        tomados por el Cliente en tiempo y forma convenidos, ni por las consecuencias que de ello
                        emanen, como la cancelación del servicio por parte
                        del prestador, ni por el reintegro, reembolso o devolución del importe abonado por dicho
                        servicio.</li>
                    <li>Respecto a la devolución de boletos aéreos, el Cliente acepta las condiciones impuestas por las
                        regulaciones de las compañías aéreas en relación a este trámite.</li>
                </ul>

                <div class="subtitleModal" id="cambio">
                    K) CAMBIO DE FECHA
                </div>
                <p>No procede el cambio de fecha de los boletos aéreos. El cambio de fecha respecto de otro servicio
                    turístico queda sujeto a las modalidades, condiciones y disponibilidad de los distintos prestadores
                    de los servicios, debiendo abonarse la
                    correspondiente penalidad aplicada por el prestador y una comisión del 10% por concepto de
                    modificaciones y, en su caso, la diferencia tarifaria si correspondiese. Tratándose de pasajes
                    nacionales se estará las políticas y directivas
                    de cada Línea Aérea.</p>

                <div class="subtitleModal" id="procedimiento">
                    L) PROCEDIMIENTO DE CONSULTAS O RECLAMOS
                </div>
                <p>En el caso de que Usted necesite efectuar un reclamo y/o consulta, solicitar modificaciones y/o
                    cancelaciones siempre que su tarifa lo permita, solicitar el reenvío de vouchers o tickets. Recuerde
                    que Promotora de Turismo Nuevo Mundo S.A.C.
                    es una plataforma de intermediación y, como tal, su principal vía de contacto es en línea.
                    Independientemente de ello, para cualquier urgencia siempre cuenta con líneas telefónicas que
                    constan en los correos y/o vouchers enviados con
                    cada Servicio Turístico.</p>
                <ul class="listaModal">
                    <li>En el caso de querer realizar un reclamo, sugerencia o consulta, se recomienda realizar una
                        descripción detallada de los hechos adjuntando de inmediato la copia de los antecedentes de
                        respaldo, explicando lo ocurrido y formulando en
                        términos claros, simples y la solución que desea. De esta manera podremos revisar su caso en
                        forma más rápida y eficiente. Promotora de Promotora de Turismo Nuevo Mundo S.A.C. analizará el
                        reclamo, y dará respuesta al mismo correo
                        electrónico informado por Usted lo antes posible. Si de la revisión de los antecedentes
                        resultara procedente acoger en todo o en parte el reclamo, Promotora de Turismo Nuevo Mundo
                        S.A.C. se lo hará saber y le solicitará la conformidad
                        del acuerdo por mail.</li>
                    <li>Los cambios o cancelaciones de reservas efectuadas por nuestro intermedio, si bien son regidos
                        por los Proveedores de los Servicios Turísticos, deben ser gestionados a través de Promotora de
                        Turismo Nuevo Mundo S.A.C. En cualquiera
                        de los casos anteriores, la línea aérea y/o el proveedor lo derivarán a nuestra empresa.</li>
                    <li>En los casos de servicios de vuelos regulares, las condiciones son impuestas por las
                        transportadoras con quien el Cliente deberá entenderse directamente y reclamar en su caso por
                        cualquier inconveniente o perjuicio sufrido como consecuencia
                        del viaje. Promotora de Turismo Nuevo Mundo S.A.C. no tiene control sobre estas condiciones y
                        ofrecerá todo apoyo a su alcance para llevarlos a cabo.</li>
                </ul>

                <div class="subtitleModal" id="presentacion">
                    M) PRESENTACIÓN EN EL AEROPUERTO
                </div>
                <p>Usted deberá hacerse presente en el Aeropuerto de partida el día previsto y a la hora indicada con la
                    Documentación de Viaje. Para vuelos domésticos aconsejamos presentarse en el aeropuerto con 3 horas
                    de anticipación y, para vuelos internacionales,
                    la anticipación deberá ser de al menos 4 horas. Si Usted no se presenta a embarcar o llega con
                    atraso a embarcar en la fecha y horarios indicados se configura lo que en el mercado turísticos se
                    conoce como No Show y la Compañía Aérea
                    puede cancelar cualquier reserva para la continuación del viaje o el retorno y Usted no tendrá
                    derecho a exigir la devolución total o parcial del importe abonado.</p>

                <div class="subtitleModal" id="seguros">
                    N) SEGUROS
                </div>
                <p>Le aconsejamos que viaje siempre convenientemente asegurado. En ciertos casos, le ofrecemos la
                    posibilidad de añadir un seguro a su viaje en ciertos destinos. Adicionalmente nuestros agentes le
                    proporcionarán toda la información que pueda
                    necesitar para decidir si quiere contratar el seguro propuesto. Tenga en cuenta que los seguros no
                    permiten cancelaciones, por tanto no son reembolsables. A menos que su visado haya sido denegado, en
                    cuyo caso debe contactarse con
                    nosotros para su devolución previa aplicación de la penalidad correspondiente de acuerdo a las
                    políticas del prestador del servicio.</p>

                <div class="subtitleModal" id="equipaje">
                    Ñ) EQUIPAJE
                </div>
                <p>Todas las compañías aéreas tienen diferentes políticas de franquicias de equipajes. Infórmese antes
                    de viajar. Promotora de Turismo Nuevo Mundo S.A.C. no se responsabiliza por el deterioro y/o
                    extravío y/o hurto y/o robo y/o pérdida del
                    equipaje y demás efectos personales de los pasajeros. En caso de pérdida o deterioro de equipaje,
                    Usted debe dirigirse a las oficinas de atención al cliente de la compañía aérea y realizar el
                    correspondiente reclamo. Se recomienda
                    al Cliente contratar un seguro de viaje para cubrir parte de los riesgos mencionados.
                </p>

                <div class="subtitleModal" id="aceptacion">
                    O) ACEPTACION DEL CLIENTE
                </div>
                <p>Las presentes condiciones generales, junto con la restante documentación que se entrega al Cliente,
                    conforman el Contrato de Viaje. Estas condiciones son entregadas por Promotora de Turismo Nuevo
                    Mundo S.A.C. al Cliente al momento de realizar
                    la reserva de los servicios. Al aceptar estos términos y condiciones, el Cliente reconoce y acepta los riesgos asociados a las condiciones climáticas durante su Viaje. El Cliente declara conocer y aceptar las presentes condiciones
                    generales de contratación y dicha aceptación queda ratificada por medio de uno cualquiera de los
                    siguientes actos:
                </p>
                <ul class="listaModal">
                    <li>El pago de la reserva y/o de los servicios contratados antes del inicio del viaje, por cualquier
                        forma o modalidad.
                    </li>
                    <li>La aceptación de la factura de los servicios contratados.</li>
                    <li>Mediante el uso de una porción cualquiera de los servicios contratados.</li>
                </ul>

                <div class="subtitleModal" id="autorizacion">
                    P) AUTORIZACION DEL CLIENTE PARA EL MANEJO DE DATOS PERSONALES
                </div>
                <p>El Cliente, titular de los datos personales, otorga su consentimiento de manera indefinida, libre,
                    previo, expreso, inequívoco e informado para que Promotora de Turismo Nuevo Mundo S.A.C. y en tanto
                    este consentimiento no sea revocado,
                    pueda realizar el tratamiento de sus datos personales con las siguientes finalidades: i) decidir el
                    otorgamiento de servicios que solicite, (ii) efectuar la cobranza de los servicios solicitados,
                    (iii) ofrecerle otros servicios de
                    Promotora de Turismo Nuevo Mundo S.A.C. y/o de sus vinculadas; (iv) enviar ofertas comerciales,
                    promociones, publicidad e información general de los productos y/o servicios de Promotora de Turismo
                    Nuevo Mundo S.A.C. y/o de sus vinculadas,
                    (v) usar y/o transferir esta información (dentro o fuera del país) a terceros y/o a cualquier
                    vinculada de Promotora de Turismo Nuevo Mundo S.A.C.; (vi) formalizaciones contractuales; (vii)
                    remisión de correspondencia; (viii) enviar
                    a los proveedores del servicio de conexión tecnológica para recibir los pagos aceptando medios de
                    pago autorizados por Promotora de Turismo Nuevo Mundo S.A.C.
                </p>
                <p>Promotora de Turismo Nuevo Mundo S.A.C. tratará sus datos personales con la más estricta
                    confidencialidad, adoptará las medidas de seguridad establecidas en la Ley N° 29733, su reglamento y
                    modificaciones; y usará sus datos personales
                    autorizados para las finalidades expresamente establecidas. El Cliente declara haber sido informado
                    que podrá revocar en cualquier momento su consentimiento, comunicando su decisión por escrito, lo
                    cual no afectará el uso de sus datos
                    ni el contenido de las bases de datos de Promotora de Turismo Nuevo Mundo S.A.C. para la ejecución
                    y/o desarrollo de la relación contractual que mantiene con Promotora de Turismo Nuevo Mundo S.A.C.
                    Asimismo, el Cliente podrá ejercitar
                    los derechos de acceso, rectificación, cancelación u oposición que, en su caso, le correspondan
                    conforme a la ley precitada, mediante una solicitud por escrito presentada a Promotora de Turismo
                    Nuevo Mundo S.A.C. en su oficina de
                    Av. José Pardo 801, Miraflores y/o correo electrónico datospersonales@expertiatravel.com</p>

                <div class="subtitleModal" id="carta">
                    Q) CARTA DE RESPONSABILIDAD
                </div>
                <iframe class="d-block mb-3 mb-lg-4 mb-xl-5 border-0"
                        src="https://servicio.nmviajes.com:9443/documentos/CARTA-DE-RESPONSABILIDAD.pdf" width="100%"
                        height="850"></iframe>
            </div>
        </div>
    </div>
</div>
