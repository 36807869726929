<form name="form" [formGroup]="form">
   <div class="row gx-3 gy-md-3 gy-sm-3 gy-xs-2 gy-2">
      <div class="col-md-6">
          <div class="group__input">
            <input matInput aria-label="State3" [matAutocomplete]="auto" formControlName="destino"
            (keyup)="autoComplete($event)" (keypress)="inputValidator.removeNonValid($event, inputValidator.VALID_NAME)"
            placeholder="¿A que país le gustaría ir?" (click)="autoCompleteInit()">
            <img src="./assets/svg/location-red.svg" alt="o" />
          </div>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let state of countriesPackageSearch" [value]="state.label">
              <img class="example-option-img" aria-hidden src="./assets/svg/airplane-line.svg">
              <span>{{state.label}}</span>
            </mat-option>
          </mat-autocomplete>
      </div>
      <div class="col-md-6">
        <div class="group__input">
          <select formControlName="themes" [class.first-option]="form.controls.themes.value==''">
            <option value="" disabled selected hidden>Seleccionar tema</option>
            <option *ngFor="let elem of themes" [value]="elem.code">
              {{ elem.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="group__input">
          <select formControlName="months" [class.first-option]="form.controls.months.value==''">
            <option value="" disabled selected hidden>Seleccionar mes</option>
            <option *ngFor="let elem of months" [value]="elem.code">
              {{ elem.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
         <div class="row gx-3 gy-3 d-flex align-items-center">
             <div class="col-xl-9 col-lg-9 col-md-8">
                <div class="group__input">
                  <select formControlName="noches" [class.first-option]="form.controls.noches.value==''">
                    <option value="" disabled selected hidden >Seleccionar número de noches</option>
                    <option *ngFor="let elem of noches" [value]="elem.code">
                      {{ elem.name }}
                    </option>
                  </select>
                </div>
             </div>
             <div class="col-xl-3 col-lg-3 col-md-4">
                 <app-button [isMobile]="true" [left]="true" [icon]="'search.svg'" [text]="'Buscar'" [w100]="true" (onClick)="searchPaquete()"></app-button>
             </div>
         </div>
      </div>
   </div>
</form>