<div class="modal-header">
	<div class="titleVerifica">Verifica tu correo</div>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
	<div class="iconCenter">
		<img src="./assets/modal/correo.svg" alt="">
	</div>
	<div class="descriptionText">
		Hemos enviado un correo de verificación a tu email ingresado, por favor valida el código a continuación para
		confirmar que tienes acceso.
	</div>
	<div class="action">
		<button data-bs-dismiss="modal" aria-label="Close" type="button" class="">Aceptar</button>
	</div>
</div>