<div class="toolbar__mobile" [class.show]="showItems">
	<div class="toolbar__mobile__actions">
		<div class="toolbar__mobile__actions__item"
		     (click)="redirect('https://ayuda.nmviajes.com/support/home')"
		     *ngIf="!hideHelp">
			<img src="./assets/svg/bi_question-circle.svg" class="info" alt="i">
			<span>Atención al cliente</span>
		</div>
		<div class="toolbar__mobile__actions__item" (click)="redirect('/canales-de-atencion')">
			<img src="./assets/svg/ph_headset.svg" class="info" alt="i">
			<span>Contáctanos</span>
		</div>
	</div>
	<div class="toolbar__mobile__line"></div>
	<div class="toolbar__mobile__actions">
		<div>
			<app-button
				[text]="!isLogged ? 'Iniciar sesión': 'Cerrar sesión'"
				[isMobile]="true"
				[w100]="true"
				(onClick)="clickAction()"
			></app-button>
		</div>
	</div>
</div>