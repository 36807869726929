<div class="modal-header">
	<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body p-3 text-center">
	<img src="../../../../assets/svg/modal-success-icon.svg" alt="Success" class="mx-auto">
	<h3 class="modal-title mt-4" style="color: #b50a27"><strong>{{ title }}</strong></h3>
	<p class="mt-3" [innerHTML]="content"></p>
	<button type="button"
	        class="btn-agencias activa inter-semi-bold fs-6 w-auto py-2 px-5"
	        (click)="activeModal.dismiss()"
	        *ngIf="buttonText">
		{{ buttonText }}
	</button>
</div>