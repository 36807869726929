import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output, SimpleChanges,
	ViewChild
} from '@angular/core';
import { TABS_DATA } from '../../constants';
import { Router } from '@angular/router';
import { PlatformService } from '../../../../core/services';
import { DEVICES } from '../../../../core/constants';

@Component({
	selector: 'app-search-tabs',
	templateUrl: './search-tabs.component.html',
	styleUrls: [ './search-tabs.component.scss' ]
})
export class SearchTabsComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() indexSelectedTab = 0;
	@Output() clickedTab = new EventEmitter();
	@ViewChild('slider', { static: false }) slider: ElementRef;

	dataTabs: any = TABS_DATA;
	indexActive = 0;
	isDown = false;
	startX: number;
	scrollLeft: number;

	constructor(private _router: Router, private _platformService: PlatformService) {
	}

	ngOnInit(): void {
		this.indexActive = this.indexSelectedTab;
	}

	ngAfterViewInit() {
		if (this._platformService.getDevice() === DEVICES.ANDROID || this._platformService.getDevice() === DEVICES.IOS)
			this.dataTabs = TABS_DATA.filter(tab => tab.name !== 'OFERTAS');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.indexSelectedTab)
			this.indexActive = changes.indexSelectedTab.currentValue;
	}

	clickTab(tab: any, index: number, event: MouseEvent) {
		event.preventDefault();

		this._router.navigateByUrl(tab.navigates ? `/${tab.path}` : '');

		this.indexActive = index;
		this.clickedTab.emit(index);
	}


	@HostListener('mousedown', [ '$event' ])
	onMouseDown(e: MouseEvent) {
		this.isDown = true;
		this.slider.nativeElement.classList.add('active');
		this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
		this.scrollLeft = this.slider.nativeElement.scrollLeft;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.isDown = false;
		this.slider.nativeElement.classList.remove('active');
	}

	@HostListener('mouseup')
	onMouseUp() {
		this.isDown = false;
		this.slider.nativeElement.classList.remove('active');
	}

	@HostListener('mousemove', [ '$event' ])
	onMouseMove(e: MouseEvent) {
		if (!this.isDown) return;
		e.preventDefault();
		const x = e.pageX - this.slider.nativeElement.offsetLeft;
		const walk = (x - this.startX) * 3; // scroll-fast
		this.slider.nativeElement.scrollLeft = this.scrollLeft - walk;
	}

}
