<span *ngIf="label" class="span__label" [class.full_label]="fullLabel" [class.disabled]="disabled"
	>{{ label }} <span *ngIf="isRequired && !disabled" class="span__label__required">*</span></span
>
<div class="select__box" #selectBox>
	<div
		class="select__box__select"
		[class.left-3px]="isLeft"
		[class.error]="labelError !== ''"
		[class.is-active]="isVisibleOptions"
		(click)="changeVisibleOptions()"
		[tabIndex]="tabIndex"
		(keyup)="onKeyUp($event)"
	>
		<span *ngIf="valueName !== ''">{{ getScreenWidth < 370 && isMonth ? valueName.slice(0, 3) : valueName }}</span>
		<span class="select__box__select__placeholder" *ngIf="valueName == ''">{{ placeholder }}</span>
		<img [src]="isFilter ? './assets/svg/Right.svg' : './assets/svg/icon-select.svg'" alt="v" width="20" height="20" />
	</div>
	<div class="select_compo select__box__options" [class.left-3px]="isLeft" [hidden]="!isVisibleOptions" #contentList>
		<div class="select__box__options__list">
			<div class="select__box__options__list__input" *ngIf="isSearch">
				<input type="text" placeholder="Buscar Nacionalidad..." [formControl]="valueSearch" />
			</div>
			<ng-container *ngIf="listFilter.length > 0">
				<div
					[ngClass]="[
						valueName === item.name
							? 'select__box__options__list__option option-active'
							: 'select__box__options__list__option'
					]"
					*ngFor="let item of listFilter; index as i"
				>
					<input
						type="radio"
						[formControlName]="name"
						[name]="name"
						[value]="item.value"
						[id]="'inline-' + i + idRand"
						#inputRefs
						hidden
					/>
					<label [for]="'inline-' + i + idRand" (click)="clickItem(item)">{{ item.name }}</label>
				</div>
			</ng-container>
		</div>
	</div>
</div>
<span class="span__error" *ngIf="labelError !== ''">{{ labelError }}</span>
