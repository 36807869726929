<div class="container__blog">
	<div class="container__blog__section">
		<div class="container__blog__section__title">
			<h3>Blog</h3>
			<span>Sumérgete en nuestro apasionante blog de viajes, lleno de consejos, inspiración y destinos fascinantes.</span>
		</div>
		<div class="container__blog__section__cards">
			<ng-container *ngFor="let blog of dataBlog; let i=index">
				<a [href]="blog.link"
				   target="_blank"
				   class="blogcard"
				   [ngClass]="i === 0 ? 'card1' : i === 1 ? 'card2' :'card3'">
					<img [src]="blog.image" [alt]="blog.title" loading="lazy" />
					<div class="blogcard__bg"></div>
					<div class="blogcard__info">
						<div class="blogcard__info__tags">
							<div class="blogcard__info__tags__tag" *ngFor="let tag of blog.categories | slice:0:2">
								<span>{{tag}}</span>
							</div>
						</div>
					</div>
					<div class="blogcard__contend">
						<h4 [class.small]="i!==0">{{blog.title}}</h4>
						<section>{{blog.contentExtract}}</section>
					</div>
				</a>
			</ng-container>
		</div>

		<div class="container__blog__section__mobile position-relative">
			<ngu-carousel #carousel [inputs]="carouselConfig" [dataSource]="dataBlog">
				<ngu-tile NguCarouselItem *nguCarouselDef="let item">
					<a [href]="item.link" target="_blank" class="blogcard">
						<img [src]="item.image" [alt]="item.title" loading="lazy" class="img-fluid object-fit-cover" />
						<div class="blogcard__bg"></div>
						<div class="blogcard__info">
							<div class="blogcard__info__tags">
								<div class="blogcard__info__tags__tag" *ngFor="let tag of item.categories | slice:0:2">
									<span>{{tag}}</span>
								</div>
							</div>
						</div>
						<div class="blogcard__contend">
							<h4 class="small">{{item.title}}</h4>
							<section>{{item.contentExtract}}</section>
						</div>
					</a>
				</ngu-tile>
			</ngu-carousel>
			<ul class="dots bars" NguCarouselPoint>
				<li [class.active]="i === carousel.activePoint"
				    (click)="carousel.moveTo(i)"
				    (keydown.enter)="carousel.moveTo(i)"
				    *ngFor="let i of carousel.pointNumbers; let j = index"></li>
			</ul>
		</div>

		<div class="container__blog__section__button">
			<a href="https://blog.nmviajes.com" target="_blank">
				<app-button [text]="'Ver más noticias'" [color]="'outline-primary'" [isMobile]="true"></app-button>
			</a>
		</div>
	</div>
</div>