<section class="bg-white title-section">
	<div class="container py-4 py-lg-5">
		<h1 class="inter-bold text-start">Ofertas en paquetes de viaje</h1>
		<p class="fs-4 mb-0">Te ofrecemos excelentes opciones para tu próximo viaje nacional o internacional.</p>
	</div>
</section>
<section [ngClass]="{ 'bg-white': i % 2 !== 0 }" *ngFor="let section of data; let i = index" class="py-4 py-lg-5">
	<div class="container">
		<ngu-carousel #carousel [inputs]="carouselConfig" [dataSource]="section.items">
			<ngu-tile NguCarouselItem [ngClass]="'py-3 py-lg-4 px-2 px-lg-3'" *nguCarouselDef="let item">
				<app-hot-sale-card [item]="item" [packageId]="section.id"></app-hot-sale-card>
			</ngu-tile>
			<a NguCarouselPrev role="button" class="carousel-arrows left-arrow">
				<img [src]="!carousel.isFirst ? './assets/svg/arrow-circle-left-red.svg' : './assets/svg/arrow-circle-left.svg'"
				     class="img-fluid"
				     alt="Prev" />
			</a>
			<a NguCarouselNext role="button" class="carousel-arrows right-arrow">
				<img [src]="!carousel.isLast ? './assets/svg/arrow-circle-right-red.svg' : './assets/svg/arrow-circle-right.svg'"
				     class="img-fluid"
				     alt="Fwd" />
			</a>
		</ngu-carousel>
	</div>
	<ul class="dots bars" NguCarouselPoint>
		<li [class.active]="i === carousel.activePoint"
		    (click)="carousel.moveTo(i)"
		    (keydown.enter)="carousel.moveTo(i)"
		    *ngFor="let i of carousel.pointNumbers; let j = index"></li>
	</ul>
</section>