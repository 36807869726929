<button class="pasajero" (click)="showPasajero()">{{onlyPasajeros | getNombrePipe}}</button>

<div [id]="idContent" class="boxFlotante" @openClose *ngIf="showOption && ( idStateOpen === idContent)">

    <div data-testid="habitacion" class="boxTwoPasajero d-flex flex-row align-items-center p-3" *ngIf="!onlyPasajeros">
        <div class="d-flex flex-row iconTwo">
            <img height="13px" src="./assets/filter/habitacion.png" alt="">
        </div>
        <div class="d-flex flex-row flex-column w-100">
            <div class="d-flex flex-row text1">Habitación</div>
            <div class="d-flex flex-row text2">Añadir</div>
        </div>
        <div class="d-flex flex-row number">
            <div class="d-flex flex-row btnNWhite disable" (click)="calculateDistributionTravel('habitacion',0)">-</div>
            <div class="d-flex flex-row textBtn">
                <input data-testid="input" class="inputPasajero" type="text" [value]="habitacion">
            </div>
            <div class="d-flex flex-row btnNWhite disable" (click)="calculateDistributionTravel('habitacion',1)">+</div>
        </div>
    </div>

    <div data-testid="adultos" class="boxTwoPasajero d-flex flex-row align-items-center p-3">
        <div class="d-flex flex-row iconTwo">
            <img height="13px" src="./assets/filter/adulto.png" alt="">
        </div>
        <div class="d-flex flex-row flex-column w-100">
            <div class="d-flex flex-row text1">Adultos</div>
            <div class="d-flex flex-row text2">12 o más años</div>
        </div>
        <div class="d-flex flex-row number">
            <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel('adultos',0)">-</div>
            <div class="d-flex flex-row textBtn">
                <input data-testid="input" class="inputPasajero" type="text" [value]="adultos">
            </div>
            <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel('adultos',1)">+</div>
        </div>
    </div>
    <div data-testid="ninios" class="boxTwoPasajero d-flex flex-row align-items-center p-3">
        <div class="d-flex flex-row iconTwo">
            <img height="13px" src="./assets/filter/nino.png" alt="">
        </div>
        <div class="d-flex flex-row flex-column w-100">
            <div class="d-flex flex-row text1">Niños</div>
            <div class="d-flex flex-row text2">De 2 - 11 años</div>
        </div>
        <div class="d-flex flex-row number">
            <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel('ninos',0)">-</div>
            <div class="d-flex flex-row textBtn">
                <input data-testid="input" class="inputPasajero" type="text" [value]="ninos">
            </div>
            <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel('ninos',1)">+</div>
        </div>
    </div>
    <div data-testid="infantes" class="boxTwoPasajero d-flex flex-row align-items-center p-3">
        <div class="d-flex flex-row iconTwo">
            <img height="13px" src="./assets/filter/infante.png" alt="">
        </div>
        <div class="d-flex flex-row flex-column w-100">
            <div class="d-flex flex-row text1">Infantes</div>
            <div class="d-flex flex-row text2">Menores de 2 años</div>
        </div>
        <div class="d-flex flex-row number">
            <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel('infantes',0)">-</div>
            <div class="d-flex flex-row textBtn">
                <input data-testid="input" class="inputPasajero" type="text" [value]="infantes">
            </div>
            <div class="d-flex flex-row btnNWhite" (click)="calculateDistributionTravel('infantes',1)">+</div>
        </div>
    </div>
    <div data-testid="infantes" class="boxTwoPasajero d-flex flex-row align-items-center p-3">
        <div class="d-flex flex-row marginRight10">
            Clase
        </div>
        <div class="d-flex flex-row flex-column w-100">
            <select class="form-select" name="selClase" id="selClase" [(ngModel)]="classId">
                <!-- <option value="">Seleccione</option> -->
                <option *ngFor="let elem of classes" [value]="elem.id">{{ elem.name }}</option>
            </select>
        </div>
    </div>

    <div class="d-flex flex-row justify-content-between align-items-center p-3 btnPasajero">
        <button class="gray" type="button" (click)="closePopUp()">Cancelar</button>
        <button type="button" (click)="savePasajeros()">Listo</button>
    </div>
</div>