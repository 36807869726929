<section class="bg-white pt-3 pt-md-4 pt-lg-5"
         *ngIf="!hiddenSection && (nationalFlights.length > 0 || internationalFlights.length > 0)">
	<div class="container">
		<div class="container__offers__section d-flex flex-column row-gap-3 row-gap-lg-4" id="sectionOffers">
			<div class="container__offers__section__title">
				<h3 class="fs-2 fw-bolder text-start mb-0">S&uacute;per ofertas de vuelos</h3>
			</div>
			<div class="filters__order d-flex align-items-center px-3 column-gap-2 column-gap-md-3 column-gap-lg-4">
				<div
					class="filters__order__tab d-flex flex-column py-2"
					*ngIf="showOffersNac"
					[class.active]="indexTab === 0"
					(click)="indexTab = 0">
					<span class="text-nowrap text-start" [class.active]="indexTab === 0">Vuelos Nacionales</span>
				</div>
				<div
					class="filters__order__tab d-flex flex-column py-2"
					*ngIf="showOffersInt"
					[class.active]="indexTab === 1"
					(click)="indexTab = 1">
					<span class="text-nowrap text-start" [class.active]="indexTab === 1">Vuelos Internacionales</span>
				</div>
			</div>
			<ng-container *ngIf="indexTab === 0">
				<div class="px-2 px-md-0 row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 g-md-4 g-xl-5">
					<app-newcard-offer
						[ngClass]="'col'"
						[urlImage]="national.Image"
						[title]="national.Destination | titlecase"
						[description]="'Desde Lima'"
						[destinationCode]="national.DestinationCode"
						[price]="national.Rate"
						*ngFor="let national of nationalFlightsPag"></app-newcard-offer>
				</div>
				<div class="container__offers__section__button d-flex justify-content-center align-items-center mt-3 mt-lg-4"
				     *ngIf="nationalFlights.length > 4">
					<app-button
						[text]="nationalFlightsPag.length >= nationalFlights.length ? 'Ver menos' : 'Ver más ofertas'"
						(onClick)="showMore()"
						[isMobile]="true"
						[color]="'outline-primary'"></app-button>
				</div>
			</ng-container>

			<ng-container *ngIf="indexTab === 1">
				<div class="px-2 px-md-0 row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 g-md-4 g-xl-5">
					<app-newcard-offer
						[ngClass]="'col'"
						[urlImage]="international.Image"
						[title]="international.Destination | titlecase"
						[description]="'Desde Lima'"
						[destinationCode]="international.DestinationCode"
						[price]="international.Rate"
						*ngFor="let international of internationalFlightsPag"></app-newcard-offer>
				</div>
				<div class="container__offers__section__button d-flex justify-content-center align-items-center mt-3 mt-lg-4"
				     *ngIf="internationalFlights.length > 4">
					<app-button
						[text]="internationalFlightsPag.length >= internationalFlights.length ? 'Ver menos' : 'Ver más ofertas'"
						(onClick)="showMore()"
						[isMobile]="true"
						[color]="'outline-primary'">
					</app-button>
				</div>
			</ng-container>
		</div>
	</div>
</section>