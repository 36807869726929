<div class="modal-content">
	<div class="recoveryLeft ">
		<div class="titleRecovery ">¡Únete a NMViajes y recibe nuestras ofertas exclusivas!</div>
		<img src="./assets/modal/logo-white.png " alt="NM Viajes" loading="lazy">
	</div>
	<div class="recoveryRight text-center">
		<div class="modal-header">
			<button type="button" class="btn-close" tabindex="-1" (click)="activeModal.close()"></button>
		</div>
		<div class="titleRecovery">Crear cuenta</div>
		<div class="socialMedia align-items-center new" [class.active]="socialMedia"></div>
		<div class="w-100 mt-2 mb-3 d-flex align-items-center justify-content-center" *ngIf="!isFromIOS">
			<div class="line bg-secondary bg-opacity-25"></div>
			<p class="text-center my-0 mx-2">&oacute;</p>
			<div class="line bg-secondary bg-opacity-25"></div>
		</div>
		<mat-tab-group mat-align-tabs="center" (selectedTabChange)="showSocialMedia($event)">
			<!-- PERSONAL -->
			<mat-tab label="Personal">
				<form name="personalAccountForm" [formGroup]="personalAccountForm" autocomplete="off">
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validatePersonalAccountForm('firstName')}">
							<input type="text" class="form-control" formControlName="firstName" placeholder="Nombres">
							<div class="textError" *ngIf="validatePersonalAccountForm('firstName')">
								Ingresa tu(s) nombre(s)
							</div>
						</div>
					</div>
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validatePersonalAccountForm('fatherLastname')}">
							<input type="text" class="form-control" formControlName="fatherLastname"
							       placeholder="Primer apellido">
							<div class="textError" *ngIf="validatePersonalAccountForm('fatherLastname')">
								Ingresa tu primer apellido
							</div>
						</div>
						<div class="divValida" [ngClass]="{error : validatePersonalAccountForm('motherLastname')}">
							<input type="text" class="form-control" formControlName="motherLastname"
							       placeholder="Segundo apellido">
							<div class="textError" *ngIf="validatePersonalAccountForm('motherLastname')">
								Ingresa tu segundo apellido
							</div>
						</div>
					</div>
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validatePersonalAccountForm('email')}">
							<input type="text" class="form-control form-email-input" formControlName="email" placeholder="Email">
							<div class="textError" *ngIf="validatePersonalAccountForm('email')">
								{{ personalAccountEmailErrorMessage }}
							</div>
						</div>
					</div>
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validatePersonalAccountForm('password')}">
							<input type="password" maxlength="10" class="form-control" formControlName="password"
							       placeholder="Contraseña">
							<div class="textError" *ngIf="validatePersonalAccountForm('password')">
								Ingresa una contraseña
							</div>
						</div>
						<div class="divValida" [ngClass]="{error : validatePersonalAccountForm('repeatPassword')}">
							<input type="password" maxlength="10" class="form-control" formControlName="repeatPassword"
							       placeholder="Confirmar contraseña">
							<div class="textError" *ngIf="validatePersonalAccountForm('repeatPassword')">
								Ingresa la misma contraseña
							</div>
						</div>
					</div>
				</form>
			</mat-tab>
			<!-- END PERSONAL -->

			<!-- EMPRESA -->
			<mat-tab label="Empresa">
				<form name="businessAccountForm" [formGroup]="businessAccountForm" autocomplete="off">
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('ruc')}">
							<input type="text" class="form-control" maxlength="11" formControlName="ruc" placeholder="RUC">
							<div class="textError" *ngIf="validateBusinessAccountForm('ruc')">
								{{ businessAccountRUCErrorMessage }}
							</div>
						</div>
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('businessName')}">
							<input type="text" class="form-control" formControlName="businessName" placeholder="Razón Social">
							<div class="textError" *ngIf="validateBusinessAccountForm('businessName')">
								Ingresa la razón social
							</div>
						</div>
					</div>
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('firstName')}">
							<input type="text" class="form-control" formControlName="firstName" placeholder="Nombres">
							<div class="textError" *ngIf="validateBusinessAccountForm('firstName')">
								Ingresa tu(s) nombre(s)
							</div>
						</div>
					</div>
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('fatherLastname')}">
							<input type="text" class="form-control" formControlName="fatherLastname"
							       placeholder="Apellido paterno">
							<div class="textError" *ngIf="validateBusinessAccountForm('fatherLastname')">
								Ingresa tu primer apellido
							</div>
						</div>
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('motherLastname')}">
							<input type="text" class="form-control" formControlName="motherLastname"
							       placeholder="Apellido materno">
							<div class="textError" *ngIf="validateBusinessAccountForm('motherLastname')">
								Ingresa tu segundo apellido
							</div>
						</div>
					</div>
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('email')}">
							<input type="text" class="form-control form-email-input" formControlName="email" placeholder="Email">
							<div class="textError" *ngIf="validateBusinessAccountForm('email')">
								{{ businessAccountEmailErrorMessage }}
							</div>
						</div>
					</div>
					<div class="elementRow">
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('password')}">
							<input type="password" maxlength="10" class="form-control" formControlName="password"
							       placeholder="Contraseña">
							<div class="textError" *ngIf="validateBusinessAccountForm('password')">
								Ingresa una contraseña
							</div>
						</div>
						<div class="divValida" [ngClass]="{error : validateBusinessAccountForm('repeatPassword')}">
							<input type="password" maxlength="10" class="form-control" formControlName="repeatPassword"
							       placeholder="Contraseña">
							<div class="textError" *ngIf="validateBusinessAccountForm('repeatPassword')">
								Ingresa la misma contraseña
							</div>
						</div>
					</div>
				</form>
			</mat-tab>
			<!-- END EMPRESA -->
		</mat-tab-group>
		<div class="textPass">Debe contener 6 caracteres como mínimo, 10 caracteres como máximo, mínimo una letra y
			mínimo un número.
		</div>
		<div class="d-flex justify-content-center">
			<button class="w-25" (click)="saveAccount()">Regístrate</button>
		</div>
		<div class="textPolitica">
			Al crear una cuenta aceptas nuestra
			<a target="_blank" class="link pointer" (click)="goToPolicy()">política de privacidad&nbsp;</a>
		</div>
		<hr>
		<div class="account">
			<div class="notRegister">¿Ya tienes cuenta?</div>
			<a class="newAccount" (click)="openLoginModal()">Inicia sesión</a>
		</div>
	</div>
</div>
