<div class="modal-header">
	<div class="titleVerifica">Recuperación de Contrase&ntilde;a</div>
	<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
	<div class="iconCenter">
		<img src="./assets/modal/correo.svg" alt="correo">
	</div>
	<div class="descriptionText">{{ message }}</div>
	<div class="action">
		<a role="button" class="btn primary" (click)="activeModal.dismiss()">Aceptar</a>
	</div>
</div>