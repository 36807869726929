<mat-form-field appearance="outline" class="mb-4 hideMov text-start px-4">
  <mat-select [(value)]="selectedCurrency" disableOptionCentering (selectionChange)="selectorCurrencyChange($event)">
    <mat-option value="soles">Soles</mat-option>
    <mat-option value="dolares">Dólares</mat-option>
  </mat-select>
</mat-form-field>

<div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingZero">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseZero"
        aria-expanded="true"
        aria-controls="collapseZero"
      >
        Filtros Activos
      </button>
    </h2>
    <div
      id="collapseZero"
      class="accordion-collapse collapse show"
      aria-labelledby="headingZero"
    >
      <div *ngIf="filterSelected.length > 0" class="accordion-body">
        <span
          *ngFor="let filterSelect of filterSelected; index as i"
          class="btn btn-outline-danger btn-filter"
          >{{ filterSelect.name
          }}<a
            class="icon-x"
            (click)="removeFilterBlock(filterSelect.filterType)"
            >X</a
          ></span
        >
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        Equipaje
      </button>
    </h2>
    <div
      id="collapseOne"
      class="accordion-collapse collapse show"
      aria-labelledby="headingOne"
    >
      <div class="accordion-body">
        <div class="d-flex justify-content-between mb-2">
          <label for="checkbox-pack-1">Equipaje de mano</label>
          <div class="form-check">
            <input
              name="equipajeMano"
              class="form-check-input"
              type="checkbox"
              id="checkbox-pack-1"
              (change)="changeEquipaje($event)"
              [checked]="filter.equipaje.mano"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <label for="checkbox-pack-2">Equipaje en bodega</label>
          <div class="form-check">
            <input
              name="equipajeBodega"
              class="form-check-input"
              type="checkbox"
              id="checkbox-pack-2"
              (change)="changeEquipaje($event)"
              [checked]="filter.equipaje.bodega"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="true"
        aria-controls="collapseTwo"
      >
        Escalas
      </button>
    </h2>
    <div
      id="collapseTwo"
      class="accordion-collapse collapse show"
      aria-labelledby="headingTwo"
    >
      <div class="accordion-body">
        <div class="d-flex justify-content-between mb-2">
          <label for="checkbox-scale-1">Todas las escalas</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkbox-scale-1"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <label for="checkbox-scale-2">Directo</label>
          <div class="form-check">
            <input
              name="directo"
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkbox-scale-2"
              [checked]="filter.escala.directo"
              (change)="changeEscala($event)"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <label for="checkbox-scale-3">1 Escala</label>
          <div class="form-check">
            <input
              name="uno"
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkbox-scale-3"
              [checked]="filter.escala.uno"
              (change)="changeEscala($event)"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <label for="checkbox-scale-4">2 o más escalas</label>
          <div class="form-check">
            <input
              name="mas"
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkbox-scale-4"
              [checked]="filter.escala.mas"
              (change)="changeEscala($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="true"
        aria-controls="collapseThree"
      >
        Aerolíneas
      </button>
    </h2>
    <div
      id="collapseThree"
      class="accordion-collapse collapse show"
      aria-labelledby="headingThree"
    >
      <div class="accordion-body pt-0">
        <div class="d-flex justify-content-center pb-3">
          <button
            class="px-0"
            [ngClass]="{ gris: filterAeroline }"
            (click)="toCheck(true)"
            type="button"
          >
            Seleccionar Todo
          </button>
          <button
            [ngClass]="{ gris: !filterAeroline }"
            (click)="toCheck(false)"
            type="button"
          >
            Borrar todo
          </button>
        </div>
        <ng-template [ngIf]="filters.airlines.length > 0">
          <div
            class="d-flex justify-content-between mb-2"
            *ngFor="let airlineObj of filters.airlines"
          >
            <div class="d-flex flex-row">
              <div class="company">
                <img src="{{ airlineObj.imageUrl }}" alt="" />
              </div>
              <label for="checkbox-airline-1">{{ airlineObj.name }}</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="airlineObj.checked"
                value=""
                (change)="checkId($event, airlineObj.code)"
                id="checkbox-airline-1"
              />
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFour"
        aria-expanded="true"
        aria-controls="collapseFour"
      >
        Precio
      </button>
    </h2>
    <div
      id="collapseFour"
      class="accordion-collapse collapse show"
      aria-labelledby="headingFour"
    >
      <div class="accordion-body pt-0">
        <div class="d-flex flex-row">
          <span><strong>Moneda:</strong></span>
          <div class="form-check form-check-inline">
            <label class="form-check-label" for="inlineRadio1">US$</label>
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="dolares"
              (change)="changePriceCurrency($event)"
              checked
            />
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label" for="inlineRadio2">S/.</label>
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="soles"
              (change)="changePriceCurrency($event)"
            />
          </div>
        </div>
        <ng5-slider
          [(value)]="minValuePrice"
          [(highValue)]="maxValuePrice"
          [options]="optionsPrice"
          (userChangeEnd)="filterPrice()"
        >
        </ng5-slider>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFive">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFive"
        aria-expanded="true"
        aria-controls="collapseFive"
      >
        Duración
      </button>
    </h2>
    <div
      id="collapseFive"
      class="accordion-collapse collapse show"
      aria-labelledby="headingFive"
    >
      <div class="accordion-body">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active border-0"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Salida
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link border-0"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Regreso
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div class="row row-cust">
              <div class="col-sm-12 col-md-12 col-lg-12 p-0">
                <label>Salida</label>
                <!-- <br />
                Lima - México -->
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 p-0">
                <div class="row row-cust pt-1 pb-1">
                  <div class="d-flex justify-content-start">
                    <ng5-slider
                      [(value)]="minValueDurationExit"
                      [(highValue)]="maxValueDurationExit"
                      [options]="optionsDurationExit"
                      (userChangeEnd)="filterDurationExit()"
                    >
                    </ng5-slider>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                <label>Escala</label>
                <br />
                de 1 a 24 horas
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 p-0">
                <div class="row row-cust pt-1 pb-1">
                  <div class="d-flex justify-content-start">
                    <ng5-slider
                      [(value)]="minValueDurationExitScale"
                      [(highValue)]="maxValueDurationExitScale"
                      [options]="optionsDurationExitScale"
                      (userChangeEnd)="filterElpasedExit()"
                    ></ng5-slider>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div class="row row-cust">
              <div class="col-sm-12 col-md-12 col-lg-12 p-0">
                <label>Regreso</label>
                <br />
                México - Lima
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 p-0">
                <div class="row row-cust pt-1 pb-1">
                  <div class="d-flex justify-content-start">
                    <ng5-slider
                      [(value)]="minValueDurationBack"
                      [(highValue)]="maxValueDurationBack"
                      [options]="optionsDurationBack"
                    >
                    </ng5-slider>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                <label>Escala</label>
                <br />
                de 1 a 24 horas
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 p-0">
                <div class="row row-cust pt-1 pb-1">
                  <div class="d-flex justify-content-start">
                    <ng5-slider
                      [(value)]="minValueDurationBackScale"
                      [(highValue)]="maxValueDurationBackScale"
                      [options]="optionsDurationBackScale"
                    ></ng5-slider>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingSix">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSix"
        aria-expanded="true"
        aria-controls="collapseSix"
      >
        Alianzas
      </button>
    </h2>
    <div
      id="collapseSix"
      class="accordion-collapse collapse show"
      aria-labelledby="headingSix"
    >
      <div class="accordion-body">
        <div class="row row-cust">
          <div class="col-sm-12 col-md-12 col-lg-12 p-0">
            <div class="row row-cust pt-1 pb-1">
              <div class="d-flex justify-content-between">
                <label for="checkbox-alliance-1">Todas las alianzas</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="checkbox-alliance-1"
                  />
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="row row-cust pt-1 pb-1">
              <div class="d-flex justify-content-between">
                <label for="checkbox-alliance-2">Alianza 1</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="checkbox-alliance-2"
                  />
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="row row-cust pt-1 pb-1">
              <div class="d-flex justify-content-between">
                <label for="checkbox-alliance-3">Alianza 2</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="checkbox-alliance-3"
                  />
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingSeven">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSeven"
        aria-expanded="true"
        aria-controls="collapseSeven"
      >
        Reserva flexible
      </button>
    </h2>
    <div
      id="collapseSeven"
      class="accordion-collapse collapse show"
      aria-labelledby="headingSeven"
    >
      <div class="accordion-body">
        <div class="row row-cust">
          <div class="col-sm-12 col-md-12 col-lg-12 p-0">
            <div class="row row-cust pt-1 pb-1">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <label>Si</label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                  />
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="row row-cust pt-1 pb-1">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <label>No</label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                  />
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</div>
