import { Component, Input, OnInit } from '@angular/core';
import { IHotSale } from '../../models';
import { Router } from '@angular/router';

@Component({
	selector: 'app-hot-sale-card',
	templateUrl: './hot-sale-card.component.html',
	styleUrls: [ './hot-sale-card.component.scss' ]
})
export class HotSaleCardComponent implements OnInit {
	@Input() packageId: number;
	@Input() item: IHotSale;

	constructor(private _router: Router) {
	}

	ngOnInit(): void {
	}

	onClick() {
		this._router.navigate([ `/ofertas`, this.packageId, this.item.id ]);
	}
}
