<div class="container__passenger" tabindex="0">
	<div class="container__passenger__name" (click)="showOptions = !showOptions">
		<img src="./assets/svg/people-red2.svg" alt="" />
		<span>{{ totalPassengers }} pasajero{{ totalPassengers > 1 ? 's' : '' }}</span>
	</div>
	<div class="container__passenger__floating" *ngIf="showOptions">
		<div class="container__passenger__floating__arrow"></div>
		<div class="container__passenger__floating__body">
			<div class="container__passenger__floating__body__passengers">
				<div class="container__passenger__floating__body__passengers__row">
					<div class="container__passenger__floating__body__passengers__row__info">
						<span>Adultos</span>
						<span>Mayores de 12 años</span>
					</div>
					<div class="container__passenger__floating__body__passengers__row__counters">
						<img
							[src]="totalADT == 1 ? './assets/svg/minus-sm.svg' : './assets/svg/minus-active.svg'"
							alt="-"
							(click)="clickCounter(0, false)"
						/>
						<span>{{ totalADT }}</span>
						<img src="./assets/svg/plus-sm.svg" alt="+" (click)="totalPassengersTemp < 9 ? clickCounter(0, true) : showAlertPassenger()" />
					</div>
				</div>
				<div class="container__passenger__floating__body__passengers__row">
					<div class="container__passenger__floating__body__passengers__row__info">
						<span>Menores</span>
						<span>De 2 a 11 años</span>
					</div>
					<div class="container__passenger__floating__body__passengers__row__counters">
						<img
							[src]="totalCNN == 0 ? './assets/svg/minus-sm.svg' : './assets/svg/minus-active.svg'"
							alt="-"
							(click)="clickCounter(1, false)"
						/>
						<span>{{ totalCNN }}</span>
						<img src="./assets/svg/plus-sm.svg" alt="+" (click)="totalPassengersTemp < 9 ? clickCounter( 1, true) : showAlertPassenger()" />
					</div>
				</div>
				<div class="container__passenger__floating__body__passengers__row">
					<div class="container__passenger__floating__body__passengers__row__info">
						<span>Infantes</span>
						<span>Menores de 2 años</span>
					</div>
					<div class="container__passenger__floating__body__passengers__row__counters">
						<img
							[src]="totalINF == 0 ? './assets/svg/minus-sm.svg' : './assets/svg/minus-active.svg'"
							alt="-"
							(click)="clickCounter(2, false)"
						/>
						<span>{{ totalINF }}</span>
						<img src="./assets/svg/plus-sm.svg" alt="+" (click)="clickCounter(2, true)" />
					</div>
				</div>
			</div>
			<div class="container__passenger__floating__body__actions">
				<div class="btn cancel" (click)="resetTotal()">Cancelar</div>
				<div class="btn apply" (click)="updateTotal()">Aplicar</div>
			</div>
		</div>
	</div>
</div>
