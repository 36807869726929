<div class="boxDestino">
  <div class="container mt-5">

    <div class="rowDestino center">
      <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_111:2572)">
          <path
            d="M6.91414 32.9741C6.41483 33.2084 6.19453 33.7982 6.41705 34.3022L8.69775 39.1982C8.8098 39.442 9.01422 39.6318 9.26562 39.7254C9.37576 39.7654 9.49258 39.7861 9.61002 39.7861C9.75699 39.7873 9.90206 39.7559 10.0357 39.6953L27.2674 31.6466L25.7066 47.9658C25.6765 48.287 25.8003 48.6028 26.0406 48.8174C26.796 49.4428 27.7375 49.7989 28.7171 49.831C28.9872 49.8306 29.2558 49.7967 29.5176 49.73C30.9165 49.365 32.0012 48.1185 32.7411 45.9895L39.5222 25.9098L52.9525 19.6451C56.9057 17.8006 58.9429 14.0604 57.5748 11.141C56.7984 9.61514 55.3113 8.57716 53.6114 8.3737C51.7228 8.08865 49.7925 8.38576 48.0771 9.22566L32.5786 16.4931L15.915 9.97542C13.8165 9.18503 12.1545 9.20535 10.9889 10.0468C10.0109 10.8493 9.45449 12.0555 9.47829 13.3204C9.48845 13.6414 9.65002 13.9382 9.91412 14.1216L21.1348 21.835L14.7088 24.876L3.93352 22.1388C3.49198 22.0303 3.03235 22.2293 2.80857 22.6254L0.122834 27.4601C-0.0165151 27.7163 -0.0384174 28.0204 0.0628411 28.294C0.163465 28.5679 0.377409 28.7847 0.650077 28.8895L8.62728 32.184L6.91414 32.9741ZM11.5558 12.8138C11.6155 12.3853 11.8323 11.9952 12.1643 11.719C12.7424 11.3032 13.7965 11.3642 15.2053 11.8911L30.0449 17.679L23.2736 20.8415L11.5558 12.8138ZM2.43369 27.4503L4.1973 24.3183L14.5666 26.8926C14.7901 26.9491 15.0259 26.9278 15.2361 26.8323L48.9389 11.0905C50.2788 10.4297 51.7878 10.189 53.267 10.4011C54.3044 10.4966 55.2284 11.0953 55.7404 12.0025C56.5911 13.8274 54.9189 16.4931 52.0907 17.8107L40.4139 23.2847L41.1236 21.1859C41.303 20.646 41.01 20.0625 40.4701 19.8835C39.9295 19.7045 39.3464 19.9975 39.1673 20.5374L30.8251 45.3607C30.3283 46.77 29.71 47.6115 29.0209 47.7934C28.5939 47.8756 28.1521 47.7918 27.7842 47.5607L29.8116 26.2742C29.8646 25.7146 29.4541 25.2178 28.8939 25.1648C28.3343 25.1112 27.8375 25.5223 27.7842 26.0819L27.4702 29.3355L10.076 37.4444L8.64759 34.4035L11.5054 33.0659C11.8733 32.9037 12.1104 32.5399 12.1104 32.1383C12.1104 31.7365 11.8733 31.3727 11.5054 31.2108L2.43369 27.4503Z"
            fill="#4B4B4B" />
        </g>
        <defs>
          <clipPath id="clip0_111:2572">
            <rect width="58" height="58" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <div class="rowDestino titulo"> {{title}} </div>
    <div class="rowDestino subtitulo mt-3">{{subTitle}}</div>

    <div class="rowDestino verMov">
      <div class="ida">
        <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.22224 10.6745L3.98078 13.8043C4.82721 14.5092 5.82223 14.8688 6.8215 14.8687C7.54487 14.8687 8.27049 14.6802 8.94312 14.2977L12.6462 12.1892C12.9013 12.0438 13.0115 11.674 12.8922 11.3633C12.773 11.0524 12.4695 10.9183 12.2143 11.0634L8.51155 13.1719C7.23477 13.8977 5.71927 13.747 4.55621 12.7785L1.44757 10.1898L2.84668 9.14429L6.44734 10.5994C6.57151 10.6496 6.70681 10.6397 6.82522 10.5717L11.1155 8.10951C11.2754 8.01775 11.3848 7.83179 11.4048 7.61788C11.4249 7.40402 11.3526 7.19272 11.2138 7.05945L6.47977 2.51444L8.32092 1.36988L16.8364 4.65222C16.9587 4.69937 17.0911 4.68856 17.2072 4.62221L22.9044 1.36056C23.5474 1.03263 24.3258 1.39094 24.6429 2.16272C24.805 2.55737 24.8287 3.00421 24.7096 3.42098C24.5905 3.83775 24.3441 4.17002 24.0158 4.35679L16.3621 8.71292C16.107 8.85812 15.9967 9.22792 16.1159 9.53876C16.2352 9.84959 16.5386 9.98404 16.7938 9.83872L24.4473 5.48271C25.0272 5.15298 25.4624 4.56592 25.6728 3.82961C25.8832 3.09331 25.8414 2.30382 25.5549 1.60665C24.9998 0.255322 23.6278 -0.36598 22.4967 0.221647C22.491 0.224567 22.4853 0.227674 22.4798 0.230843L16.954 3.39433L8.42924 0.108322C8.3016 0.0591148 8.16299 0.0730319 8.04361 0.147277L5.30692 1.84859C5.1521 1.94483 5.04817 2.1306 5.03119 2.34134C5.01421 2.55209 5.08652 2.75892 5.22309 2.89002L9.93484 7.41359L6.57503 9.3418L2.94276 7.87392C2.80002 7.81632 2.64377 7.83825 2.51557 7.93399L0.243352 9.63195C0.0959743 9.74205 0.00443697 9.93571 0.000153326 10.1462C-0.00407932 10.3568 0.0795537 10.5556 0.22224 10.6745Z"
            fill="#4B4B4B" />
          <path
            d="M14.2863 9.87937C14.0314 10.0244 13.9213 10.3937 14.0404 10.7043C14.1594 11.0148 14.4626 11.1489 14.7174 11.0039C14.9723 10.8588 15.0824 10.4895 14.9633 10.179C14.8443 9.86849 14.5412 9.73435 14.2863 9.87937Z"
            fill="#4B4B4B" />
        </svg>Ida
      </div>
      <div class="vuelta">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.1113 4.54943C13.911 4.44018 13.6564 4.52078 13.5426 4.72944L12.7899 6.1096C11.9496 3.20805 8.94286 1.63353 6.07422 2.59273C4.7223 3.04479 3.57805 4.01221 2.88458 5.28943C2.77078 5.4981 2.84092 5.75581 3.04124 5.86505C3.24156 5.9743 3.49618 5.89369 3.60998 5.68502C4.86216 3.38999 7.6633 2.50398 9.86654 3.70612C10.9855 4.31663 11.7859 5.3886 12.0726 6.66052L10.1615 6.34229C9.93523 6.30446 9.71549 6.46244 9.6707 6.69519C9.62591 6.92794 9.77298 7.1473 9.99924 7.18513L12.5875 7.61631C12.7642 7.64579 12.9433 7.5553 13.0318 7.39194L14.268 5.12504C14.3818 4.91639 14.3117 4.65867 14.1113 4.54943Z"
            fill="#DF0C30" />
          <path
            d="M12.1583 9.8567C11.9579 9.74745 11.7033 9.82805 11.5895 10.0367C10.3373 12.3317 7.53618 13.2178 5.33294 12.0156C4.21399 11.4051 3.41358 10.3331 3.1269 9.06122L5.03799 9.37945C5.26425 9.41728 5.484 9.25929 5.52879 9.02655C5.57358 8.7938 5.4265 8.57443 5.20024 8.53661L2.61194 8.10542C2.43528 8.07595 2.25614 8.16644 2.16769 8.32979L0.931437 10.5967C0.817639 10.8054 0.887778 11.0631 1.0881 11.1723C1.28842 11.2816 1.54306 11.201 1.65686 10.9923L2.40954 9.61213C3.24988 12.5137 6.25662 14.0882 9.12524 13.129C10.4772 12.6769 11.6214 11.7095 12.3149 10.4323C12.4287 10.2237 12.3586 9.96594 12.1583 9.8567Z"
            fill="#DF0C30" />
        </svg>Vuelta
      </div>
      <div class="descrip description text-start text-md-end">{{description}}</div>
    </div>

    <div class="verDesktop w-100">
      <div class="rowDestino tableDestino bold">
        <div class="colDestino">Salida</div>
        <div class="colDestino">Retorno</div>
        <div class="colDestino">Publicadas</div>
        <div class="colDestino">Tarifas</div>
        <div class="colDestino"></div>
      </div>

      <div class="rowDestino tableDestino" *ngFor="let item of vuelos?.slice(0, limit)">
        <div class="colDestino">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
              stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
          </svg>{{item.DateStart}}
        </div>
        <div class="colDestino">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
              stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
          </svg>{{item.DateEnd}}
        </div>
        <div class="colDestino">{{item.SearchTime}}</div>
        <div class="colDestino boldTarifa">{{'$ '+ item.Total}}</div>
        <div class="colDestino">
          <div (click)="buscarVuelo(item)" class="btnBuscar">Buscar</div>
        </div>
      </div>
    </div>

    <div class="contentTabMobile">
      <div class="boxInter">
        <div class="rowAero tableDestino" *ngFor="let item of vuelos?.slice(0, limit)">
          <div class="city">{{item.SearchTime}}</div>
          <div class="rowTab">
            <div class="cLeft">Salida</div>
            <div class="cRight">Retorno</div>
          </div>

          <div class="rowTab">
            <div class="cLeft">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
                  stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
              </svg>{{item.DateStart}}
            </div>
            <div class="cRight">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
                  stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
              </svg>{{item.DateEnd}}
            </div>
          </div>
          <div class="rowTab">
            <div class="cLeft boldTarifa">{{'$ '+ item.Total}}</div>
            <div class="cRight">
              <div (click)="buscarVuelo(item)" class="btnBuscar">Buscar</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rowDestino footerTable">
      <div class="text">Tarifas publicadas sujetos a cambio. Precios basados en disponibilidad. Algunas
        restricciones y fechas de bloqueo son aplicables. <strong>Tarifa no incluye Gastos Administrativos.</strong>
      </div>
      <div class="masOfertas" *ngIf="limit < vuelos?.length">
        <button class="plus dropdown-toggle" (click)="viewMoreOffers()">Más ofertas</button>
      </div>
    </div>
  </div>

  <div class="container-fluid px-5 mt-5">
    <app-activity [city]="codigoCiudad" [site]="site" [isFlight]="isFlight"></app-activity>
  </div>

  <div class="container-fluid px-5 mt-5">
    <!-- <div class="embed-container">
            <iframe src="http://localhost:4200/#/hoteles" frameborder="0" allowfullscreen></iframe>
        </div> -->

    <app-hotels [city]="codigoCiudad" [site]="site" [isFlight]="isFlight"></app-hotels>
  </div>

  <div class="container-fluid mt-5 px-0">
    <app-destinations [city]="codigoCiudad"></app-destinations>
  </div>
</div>

<section class="slider-container mx-auto">
  <app-aereolineas [aereolineas]="$aereolineas | async" (redirigir)="toLine($event)"></app-aereolineas>
</section>