<div id="carouselExampleIndicators" class="carousel slide hideMobile" data-bs-ride="carousel">
  <div class="carousel-indicators d-flex d-md-none">
    <ng-container *ngFor="let b of airfare; let indice = index">
      <button type="button" data-bs-target="#carouselExampleIndicators" class="active" [attr.data-bs-slide-to]="indice"
        aria-current="true" aria-label="Slide 1"></button>
    </ng-container>
  </div>
  <div class="carousel-inner row">
    <div *ngFor="let item of airfare?.slice(0, limit); let i = index;" class="col-md-3 col-12 active carousel-item">
      <!-- CARD -->
      <div class="card">
        <div class="card__img">
          <img class="card-img-top" loading="lazy" [src]="item.Image" [alt]="item.Destination"/>
        </div>
        <div class="card-body">
          <h5 class="card-title m-0"></h5>
          <h3 class="card-title fs-5 fw-bold">{{ item.Destination | titlecase }}</h3>
          <h5 class="card-title">Desde Lima</h5>
          <div class="cotainer">
            <div class="row">
              <div class="col-8">
                <span class="mt-3">Precio desde:</span>
                <span class="price">${{ item.Rate }}</span>
                <p class="card-text">Elige tu próximo destino</p>
              </div>

              <div class="col-4 pl-md-0 d-flex align-items-end justify-content-end">
                <a (click)="viewRates(item,i)">
                  <svg class="arrow" width="11" height="18" viewBox="0 0 11 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M-9.24496e-08 15.885L6.79892 9L-6.94355e-07 2.115L2.09312 -9.14931e-08L11 9L2.09312 18L-9.24496e-08 15.885Z"
                      fill="white" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END CARD -->
    </div>
  </div>
</div>


<div *ngFor="let item of airfare?.slice(0, limit); let i = index;" id="carouselExampleIndicators"
  class="carousel slide onlyMobile" data-bs-ride="carousel">
  <div class="carousel-indicators d-flex d-md-none">
    <ng-container *ngFor="let b of airfare; let indice = index">
      <button type="button" data-bs-target="#carouselExampleIndicators" class="active" [attr.data-bs-slide-to]="indice"
        aria-current="true" aria-label="Slide 1"></button>
    </ng-container>
  </div>
  <div class="carousel-inner row">
    <div class="col-md-3 col-12 active carousel-item">
      <!-- CARD -->
      <div class="card">
        <div class="card__img">
          <img class="card-img-top" loading="lazy" [src]="item.Image" [alt]="item.Destination">
        </div>
        <div class="card-body">
          <h5 class="card-title m-0"></h5>
          <h3 class="card-title fs-5 fw-bold">{{ item.Destination | titlecase }}</h3>
          <h5 class="card-title">Desde Lima</h5>
          <div class="cotainer">
            <div class="row">
              <div class="col-8">
                <span class="mt-3">Precio desde:</span>
                <span class="price">${{ item.Rate }}</span>
                <p class="card-text">Elige tu próximo destino</p>
              </div>

              <div class="col-4 pl-md-0 d-flex align-items-end justify-content-end">
                <a (click)="viewRates(item,i)">
                  <svg class="arrow" width="11" height="18" viewBox="0 0 11 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M-9.24496e-08 15.885L6.79892 9L-6.94355e-07 2.115L2.09312 -9.14931e-08L11 9L2.09312 18L-9.24496e-08 15.885Z"
                      fill="white" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END CARD -->
    </div>
  </div>
</div>


<div class="row text-end py-5">
  <div class="col-12" *ngIf="limit < airfare?.length">
    <ng-container>
      <button class="plus dropdown-toggle px-4" (click)="viewMoreOffers()">Más ofertas</button>
    </ng-container>
  </div>
</div>