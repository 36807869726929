<section class="tabDestino movDestino" *ngIf="destination">
  <div class="tabImgDest">
    <div class="mascara"></div>
    <img [src]="destination.Galleries[0]" alt="">
    <div class="galeriaText">
      <div class="texto" *ngIf="destination.Galleries.length > 1" (click)="displayGallery = true">Ver Galería
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_152:1334)">
            <path
              d="M3.48799 3.72121C5.60038 1.50704 8.8467 1.16715 11.2481 2.5012C11.4556 2.61644 11.5304 2.87812 11.4151 3.08558C11.2999 3.29312 11.0382 3.36775 10.8307 3.25255C8.63426 2.0322 5.87061 2.46898 4.11168 4.31263C2.29686 6.2381 2.0925 9.10824 3.4965 11.2361C5.43043 14.1735 9.60738 14.6162 12.1117 12.1114C13.7992 10.4245 14.2881 7.70398 13.0691 5.49019C12.9548 5.28222 13.0306 5.02089 13.2385 4.9065C13.4465 4.79221 13.7077 4.86796 13.8222 5.07589C13.8486 5.1238 13.8954 5.21308 13.9406 5.30465H13.9405C15.1904 7.85243 14.6217 10.8176 12.7194 12.7192C9.83416 15.605 5.01471 15.1052 2.77884 11.7091C1.17538 9.27882 1.37209 5.96611 3.48799 3.72121Z"
              fill="white" />
            <path
              d="M2.38735 2.38766C5.57094 -0.795503 10.7512 -0.795632 13.935 2.3877C16.7979 5.25105 17.1061 9.74029 14.7747 12.9518L16.1139 14.2907C16.5834 14.066 17.1771 14.1385 17.5815 14.5429L21.3697 18.3315C22.2096 19.1709 22.2103 20.5301 21.3699 21.37C20.5303 22.2101 19.1711 22.2104 18.3313 21.3701L14.5426 17.5818C14.1384 17.1775 14.0656 16.5838 14.2904 16.1142L12.9514 14.7749C9.73916 17.1068 5.25027 16.7978 2.38735 13.9353C-0.79594 10.7516 -0.79594 5.57138 2.38735 2.38766ZM15.1504 16.974L18.9392 20.7624C19.4428 21.2663 20.2579 21.2668 20.7621 20.7622C21.2662 20.2585 21.2665 19.4434 20.762 18.9394L16.9738 15.1506C16.8063 14.9832 16.5337 14.9831 16.3662 15.1506L15.1504 16.3664C14.9828 16.5339 14.9828 16.8065 15.1504 16.974ZM14.8465 15.4548L15.4544 14.8469L14.2306 13.6234C14.1346 13.7299 14.036 13.8343 13.935 13.9353C13.834 14.0363 13.7296 14.1349 13.623 14.231L14.8465 15.4548ZM2.99514 13.3275C5.84298 16.1749 10.456 16.1992 13.3273 13.3275C16.1952 10.46 16.1781 5.84672 13.3273 2.99545C10.479 0.147487 5.84397 0.147014 2.99519 2.99545C0.147007 5.84402 0.147007 10.479 2.99514 13.3275Z"
              fill="white" />
            <path
              d="M12.1013 3.61394C12.1043 3.61007 12.1078 3.60663 12.1116 3.60277C12.2797 3.43515 12.5517 3.43515 12.7197 3.60277C12.8874 3.77082 12.8874 4.04285 12.7197 4.21086C12.7159 4.21473 12.7124 4.21816 12.7086 4.2216C12.5397 4.37848 12.2758 4.37461 12.1116 4.21086C11.9479 4.04668 11.9441 3.78281 12.1013 3.61394Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_152:1334">
              <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 1 22 0)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
  <div class="tabContentDest">
    <div class="titleTabDest">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.88598 12.1482C3.52435 12.3627 3.28125 12.7569 3.28125 13.207C3.28125 13.8855 3.83324 14.4375 4.51172 14.4375C5.0464 14.4375 5.50233 14.0946 5.67164 13.6172H8.85938C9.53786 13.6172 10.0898 14.1692 10.0898 14.8477C10.0898 15.5261 9.53786 16.0781 8.85938 16.0781H3.9375C2.8067 16.0781 1.88672 16.9981 1.88672 18.1289C1.88672 19.2597 2.8067 20.1797 3.9375 20.1797H8.03906C8.26559 20.1797 8.44922 19.9961 8.44922 19.7695C8.44922 19.543 8.26559 19.3594 8.03906 19.3594H3.9375C3.25902 19.3594 2.70703 18.8074 2.70703 18.1289C2.70703 17.4504 3.25902 16.8984 3.9375 16.8984H8.85938C9.99018 16.8984 10.9102 15.9785 10.9102 14.8477C10.9102 13.7169 9.99018 12.7969 8.85938 12.7969H5.67164C5.57464 12.5234 5.38359 12.2941 5.13745 12.1482L8.27014 7.00826C8.76295 6.26752 9.02344 5.40438 9.02344 4.51172C9.02344 2.02396 6.99948 0 4.51172 0C2.02396 0 0 2.02396 0 4.51172C0 5.40438 0.26049 6.26752 0.753293 7.00826L3.88598 12.1482ZM4.51172 13.6172C4.28556 13.6172 4.10156 13.4332 4.10156 13.207C4.10156 12.9809 4.28556 12.7969 4.51172 12.7969C4.73788 12.7969 4.92188 12.9809 4.92188 13.207C4.92188 13.4332 4.73788 13.6172 4.51172 13.6172ZM4.51172 0.820312C6.54716 0.820312 8.20312 2.47628 8.20312 4.51172C8.20312 5.24459 7.98874 5.9528 7.58317 6.55983C7.58002 6.56455 7.57694 6.56935 7.57399 6.57423L4.51172 11.5986C4.51172 11.5986 1.44342 6.56455 1.44026 6.55983C1.0347 5.9528 0.820312 5.24459 0.820312 4.51172C0.820312 2.47628 2.47628 0.820312 4.51172 0.820312Z"
          fill="#4B4B4B" />
        <path
          d="M4.51172 6.5625C5.64252 6.5625 6.5625 5.64252 6.5625 4.51172C6.5625 3.38092 5.64252 2.46094 4.51172 2.46094C3.38092 2.46094 2.46094 3.38092 2.46094 4.51172C2.46094 5.64252 3.38092 6.5625 4.51172 6.5625ZM4.51172 3.28125C5.1902 3.28125 5.74219 3.83324 5.74219 4.51172C5.74219 5.1902 5.1902 5.74219 4.51172 5.74219C3.83324 5.74219 3.28125 5.1902 3.28125 4.51172C3.28125 3.83324 3.83324 3.28125 4.51172 3.28125Z"
          fill="#4B4B4B" />
        <path
          d="M16.4883 6.80859C14.0005 6.80859 11.9766 8.83255 11.9766 11.3203C11.9766 12.2144 12.2379 13.0789 12.7322 13.8204L15.8532 18.716C15.6116 18.8622 15.4242 19.0893 15.3284 19.3594H11.7305C11.5039 19.3594 11.3203 19.543 11.3203 19.7695C11.3203 19.9961 11.5039 20.1797 11.7305 20.1797H15.3284C15.4977 20.6571 15.9536 21 16.4883 21C17.1668 21 17.7188 20.448 17.7188 19.7695C17.7188 19.3232 17.4799 18.9317 17.1234 18.716L20.2444 13.8204C20.7387 13.0789 21 12.2144 21 11.3203C21 8.83255 18.976 6.80859 16.4883 6.80859ZM16.4883 20.1797C16.2621 20.1797 16.0781 19.9957 16.0781 19.7695C16.0781 19.5434 16.2621 19.3594 16.4883 19.3594C16.7144 19.3594 16.8984 19.5434 16.8984 19.7695C16.8984 19.9957 16.7144 20.1797 16.4883 20.1797ZM19.5597 13.3684C19.5581 13.3709 16.4883 18.1862 16.4883 18.1862C16.4883 18.1862 13.4184 13.3708 13.4168 13.3684C13.0113 12.7614 12.7969 12.0532 12.7969 11.3203C12.7969 9.28487 14.4528 7.62891 16.4883 7.62891C18.5237 7.62891 20.1797 9.28487 20.1797 11.3203C20.1797 12.0532 19.9653 12.7614 19.5597 13.3684Z"
          fill="#4B4B4B" />
        <path
          d="M16.4883 9.26953C15.3575 9.26953 14.4375 10.1895 14.4375 11.3203C14.4375 12.4511 15.3575 13.3711 16.4883 13.3711C17.6191 13.3711 18.5391 12.4511 18.5391 11.3203C18.5391 10.1895 17.6191 9.26953 16.4883 9.26953ZM16.4883 12.5508C15.8098 12.5508 15.2578 11.9988 15.2578 11.3203C15.2578 10.6418 15.8098 10.0898 16.4883 10.0898C17.1668 10.0898 17.7188 10.6418 17.7188 11.3203C17.7188 11.9988 17.1668 12.5508 16.4883 12.5508Z"
          fill="#4B4B4B" />
        <path
          d="M9.88477 20.1797C10.1113 20.1797 10.2949 19.9961 10.2949 19.7695C10.2949 19.543 10.1113 19.3594 9.88477 19.3594C9.65824 19.3594 9.47461 19.543 9.47461 19.7695C9.47461 19.9961 9.65824 20.1797 9.88477 20.1797Z"
          fill="#4B4B4B" />
      </svg>Destinos
    </div>
    <div class="nameCity">{{destination.Name.toUpperCase()}}</div>
    <div class="opcionTab">
      <div class="tabName" [ngClass]="{active: id==='history'}" (click)="showInformation('history')">Historia</div>
      <div class="tabName" [ngClass]="{active: id==='touristAttraction'}"
        (click)="showInformation('touristAttraction')">Atractivo
        Turístico</div>
    </div>
    <div class="contOpcion" *ngIf="id === 'history'" [innerHtml]="destination.History"></div>
    <div class="contOpcion" *ngIf="id === 'touristAttraction'" [innerHtml]="destination.Description"></div>

    <p-galleria images="destination.Galleries" [(visible)]="displayGallery" [responsiveOptions]="responsiveOptions"
      [containerStyle]="{'max-width': '850px'}" [numVisible]="7" [circular]="true" [fullScreen]="true"
      [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
      <ng-template pTemplate="item" let-item>
        <img [src]="item" style="width: 100%; display: block;" />
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
          <img [src]="item" style="display: block;" />
        </div>
      </ng-template>
    </p-galleria>
  </div>
</section>
